import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { LinkItem } from '../../../../components/Component';

function SliderItem({ title, description, image, altText }) {
  return (
    <Row>
      <div className="d-flex flex-md-row flex-column justify-content-center">
        <Col md='6' className="align-self-center pb-3 pb-md-0">
          <div className="px-4">
            <h5 className="ff text-start" style={{ color: "#2B3E79" }}>{title}</h5>
            <p className="ff text-start">{description}</p>
            <div className="d-flex">
              <LinkItem to={'/portfolio'} className={'pe-3'}>
                <Button className="btn border-0 ff fw-bold gradient-button">
                  View Portfolio
                </Button>
              </LinkItem>
              <LinkItem to={'/contact'}>
                <Button className="btn border-0 ff fw-bold gradient-button">
                  Contact Us
                </Button>
              </LinkItem>
            </div>
          </div>
        </Col>
        <Col md='5' className="align-self-end d-flex justify-content-center pt-3 pt-md-0">
          <img src={image} className="h-300px img-fluid" alt={altText} />
        </Col>
      </div>
    </Row>
  );
}

export default SliderItem;
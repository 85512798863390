import React, { useRef, useState } from 'react';
import { useLoader, useFrame } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Html, useProgress } from '@react-three/drei';

const Model = ({ modelPath, position, scale }) => {
  const [modelLoaded, setModelLoaded] = useState(false);
  const modelRef = useRef();
  const { progress } = useProgress();

  const gltf = useLoader(GLTFLoader, modelPath);

  if (gltf && !modelLoaded) {
    const model = gltf.scene.clone();
    model.position.set(...position);
    model.scale.set(...scale);
    modelRef.current = model;
    setModelLoaded(true);
  }

  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y += 0.001;
    }
  });

  return (
    <>
      {!modelLoaded && (
        <Html center>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            fontWeight: 'bold',
            fontSize: '20px',
            color: 'white'
          }}>
            Loading... {progress.toFixed(2)}%
          </div>
        </Html>
      )}
      {modelLoaded && <primitive object={modelRef.current} />}
    </>
  );
};

export default Model;
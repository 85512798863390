import React, { useCallback, useEffect, useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
// import { Link } from 'react-router-dom';
import WhitelabeledButton from '../../components/WhitelabeledButton';
import tabimg1 from '../../../../images/axiom_img/UI UX.png';
import tabimg2 from '../../../../images/axiom_img/GraphicPage-images/Graphic_Design.jpeg';
import tabimg3 from '../../../../images/axiom_img/3Ddesign/01.png';
import tabimg4 from '../../../../images/axiom_img/Game.png';
import tabimg5 from '../../../../images/axiom_img/Static & Dynamic.png';
import tabimg6 from '../../../../images/axiom_img/Custom Web Dev..png';
import tabimg7 from '../../../../images/axiom_img/ERP CRM.png';
import tabimg8 from '../../../../images/axiom_img/Mobile App.png';
import tabimg9 from '../../../../images/axiom_img/Web Hosting.png';
import tabimg10 from '../../../../images/axiom_img/Domain Management.png';
import tabimg12 from '../../../../images/axiom_img/Server Space.png';
import tabimg11 from '../../../../images/axiom_img/Web Management.png';
import tabimg13 from '../../../../images/axiom_img/Digital Marketing.png';
import tabimg14 from '../../../../images/axiom_img/Social Media Creation.png';
import tabimg15 from '../../../../images/axiom_img/Brand.png';
import tabimg16 from '../../../../images/axiom_img/digitalmarketing.png';
import { useTab } from '../../components/TabContext';

const BG_COLOR_1 = '#D1D7FC';
const BG_COLOR_2 = '#CEE8FF';

const tabsData = [
    { id: "1", title: "Software Development" },
    { id: "2", title: "Creative & Design Services" },
    { id: "3", title: "Digital Marketing & Management" },
    { id: "4", title: "Web Infrastructure & Management" },
];

const softwareDevCards = [
    {
        id: 1, image: tabimg5, title: "Static & Dynamic website Development", description: "We craft static websites for consistent user experiences and dynamic websites that adapt in real-time to user interactions, ensuring seamless and engaging interface.", link: "/static&dynamicweb", bgColor: BG_COLOR_1
    },
    {
        id: 2, image: tabimg6, title: "E-commerce website development", description: "Elevate your business online with our expert ecommerce solutions. We create dynamic digital storefronts that drive sales and engage customers effectively.", link: "/e-commerceservices", bgColor: BG_COLOR_2
    },
    {
        id: 3, image: tabimg7, title: "ERP/CRM Development", description: "Gain a competitive edge with powerful ERP/CRM systems that optimize workflows and boost performance, enhancing efficiency and driving business success with seamless integration and scalability.", link: "/erpcrmservices", bgColor: BG_COLOR_1
    },
    {
        id: 4, image: tabimg8, title: "Mobile App Development", description: "Reach new audiences and drive engagement with feature-rich, native mobile apps for Android and iOS. Experience seamless performance, intuitive design and unparalleled user satisfaction.", link: "/mobileappdevelopment", bgColor: BG_COLOR_2
    }
];

const creativeDesignCards = [
    {
        id: 1, image: tabimg1, title: "UI/UX design", description: "Meet our UI/UX design team for unparalleled services. We craft intuitive interfaces, seamless experiences and striking visuals to elevate user engagement and satisfaction.", link: "/ui/uxservices", bgColor: BG_COLOR_1
    },
    {
        id: 2, image: tabimg2, title: "Graphics Design", description: "We build strong brand identities with exceptional logos, captivating banners and impactful visual elements. Let us elevate your brand's presence with creative excellence!", link: "/graphicsservices", bgColor: BG_COLOR_2
    },
    {
        id: 3, image: tabimg3, title: "3D Design", description: "Elevate your brand & projects with high-quality 3D models for products, characters, animations & more. Enhance visual impact and engagement with precision-crafted creativity!", link: "/3Dservices", bgColor: BG_COLOR_1
    },
    {
        id: 4, image: tabimg4, title: "Game Design", description: "Embark on captivating gaming adventures where innovation merges with creativity for unparalleled entertainment experiences. Discover new worlds and endless possibilities!", link: "/gamedesignservices", bgColor: BG_COLOR_2
    }
];

const digitalMarketingCards = [
    {
        id: 1, image: tabimg13, title: "SEO & Digital Marketing", description: "Our Digital Growth Services specialize in boosting online visibility and driving measurable growth through data-driven SEO and comprehensive digital marketing strategies.", link: "/digitalmarketing", bgColor: BG_COLOR_1
    },
    {
        id: 2, image: tabimg14, title: "Social Media Management & Creation", description: "We skillfully manage your online presence, optimizing content and platforms to connect your brand with the perfect audience.", link: "/socialmediaservices", bgColor: BG_COLOR_2
    },
    {
        id: 3, image: tabimg15, title: "Brand Evolution", description: "Our Brand Evolution Services offer strategic initiatives to revitalize and refine your brand, guiding its growth journey and ensuring it remains competitive in the marketplace.", link: "/brandevolutionservices", bgColor: BG_COLOR_1
    },
    {
        id: 4, image: tabimg16, title: "Content Marketing", description: 'Axiom Web Control Services Digital Solution & Tech offers expert content marketing to captivate and engage your target audience, fostering lasting relationships for growth.', link: "/contentmarketingservices", bgColor: BG_COLOR_2
    }
];

const webInfrastructureCards = [
    {
        id: 1, image: tabimg9, title: "Website Hosting Services", description: "Power your online presence with reliable, high-performance website hosting tailored to your needs, ensuring accessibility and optimization for success.", link: "/webhostingservice", bgColor: BG_COLOR_1
    },
    {
        id: 2, image: tabimg10, title: "Domain Registration & Management", description: "Simplify your online presence with our domain services. We handle registration and management, ensuring your web address is secure and easily accessible.", link: "/domainregisterservice", bgColor: BG_COLOR_2
    },
    {
        id: 3, image: tabimg12, title: "Dedicated Server Hosting", description: "Unlock powerful performance and security with our dedicated server hosting. Enjoy unparalleled reliability and customization for your site or app.", link: "/serverhostingservice", bgColor: BG_COLOR_1
    },
    {
        id: 4, image: tabimg11, title: "Website Maintenance & Support", description: "We offer essential reliable support to keep your website running smoothly, ensuring its functionality, security and performance remain optimized.", link: "/websupportservice", bgColor: BG_COLOR_2
    }
];

const Card = React.memo(({ data }) => (
    <article className="card1">
        <header className="card__thumb">
            <img src={data.image} alt={data.title} />
        </header>
        <div className="card__body" style={{ backgroundColor: data.bgColor }}>
            <h2 className="card__title text-primary text-start">
                <span href="#">{data.id.toString().padStart(2, '0')}</span>
            </h2>
            <div className="card__subtitle text-primary">{data.title}</div>
            <div className='card__description text-dark'>
                <p className='text-start'>{data.description}</p>
                <WhitelabeledButton
                    to={data.link}
                    buttonText="Know More"
                    buttonColor="primary"
                    buttonOutline
                    className={`d-flex justify-content-center`}
                />
            </div>
        </div>
    </article>
));

const ServiceGallery = () => {
    const { activeTab, setActiveTab } = useTab();
    const [contentHeight, setContentHeight] = useState('auto');
    useEffect(() => {
        const updateHeight = () => {
            if (window.innerWidth < 768) { // Mobile breakpoint
                setContentHeight('70vh'); // Adjust this value as needed
            } else {
                setContentHeight('auto');
            }
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    const toggle = useCallback((tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }, [activeTab, setActiveTab]);

    const renderCards = useCallback((cards) => (
        <div className={`content-wrapper ${window.innerWidth < 768 ? 'scrollable-content' : ''}`} 
             style={{ height: contentHeight, overflowY: window.innerWidth < 768 ? 'auto' : 'visible' }}>
            <div className="text-center d-flex justify-content-center m-3 m-md-5">
                <div className="row align-items-start">
                    {cards.map(card => (
                        <div key={card.id} className='col-12 col-md-6 col-lg-3 mb-2'>
                            <Card data={card} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ), [contentHeight]);

    return (
        <>
            <Nav tabs className="mt-n3 border-0 bg-transparent justify-content-center justify-content-lg-center">
                {tabsData.map(tab => (
                    <NavItem key={tab.id} className='px-md-3 px-0'>
                        <NavLink
                            className={`crimson-text fs-4 ff text-center`}
                            style={{
                                cursor: 'pointer',
                                borderBottom: activeTab === tab.id ? "3px solid #004CA1" : "none"
                            }}
                            onClick={() => toggle(tab.id)}>
                            {tab.title}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <TabContent activeTab={activeTab} className="tab-content-container">
                <TabPane tabId="1">
                    {renderCards(softwareDevCards)}
                </TabPane>
                <TabPane tabId="2">
                    {renderCards(creativeDesignCards)}
                </TabPane>
                <TabPane tabId="3">
                    {renderCards(digitalMarketingCards)}
                </TabPane>
                <TabPane tabId="4">
                    {renderCards(webInfrastructureCards)}
                </TabPane>
            </TabContent>
        </>
    );
}

export default ServiceGallery
import React, { useState } from 'react';
import { PreviewCard } from '../../../components/Component';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import PortfolioTab from '../../axiomlayout/pages/components/PortfolioTab';
import Silocloud from "../../../images/siloimages/Portfolio/silocloud.png";
import Noit from "../../../images/siloimages/Portfolio/noitavonne.png";
import Nuairs from "../../../images/siloimages/Portfolio/Nuairs.png";
import realFlow from "../../../images/siloimages/Portfolio/therealflow.png";
import representingme from "../../../images/siloimages/Portfolio/representingme.png";
import hbcu from "../../../images/siloimages/Portfolio/hbcu.png";

const websitesData = [
    {
        id: 'Silocloud', title: 'SiloCloud', url: 'https://silocloud.com/', image: Silocloud
    },
    {
        id: 'Noit', title: "Noitavonne", url: 'https://noitavonne.com/', image: Noit
    },
    {
        id: 'Nuairs', title: 'NuAirs', url: 'https://nuairs.com/', image: Nuairs
    },
    {
        id: 'realFlow', title: 'TheRealFlow', url: 'https://therealflow.com/', image: realFlow
    },
    {
        id: 'representingme', title: 'RepresentingMe', url: 'https://representingme.com/', image: representingme
    },
    {
        id: 'hbcu', title: 'HBCU', url: 'https://www.hbculeaguepass.com/home', image: hbcu
    },
];

const Portfoliosilo = () => {
    const [activeTab, setActivetab] = useState("1");
    const toggle = (tab) => {
        if (activeTab !== tab) setActivetab(tab);
    };

    // useEffect(() => {
    //     const handleResize = () => {
    //         setFov(window.innerWidth < 768 ? 7 : 5);
    //     };
    //     window.addEventListener('resize', handleResize);
    //     handleResize();
    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);

    // const handleButtonClick = (path, position, scale) => {
    //     setModel({ path, position, scale });
    // };

    return (
        <div className='nk-content'>
            <div className='container text-center'>
                <h3 className='ff-alt text-black'>Gallery</h3>
            </div>
            <PreviewCard>
                <Nav tabs className="mt-n3">
                    <NavItem>
                        <NavLink
                            tag="a"
                            href="#tab"
                            className={classnames({ active: activeTab === "1" })}
                            onClick={(ev) => {
                                ev.preventDefault();
                                toggle("1");
                            }}>
                            Websites
                        </NavLink>
                    </NavItem>
                    {/* <NavItem>
                        <NavLink
                            tag="a"
                            href="#tab"
                            className={classnames({ active: activeTab === "2" })}
                            onClick={(ev) => {
                                ev.preventDefault();
                                toggle("2");
                            }}
                        >
                            3D Design
                        </NavLink>
                    </NavItem> */}
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="container">
                            <PortfolioTab items={websitesData.slice(0, 3)} titleClassName="text-danger ff-alt" />
                            <PortfolioTab items={websitesData.slice(3, 6)} titleClassName="text-danger ff-alt" />
                            {/* <PortfolioTab items={websitesData.slice(6, 9)} /> */}
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        {/* ... (3D Design tab content) */}
                    </TabPane>
                </TabContent>
            </PreviewCard>
        </div >
    )
}

export default Portfoliosilo

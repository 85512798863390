import React from 'react'
import Content from '../../../../layout/content/Content'
import Head from '../../../../layout/head/Head'
import About from './3DPageComponent/About'
import ChooseUs from './3DPageComponent/ChooseUs'
import ServiceOffer from './3DPageComponent/ServiceOffer'
import ImpOfService from './3DPageComponent/ImpOfService'
import Contact from '../../components/Contact'
import Testimonial from '../components/Testimonial'
import heroimg from '../../../../images/axiom_img/3Ddesign/01.png';
import GenericHero from '../components/GenericHero'
import { Icon } from '../../../../components/Component'


const ThreeDPage = () => {
  return (
    <React.Fragment>
      <Head title="Axiom | 3D Design"></Head>
      <Content>
        <GenericHero
          breadcrumbs={
            <>
              SERVICES <Icon name={'chevron-right'} /> Creative & Design Services<Icon name={'chevron-right'} /> 3D Design
            </>
          }
          title="Bring your ideas to life in 3D: design & modeling services"
          description="Custom 3D design & modeling expertise - We deliver exceptional 3D models tailored to your unique vision, from products and prototypes to anything you can imagine."
          buttonText="Contact Now"
          buttonLink="/contact"
          heroImage={heroimg} />
        <About />
        <ChooseUs />
        <ServiceOffer />
        <ImpOfService />
        <Contact />
        <Testimonial />
      </Content>
    </React.Fragment>
  )
}

export default ThreeDPage

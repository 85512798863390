import React from 'react'
import { Card, CardBody, CardHeader, CardImg, CardText, CardTitle, Col, Row } from 'reactstrap';
import SlideA from "../../../../../images/axiom_img/WebHosting-images/Shared Web Hosting.png";
import SlideB from "../../../../../images/axiom_img/WebHosting-images/VPS Hosting.png";
import SlideC from "../../../../../images/axiom_img/WebHosting-images/dedicate hosting.png";
import SlideD from "../../../../../images/axiom_img/WebHosting-images/Cloud Hosting.png";
import SlideE from "../../../../../images/axiom_img/WebHosting-images/Wordpress.png";

const HostServices = () => {
    const bgColor2 = '#D1D7FC';
    return (
        <React.Fragment>
            <div className="container-fluid" style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                <Row>
                    <Col>
                        <p className="fw-bolder text-primary text-center ff my-5 fs-1 ">Hosting Services We Offered</p>
                    </Col>
                </Row>
                <div className='container' style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                    <Row>
                        <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100">
                                <CardImg top src={SlideA} alt="Shared" className='h-285px object-fit-cover img-fluid'/>
                                <CardBody className="card-inner"  style={{ backgroundColor: `${bgColor2}` }}>
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary '>Shared Hosting</CardTitle>
                                    <CardText className='ff text-primary'>
                                        Perfect for small to medium websites, our shared hosting offers a cost-effective, user-friendly solution. With shared hosting, your site shares server resources with other websites.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100">
                                <CardImg top src={SlideB} alt="VPS" className='h-285px object-fit-cover img-fluid' />
                                <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'> VPS Hosting</CardTitle>
                                    <CardText className='ff text-primary'>
                                        Offers more power and flexibility than shared hosting. With VPS hosting, you get a dedicated slice of server resources, allowing for greater customization and better performance.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100">
                                <CardImg top src={SlideC} alt="Dedicated" className='h-285px object-fit-cover img-fluid'/>
                                <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Dedicated Hosting</CardTitle>
                                    <CardText className='ff text-primary'>
                                        The ultimate in web hosting, dedicated hosting provides a server solely for your use, delivering maximum performance and security.
                                    </CardText>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100">
                                <CardImg top src={SlideD} alt="Cloud" className='h-285px object-fit-cover img-fluid'/>
                                <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent'>
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Cloud Hosting</CardTitle>
                                    <CardText className='ff text-primary'>
                                        Leverages the power of multiple servers, offering high reliability and scalability. Cloud hosting is great for businesses expecting rapid growth or fluctuating needs.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100">
                                <CardImg top src={SlideE} alt="WordPress" className='h-285px object-fit-cover img-fluid'/>
                                <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'> WordPress Hosting</CardTitle>
                                    <CardText className='ff text-primary'>
                                        Optimized specifically for WordPress websites, this hosting service is tailored to deliver exceptional performance, security and ease of use.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        {/* <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100">
                                <CardBody className="card-inner">
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                        <Icon name={'setting-fill'} />
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Maintenance and support</CardTitle>
                                    <CardText className='ff text-primary'>
                                        Our dedicated team ensures the stability, security and continuous improvement of your applications throughout their lifecycle.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col> */}
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default HostServices

import React from 'react'
import SlideA from "../../../../../images/axiom_img/Erp&Crm-images/ERP Consultation.png";
import SlideB from "../../../../../images/axiom_img/Erp&Crm-images/CRM Consultation.png";
import SlideC from "../../../../../images/axiom_img/Erp&Crm-images/Custom Software.png";
import SlideD from "../../../../../images/axiom_img/Erp&Crm-images/Financial Management.png";
import SlideE from "../../../../../images/axiom_img/Erp&Crm-images/Human Resourse.png";
import SlideF from "../../../../../images/axiom_img/Erp&Crm-images/Automate Business workflow.png";
import { Card, CardBody, CardImg, CardText, CardTitle, Col, Row } from 'reactstrap';

const ServiceSection = () => {
    const bgColor1 = '#D1D7FC';
    const bgColor2 = '#CEE8FF';
    return (
        <div className='container'>
            <Row>
                <Col>
                    <p className="fw-bolder text-primary text-center ff my-5 fs-1 text-capitalize  ">how Axiom can help businesses achieve your goals ?</p>
                </Col>
            </Row>
            <Row>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideA} alt="ERP Consultation" className='h-350px object-fit-contain img-fluid' />
                        <CardBody className="card-inner " style={{ backgroundColor: `${bgColor2}` }}>
                            <CardTitle className='ff fs-4 fw-bold text-primary '>ERP Consultation and Implementation</CardTitle>
                            <CardText className='text-primary'>
                                <div className="list">
                                    <ul>
                                        <li><p className='ff'><span className='fw-bold text-primary'>Overview: </span>Axiom specializes in ERP consultations, strategy implementations and comprehensive support services. Our team of experts works closely with clients to tailor ERP solutions that align with their business objectives and operational needs.</p>
                                        </li>
                                        <li><p className='ff'><span className='fw-bold text-primary'>Benefits: </span> Our approach ensures that ERP systems are not just implemented but are effectively integrated into the business processes, leading to improved efficiency, cost savings and enhanced decision-making capabilities.</p>
                                        </li>
                                    </ul>
                                </div>
                            </CardText>
                            {/* <Button color="primary">Go somewhere</Button> */}
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideB} alt="CRM Consultation" className='h-350px object-fit-contain img-fluid' />
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                            <CardTitle className='ff fs-4 fw-bold text-primary'>CRM Consultation and Implementation</CardTitle>
                            <CardText className='text-primary ff'>
                                <div className="list">
                                    <ul>
                                        <li><p className='ff'><span className='fw-bold text-primary'>Overview: </span> We offer CRM consulting services that include CRM selection, implementation, migration and consolidation. Our goal is to help businesses select the right CRM system that best fits their needs and to implement it seamlessly.</p>
                                        </li>
                                        <li><p className='ff'><span className='fw-bold text-primary'>Benefits: </span> By providing CRM consulting services, we ensure that businesses can leverage the full potential of their CRM systems, leading to better customer relationship management, increased sales and improved customer satisfaction.</p>
                                        </li>
                                    </ul>
                                </div>
                            </CardText>
                            {/* <Button color="primary">Go somewhere</Button> */}
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideC} alt="Custom Software" className='h-350px object-fit-contain img-fluid' />
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                            <CardTitle className='ff fs-4 fw-bold text-primary'>Custom Software Development</CardTitle>
                            <CardText className='text-primary ff'>
                                <div className="list">
                                    <ul>
                                        <li><p className='ff'><span className='fw-bold text-primary'>Overview: </span> Our team of software architects provides custom software development and consulting services. We work with businesses to understand their unique challenges and develop tailored solutions that address their specific needs.</p>
                                        </li>
                                        <li><p className='ff'><span className='fw-bold text-primary'>Benefits: </span> Our custom software development services help businesses reduce stress and costs associated with software development, ensuring that they can focus on their core operations while we handle the technical aspects.</p>
                                        </li>
                                    </ul>
                                </div>
                            </CardText>
                            {/* <Button color="primary">Go somewhere</Button> */}
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideD} alt="Financial Management" className='h-350px object-fit-contain img-fluid' />
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                            <CardTitle className='ff fs-4 fw-bold text-primary'>Financial Management</CardTitle>
                            <CardText className='text-primary ff'>
                                <div className="list">
                                    <ul>
                                        <li><p className='ff'><span className='fw-bold text-primary'>Overview: </span> Axiom's solutions include Financial Management applications that streamline invoicing and billing processes. We also build robust document management capabilities to facilitate financial operations.</p>
                                        </li>
                                        <li><p className='ff'><span className='fw-bold text-primary'>Benefits: </span> These applications help businesses manage their finances more efficiently, reducing the time and resources spent on financial tasks and improving overall financial health.</p>
                                        </li>
                                    </ul>
                                </div>
                            </CardText>
                            {/* <Button color="primary">Go somewhere</Button> */}
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideE} alt="Resource Management" className='h-350px object-fit-contain img-fluid' />
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                            <CardTitle className='ff fs-4 fw-bold text-primary'>Human Resource Management</CardTitle>
                            <CardText className='text-primary ff'>
                                <div className="list">
                                    <ul>
                                        <li><p className='ff'><span className='fw-bold text-primary'>Overview: </span> Our Human Resource Management applications provide convenient management of employee data and workflows, ensuring that HR processes are streamlined and efficient.</p>
                                        </li>
                                        <li><p className='ff'><span className='fw-bold text-primary'>Benefits: </span> By automating HR processes, businesses can save time, reduce errors and ensure compliance with labor laws and regulations.</p>
                                        </li>
                                    </ul>
                                </div>
                            </CardText>
                            {/* <Button color="primary">Go somewhere</Button> */}
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideF} alt="Service Automation" className='h-350px object-fit-contain img-fluid' />
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                            <CardTitle className='ff fs-4 fw-bold text-primary'>Enterprise Service Automation</CardTitle>
                            <CardText className='text-primary ff'>
                                <div className="list">
                                    <ul>
                                        <li><p className='ff'><span className='fw-bold text-primary'>Overview: </span> We offer Enterprise Service Automation applications that automate business processes and workflows, leading to increased efficiency and productivity.</p>
                                        </li>
                                        <li><p className='ff'><span className='fw-bold text-primary'>Benefits: </span> These applications help businesses reduce manual tasks, minimize errors and improve the overall efficiency of their operations.</p>
                                        </li>
                                    </ul>
                                </div>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ServiceSection

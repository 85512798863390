import React from 'react'
import WhitelabeledButton from './WhitelabeledButton';
import logo from '../../../images/axiom_img/background2.png';

const Home = () => {
    return (
        <div className="container-fluid d-flex align-items-start mt-md-4 bgimg" name="home">
            <div className="nk-content ms-md-3 ms-0">
                <div className=" p-1 p-md-3 p-lg-4 mt-5">
                    <div className="content d-grid align-items-center px-md-3">
                        <h1 className="ff" style={{ color: '#2B3146' }}>Software Development Services :<br />
                            <span className='fw-bold d-flex align-items-end' style={{ color: '#2B4584' }}>
                                <span><img src={logo} alt="Axiom" className='img-fluid home_logo' /></span>xiom Web Control</span>
                        </h1>
                    </div>
                    <div className="content ps-3 mt-2">
                        <h4 className="fw-bolder ff">Build Your Vision, Grow Your Business</h4>
                    </div>
                </div>
                <div className="container m-0 ">
                    <div className="row">
                        <div className="col-12">
                            <div className="list d-grid ms-md-5">
                                <ol className='pb-3 '>
                                    <li className="list-item ff" style={{ color: '#2B3146' }}>
                                        Build: Craft a stunning and functional website that reflects your brand.
                                    </li>
                                    <li className="list-item" style={{ color: '#2B3146' }}>
                                        <p className="m-0 ff" style={{ color: '#2B3146' }}>
                                            Promote: Reach your target audience and drive traffic with effective marketing
                                        </p>
                                        <p style={{ color: '#2B3146' }} className='ff'>
                                            strategies.
                                        </p>
                                    </li>
                                    <li className="list-item" style={{ color: '#2B3146', }}>
                                        <p className="m-0 ff" style={{ color: '#2B3146' }}>
                                            Thrive: Grow your online presence, increase conversions and achieve
                                        </p>
                                        <p style={{ color: '#2B3146' }} className='ff'>
                                            lasting success.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <WhitelabeledButton
                    className={`d-flex justify-content-center justify-content-md-start pb-3 mt-md-4 ms-md-5 m-auto`}
                    to="/contact"
                    buttonText="Book Your Service"
                    buttonColor="primary"
                    buttonOutline
                />
            </div>
        </div>
    )
}

export default Home

import React from 'react'
import { Card, CardBody, CardText, Col, Row } from 'reactstrap'

const ServiceProvides = () => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <p className="fw-bolder text-primary text-center text-capitalize ff my-5 fs-2 px-3 px-md-0">Our Dedicated Server Hosting Offers</p>
                </Col>
            </Row>
            <div className="container-fluid">
                <Row className='justify-content-center'>
                    <Col md='11' >
                        <div className="container-fluid align-self-center " style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                            <Col md='12' className=''>
                                <p className='text-center  text-primary ff fs-5 pt-3'>
                                    Core Web Hosting Services
                                </p>
                            </Col>
                            <Col md='12' sm='6'>
                                <div className="container my-3 my-md-5 ">
                                    <Row className='border-top border-2 border-primary border-dotted'>
                                        <Col md='12' className='d-flex justify-content-center'>
                                            <Card className="bg-transparent">
                                                <CardBody className="card-inner ">
                                                    <CardText>
                                                        <div className='d-flex flex-column justify-content-center my-4 '>
                                                            <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Streamlined Domain Name Search & Availability Checks:</span> Our user-friendly platform allows you to effortlessly search for your desired domain name and verify its availability.</p>

                                                            <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Expert Guidance & Secure Registration:</span> Our team of domain specialists is readily available to assist you in selecting the perfect domain name for your brand. They will guide you through a secure registration process, ensuring ownership and control over your online address.</p>

                                                            <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Centralized Domain Management Tools: </span> We provide a comprehensive suite of tools for managing your domain settings. This includes updating contact information, configuring email addresses and administering DNS records that direct visitors to your website.</p>

                                                            <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Automated Renewal Reminders:</span>We prioritize the continuity of your online presence. Automated renewal reminders will notify you before your domain registration expires, allowing you to renew seamlessly and avoid any disruptions.</p>
                                                        </div>
                                                    </CardText>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </div>
                    </Col>
                </Row>

            </div>
        </React.Fragment>
    )
}

export default ServiceProvides

import React from 'react'
import { Col, Row } from '../../../../../components/Component'

const OurServices = () => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <p className="text-primary text-center text-capitalize ff fs-1 fw-bold my-5 px-3 px-md-0">Our Digital Marketing Services Offers</p>
                </Col>
            </Row>
            <div className="container-fluid">
                <Row className='justify-content-center'>
                    <Col md='11' >
                        <div className="container-fluid d-flex flex-column align-items-center " style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                            <Col md='8' className=''>
                                <p className='text-center  text-primary ff fs-5 py-3'>
                                    Maximize your online presence with Axiom's expert digital marketing services. We specialize in SEO, PPC advertising, social media marketing and compelling content creation. Explore our full range of services to enhance your brand's digital impact. Partner with Axiom for accelerated online growth.
                                </p>
                            </Col>
                            <Col md='12' sm='6' className='border-top border-2 border-primary border-dotted'>
                                <div className='d-flex justify-content-center my-4 '>
                                    <Row>
                                        <Col md={4} className="mb-4">
                                            <div className="card bg-transparent">
                                                <div className="card-body list">
                                                    <h5 className="mb-3 ff text-primary ">Search Engine Optimization (SEO)</h5>
                                                    <ul className="ff fs-4 text-start text-primary">
                                                        <li className='list-item'>Keyword Research and Strategy</li>
                                                        <li className='list-item'>On-Page Optimization (including metadata, headings, content optimization)</li>
                                                        <li className='list-item'>Technical SEO (site speed optimization, mobile-friendliness, structured data)</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4} className="mb-4">
                                            <div className="card bg-transparent ">
                                                <div className="card-body list">
                                                    <h5 className="mb-3 ff text-primary ">Pay-Per-Click (PPC) Advertising</h5>
                                                    <ul className="ff fs-4 text-start text-primary">
                                                        <li className='list-item'>Campaign Strategy and Setup</li>
                                                        <li className='list-item'>Keyword Research and Selection</li>
                                                        <li className='list-item'>Bid Management and ROI Tracking</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4} className="mb-4">
                                            <div className="card bg-transparent ">
                                                <div className="card-body list">
                                                    <h5 className="mb-3 ff text-primary ">Social Media Marketing</h5>
                                                    <ul className="ff fs-4 text-start text-primary">
                                                        <li className='list-item'>Social Media Strategy</li>
                                                        <li className='list-item'>Profile Setup and Branding</li>
                                                        <li className='list-item'>Content Creation and Scheduling</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4} className="mb-4">
                                            <div className="card bg-transparent ">
                                                <div className="card-body list">
                                                    <h5 className="mb-3 ff text-primary ">Content Marketing</h5>
                                                    <ul className="ff fs-4 text-start text-primary">
                                                        <li className='list-item'>Content Strategy Development</li>
                                                        <li className='list-item'>Blog Posts, Articles, White Papers and E-books</li>
                                                        <li className='list-item'>SEO Content Optimization</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4} className="mb-4">
                                            <div className="card bg-transparent ">
                                                <div className="card-body list">
                                                    <h5 className="mb-3 ff text-primary ">Additional Services</h5>
                                                    <ul className="ff fs-4 text-start text-primary">
                                                        <li className='list-item'>Email Marketing</li>
                                                        <li className='list-item'>Reputation Management</li>
                                                        <li className='list-item'>Analytics and Conversion Rate Optimization (CRO)</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </div>
                    </Col>
                </Row>

            </div>
        </React.Fragment>
    )
}

export default OurServices

import React from 'react'
import bgimg1 from '../../../../images/axiom_img/background2.png';


const Stats = () => {
    return (
        <>
            <div className='position-absolute d-none d-md-block opacity-50 icon_img2'>
                <img src={bgimg1} alt="background_img" className='h-425px bgimg1' style={{ transform: 'rotate(25deg)' }} />
            </div>
            <div className="d-flex justify-content-around align-items-center .stats-container row mx-auto my-3 my-md-0">
                <div className="text-center col-6 col-md-3 mb-4">
                    <div className="ff fs-2 fw-bolder text-black ">15</div>
                    <div className="fs-4ff text-black fw-bold">Years of experiences</div>
                </div>
                <div className="text-center col-6 col-md-3 mb-4 border-start border-2 border-primary">
                    <div className="ff fs-2 fw-bolder text-black">400 +</div>
                    <div className="fs-4ff text-black fw-bold">Happy Customers</div>
                </div>
                <div className="stat-item col-6 col-md-3 mb-4 border-md-start border-md-2 border-md-primary">
                    <div className="ff fs-2 fw-bolder text-black">300 +</div>
                    <div className=" fs-4ff text-black fw-bold">Projects Completed</div>
                </div>
                <div className="stat-item col-6 col-md-3 mb-4 border-start border-2 border-primary">
                    <div className="ff fs-2 fw-bolder text-black">7</div>
                    <div className="fs-4ff text-black fw-bold">Awards Achievement</div>
                </div>
            </div>
        </>
    )
}

export default Stats

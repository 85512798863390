import React from 'react'
import imgUrl from '../../../images/axiom_img/background6.png'
import Stats from '../pages/components/Stats'
import FeatureList from '../pages/components/Features'
const About = () => {
    return (
        <React.Fragment>
            <div style={{
                backgroundImage: `url(${imgUrl})`, backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}>
                <div className="nk-container d-flex justify-content-center p-3 p-md-5">
                    <h4 className='text-primary ff fs-2 text-center '>We Solve Technology Challenges</h4>
                </div>
                <div className='container-fluid mb-0 mb-md-3 position-relative '>
                    <Stats />
                </div>
                <div className='container-fluid mb-0 mt-md-3 p-0 pb-md-4'>
                    <FeatureList />
                </div>
            </div>

        </React.Fragment>
    )
}

export default About

import React, { Suspense, useEffect, useState } from 'react'
import { PreviewCard } from '../../../../components/Component';
import { Button, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Canvas } from '@react-three/fiber';
import { Environment, OrbitControls, SpotLight } from '@react-three/drei';
import { Html } from '@react-three/drei';
import PortfolioTab from '../components/PortfolioTab';
import classnames from "classnames";
import axiom from "../../../../images/axiom_img/portfolio/axiomwebcontrol_project.jpg"
import IGTBOK from "../../../../images/axiom_img/portfolio/IGTBOK_project.jpg"
import holistic from "../../../../images/axiom_img/portfolio/HolisticBeautyWellness.png"
import DJboogi from "../../../../images/axiom_img/portfolio/djlboogieent.com_project.jpg"
import Dallas from "../../../../images/axiom_img/portfolio/DallasBuysHouses.png"
import iParty from "../../../../images/axiom_img/portfolio/iParty_project.png"
import plumbing from "../../../../images/axiom_img/portfolio/Axiom_Plumbing_Services.jpg"
import Diverse from "../../../../images/axiom_img/portfolio/Diverse_Services.jpg"
import CRM from "../../../../images/axiom_img/portfolio/Axiom_CRM.jpg"
import Electricion from "../../../../images/axiom_img/portfolio/Electricion-services.png"
import Mason from "../../../../images/axiom_img/portfolio/Mason-services.png"
import model1 from "../../../../images/axiom_img/portfolio/model1.jpg"
import model2 from "../../../../images/axiom_img/portfolio/model2.jpg"
import model3 from "../../../../images/axiom_img/portfolio/model3.jpg"
import model4 from "../../../../images/axiom_img/portfolio/model4.jpg"
import model5 from "../../../../images/axiom_img/portfolio/model5.jpg"
import model6 from "../../../../images/axiom_img/portfolio/model6.jpg"
import Model from '../../../components/common/Model';

const websitesData = [
    { id: 'axiom', title: 'Axiom Web Control Services', url: 'https://axiomwebcontrol.com/', image: axiom },
    { id: 'IGTBOK', title: "It's Going To Be OK !", url: 'https://peaceful-lumiere.174-143-48-203.plesk.page', image: IGTBOK },
    { id: 'holistic', title: 'Holistic Wellness Beauty', url: 'https://holisticwellnessbeauty.com/', image: holistic },
    { id: 'DJboogi', title: 'DJ L. Boogie Entertainment', url: 'https://djlboogieent.com/', image: DJboogi },
    { id: 'Dallas', title: 'Dallas Buys Houses', url: 'https://gracious-volhard.174-143-48-203.plesk.page/', image: Dallas },
    { id: 'iParty', title: 'I Party DJ CRM', url: 'https://ipartydjcrm.com', image: iParty },
    { id: 'plumbing', title: 'Axiom Plumbing Services', url: 'https://keen-euclid.174-143-48-203.plesk.page/', image: plumbing },
    { id: 'Electricion', title: 'Axiom Electricion Services', url: 'https://keen-euclid.174-143-48-203.plesk.page/electrician', image: Electricion },
    { id: 'Mason', title: 'Axiom Mason Services', url: 'https://keen-euclid.174-143-48-203.plesk.page/mason', image: Mason },
    { id: 'Diverse', title: 'Diverse Tax Services', url: 'https://cool-greider.174-143-48-203.plesk.page', image: Diverse },
    { id: 'CRM', title: 'Axiom ERP', url: 'https://erp.axiomwebcontrol.com', image: CRM },
];

const Portfolio = () => {
    const [activeTab, setActivetab] = useState("1");
    const [model, setModel] = useState({ path: '/AVReceivermachine.gltf', scale: [1, 1, 1], position: [0, 0, 0] });
    const [fov, setFov] = useState(5);


    const toggle = (tab) => {
        if (activeTab !== tab) setActivetab(tab);
    };

    useEffect(() => {
        const handleResize = () => {
            setFov(window.innerWidth < 768 ? 7 : 5);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleButtonClick = (path, position, scale) => {
        setModel({ path, position, scale });
    };

    return (
        <div className='nk-content'>
            <PreviewCard>
                <Nav tabs className="mt-n3">
                    <NavItem>
                        <NavLink tag="a" href="#tab" className={classnames({ active: activeTab === "1" })}
                            onClick={(ev) => {
                                ev.preventDefault();
                                toggle("1");
                            }}>
                            Websites
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag="a" href="#tab" className={classnames({ active: activeTab === "2" })}
                            onClick={(ev) => {
                                ev.preventDefault();
                                toggle("2");
                            }}>
                            3D Design
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="container">
                            <PortfolioTab items={websitesData.slice(0, 3)} />
                            <PortfolioTab items={websitesData.slice(3, 6)} />
                            <PortfolioTab items={websitesData.slice(6, 9)} />
                            <PortfolioTab items={websitesData.slice(9, 12)} />
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="container">
                            <Row>
                                <div className="col-12 col-lg-8 mb-3">
                                    <Canvas key={model.path} className='rounded rounded-4 border border-2 border-dark' style={{ height: '400px' }} shadows camera={{ position: [0, 0, 10], fov: fov }} dpr={[1, 2]}>
                                        <color attach="background" args={["#79797b"]} />
                                        <ambientLight intensity={0.5} />
                                        <directionalLight castShadow position={[10, 10, 10]} intensity={1} shadow-mapSize={[1024, 1024]} shadow-camera-far={50} shadow-camera-left={-10} shadow-camera-right={10} shadow-camera-top={10} shadow-camera-bottom={-10} />
                                        <SpotLight position={[15, 20, 10]} angle={0.3} penumbra={0.2} intensity={0.8} castShadow shadow-bias={-0.0001} />
                                        <hemisphereLight intensity={0.4} color="#ffffff" groundColor="#444444" />
                                        <OrbitControls maxDistance={10} />
                                        <Environment preset="sunset" background={false}>
                                        </Environment>
                                        <Suspense fallback={
                                            <Html center>
                                                <div style={{
                                                    display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', fontWeight: 'bold', fontSize: '20px', color: 'black'
                                                }}>
                                                    Loading...
                                                </div>
                                            </Html>
                                        }>
                                            <Model modelPath={model.path} position={model.position} scale={model.scale} />
                                        </Suspense>
                                    </Canvas>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="py-3 d-flex flex-wrap justify-content-center">
                                        {[
                                            { label: 'Model 1', path: '/AVReceivermachine.gltf', position: [0, -0.1, 0], scale: [1, 1, 1], thumbnail: model1 },
                                            { label: 'Model 2', path: '/RetroLightLED.gltf', position: [0, -0.3, 0], scale: [0.1, 0.1, 0.1], thumbnail: model2 },
                                            { label: 'Model 3', path: '/LEDLight.gltf', position: [0, -0.2, 0], scale: [0.3, 0.3, 0.3], thumbnail: model3 },
                                            { label: 'Model 4', path: '/ColdSparkMachine.gltf', position: [0, -0.4, 0], scale: [0.07, 0.07, 0.07], thumbnail: model4 },
                                            { label: 'Model 5', path: '/DJMixer.gltf', position: [0, 0, 0], scale: [0.03, 0.03, 0.03], thumbnail: model5 },
                                            { label: 'Model 6', path: '/DjMixer-02.gltf', position: [0, 0, 0], scale: [0.1, 0.1, 0.1], thumbnail: model6 },
                                            // { label: 'Model 7', path: '/Soundsystem.gltf', position: [-0.3, -0.2, 0], scale: [0.4, 0.4, 0.4] },
                                            // ... other models
                                        ].map((item, index) => (
                                            <div key={index} className='p-1' style={{ width: '50%' }}>
                                                <Button size="sm" className=" w-100 mb-2 model-button bg-transparent text-black d-flex justify-content-center align-items-center p-0"
                                                    onClick={() => handleButtonClick(item.path, item.position, item.scale)}
                                                >
                                                    <img src={item.thumbnail} alt={item.label} className="model-thumbnail"
                                                        style={{
                                                            // width: '150px',
                                                            height: '80px',
                                                            objectFit: 'contain',
                                                            borderRadius: '4px'
                                                        }}
                                                    />
                                                    {/* <span>{item.label}</span> */}
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </TabPane>
                </TabContent>
            </PreviewCard>
        </div >
    )
}

export default Portfolio

import React from 'react'
import { Block } from '../../../../../components/Component'

const Aboutsec = () => {
    return (
        <React.Fragment>
            <Block>
                <div className="container py-md-5 py-3">
                    <span className="ff fs-5 " style={{ color: "#2B3E79" }}>
                        Web Infrastructure & Management
                    </span>
                </div>
                <div className="container py-4 ">
                    <div className='d-flex justify-content-center '>
                        <p className='ff fs-2 fw-bolder text-primary text-center '> Empowering Your Online Presence with Exceptional Website Maintenance and Support Services
                        </p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-semibold '>At Axiom, our mission is to empower businesses with robust and reliable website maintenance and support services. We understand the critical role that websites play in today's digital landscape, serving as the cornerstone of online presence and customer engagement. Partner with Axiom and experience a seamless and efficient approach to maintaining and optimizing your digital presence.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-bolder text-primary '>Why do businesses choose our services?</p>
                    </div>
                    <div className='d-flex flex-column  justify-content-center my-4 '>
                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Expertise and Experience: </span>Our team comprises seasoned professionals with extensive experience in website development, maintenance and support. We stay updated with industry trends and best practices to deliver top-notch solutions.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Proactive Approach:</span> Axiom takes a proactive stance towards website management. We identify and resolve issues before they impact your site's performance, enhancing reliability and user experience.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Focus on Security:</span>  Security is paramount in today's digital landscape. We implement robust security measures, including regular updates and monitoring, to safeguard your website against threats.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Timely Updates and Maintenance:</span> Keeping your website up-to-date is crucial for optimal performance. Axiom ensures timely updates, maintenance and backups, minimizing downtime and disruptions.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Unwavering 24/7 Support:</span> Our dedicated and highly skilled support team is readily available 24/7 to address your inquiries and concerns. We prioritize building long-term partnerships and providing exceptional customer service, ensuring you receive the support you deserve whenever you need it.</p>
                    </div>
                </div>
            </Block>
        </React.Fragment>
    )
}

export default Aboutsec

import React from 'react'
import { Block } from '../../../../../components/Component'

const AboutSection = () => {
    return (
        <React.Fragment>
            <Block>
                <div className="container py-md-5 py-3">
                    <span className="ff fs-5 " style={{ color: "#2B3E79" }}>
                        Digital Marketing & Management Services
                    </span>
                </div>
                <div className="container py-4 ">
                    <div className='d-flex justify-content-center '>
                        <p className='ff fs-2 fw-bolder text-primary text-center '>About Axiom: Your Partner in Content Marketing
                        </p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-semibold '>Axiom is an esteemed authority in content marketing. We carefully develop strategic plans that perfectly align with our clients' unique needs and values. By creating engaging and powerful content, we help businesses attract and retain audiences, ultimately driving them towards their goals. Our unwavering dedication is to enhance our clients' market presence and amplify their brand's influence through exceptional content.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-bolder text-primary'>Why do businesses choose our services?</p>
                    </div>
                    <div className='d-flex flex-column  justify-content-center my-4 '>
                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Strategic Alignment: </span> Our services are tailored to align with your unique business goals, values and target audience. We understand that every business is unique and we work closely with you to develop a content marketing strategy that resonates with your audience and supports your business objectives.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Compelling Content Creation: </span> We specialize in creating compelling content that not only engages your audience but also drives action. Whether it's blog posts, social media content, or video marketing, our team of skilled writers, designers and videographers craft content that stands out and captivates your audience.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Data-Driven Insights: </span> We leverage advanced analytics to track the performance of your content across various channels. This data-driven approach allows us to provide you with actionable insights, enabling you to make informed decisions and optimize your content marketing efforts.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Cost-Effective Solutions: </span> We offer cost-effective content marketing solutions that provide a high return on investment. Our strategies are designed to maximize your reach and engagement without breaking the bank, ensuring that your content marketing efforts are both effective and economical.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Dedicated Support: </span> Axiom provides dedicated support and collaboration throughout our engagement. Our goal is to become a trusted partner in your digital marketing journey, guiding you towards sustained success on social media platforms.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-semibold '>Choosing Axiom for your content marketing needs means partnering with a team that is committed to understanding your business, creating compelling content and driving results that align with your goals.</p>
                    </div>
                </div>
            </Block>
        </React.Fragment>
    )
}

export default AboutSection

import React from 'react'
import { Block, Col } from '../../../../../components/Component'
import heroimg from '../../../../../images/axiom_img/ContentMarketing/Content Management.png';


const WhyChooseus = () => {
    return (
        <React.Fragment>
            <Block>
                <div className='nk-content' style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                    <div className="container-fluid p-md-5 py-3  ">
                        <div className="container bg-transparent ">
                            <div className='d-flex justify-content-center mx-md-5'>
                                <p className='ff fs-1 fw-bold text-primary text-center text-capitalize '>Why Choose {process.env.REACT_APP_PROJECT_NAME} for Content Marketing</p>
                            </div>
                        </div>

                        <div className=" row">
                            <Col md='8'>
                                <div className=''>
                                    <div className='d-flex flex-column justify-content-center my-4 '>
                                        <p className='ff fs-4 text-start text-primary '>Axiom is a leading expert in content marketing, with extensive experience and proven success in the field. We are committed to helping clients establish a strong and recognizable brand identity through innovative content marketing strategies that are tailored to their specific needs.</p>

                                        <p className='ff fs-4 text-start text-primary '>In the ever-changing world of digital marketing, we understand that the power of content can transform brands. At Axiom, our approach integrates cutting-edge research, creative storytelling and strategic execution to ensure that every piece of content not only resonates with your target audience but also amplifies your brand’s presence in the marketplace.</p>

                                        <p className='ff fs-4 text-start text-primary '>Whether you want to engage new audiences, increase sales or build lasting relationships with your customers, Axiom is your partner in crafting a content strategy that achieves these goals while elevating your brand to new heights.</p>

                                    </div>
                                </div>
                            </Col>
                            <Col md='4'>
                                <img src={heroimg} alt="Content Management img" className='img-fluid' />

                            </Col>
                        </div>
                    </div>
                </div>
            </Block>

        </React.Fragment>
    )
}

export default WhyChooseus

import React from 'react'
import { Card, CardBody, CardImg, CardText, CardTitle, Col, Row } from 'reactstrap';
import SlideA from "../../../../../images/axiom_img/UI_UXPage-images/02.png";
import SlideB from "../../../../../images/axiom_img/UI_UXPage-images/03.png";
import SlideC from "../../../../../images/axiom_img/UI_UXPage-images/04.png";
import SlideD from "../../../../../images/axiom_img/UI_UXPage-images/05.png";
import SlideE from "../../../../../images/axiom_img/UI_UXPage-images/06.png";
import SlideF from "../../../../../images/axiom_img/UI_UXPage-images/07.png";


const ServiceOffer = () => {
    const bgColor1 = '#D1D7FC';
    const bgColor2 = '#CEE8FF';
    return (
        <div className='container'>
            <Row>
                <Col>
                    <p className="fw-bolder text-primary text-center ff my-5 fs-1 ">Our UI/UX Design Services Offer</p>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideA} alt="img" className='h-350px object-fit-contain img-fluid' />
                        <CardBody className="card-inner " style={{ backgroundColor: `${bgColor2}` }}>
                            <CardTitle className='ff fs-3 fw-bold text-primary '>User Research & Persona Development</CardTitle>
                            <CardText className='text-primary ff'>
                                We employ a rigorous user research process, utilizing surveys, interviews and user testing to uncover your target audience's needs, behaviors and pain points. This data informs the creation of detailed user personas - profiles representing your ideal customers - which serve as a compass for all design decisions.
                            </CardText>
                            {/* <Button color="primary">Go somewhere</Button> */}
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideB} alt="img" className='h-350px object-fit-contain img-fluid' />
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                            <CardTitle className='ff fs-3 fw-bold text-primary'>Strategic Information Architecture & User Flow Design</CardTitle>
                            <CardText className='text-primary ff'>
                                We prioritize user-centricity by structuring content logically and intuitively. This translates into crafting user flows that meticulously map out the steps users take to complete key tasks within your website or application.
                            </CardText>
                            {/* <Button color="primary">Go somewhere</Button> */}
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideC} alt="img" className='h-350px object-fit-contain img-fluid' />
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                            <CardTitle className='ff fs-3 fw-bold text-primary'>High-Fidelity Wireframing & Interactive Prototyping</CardTitle>
                            <CardText className='text-primary ff'>
                                To establish the foundation of your interface, we create high-fidelity wireframes - detailed visual blueprints - that communicate the layout and functionality. Following this, we develop interactive prototypes that simulate the final user experience, enabling you to provide valuable feedback before proceeding with the visual design phase.
                            </CardText>
                            {/* <Button color="primary">Go somewhere</Button> */}
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideD} alt="img" className='h-350px object-fit-contain img-fluid' />
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                            <CardTitle className='ff fs-3 fw-bold text-primary'>Brand-Aligned Visual Design & User Interface (UI) Development</CardTitle>
                            <CardText className='text-primary ff'>
                                Our UI/UX designers leverage their expertise to craft visually appealing and user-friendly interfaces that seamlessly integrate with your established brand identity. This encompasses typography, color palettes, imagery selection and overall aesthetic direction.
                            </CardText>
                            {/* <Button color="primary">Go somewhere</Button> */}
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideE} alt="img" className='h-350px object-fit-contain img-fluid' />
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                            <CardTitle className='ff fs-3 fw-bold text-primary'>Meticulous Usability Testing & Iterative Design Refinement</CardTitle>
                            <CardText className='text-primary ff'>
                                We prioritize user needs through meticulous usability testing with real users. This process unveils any potential issues within the user journey, allowing us to refine and iterate on the design until it delivers a smooth and intuitive experience.
                            </CardText>
                            {/* <Button color="primary">Go somewhere</Button> */}
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideF} alt="img" className='h-350px object-fit-contain img-fluid' />
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                            <CardTitle className='ff fs-3 fw-bold text-primary'>Data-Driven Analytics & Continuous Optimization</CardTitle>
                            <CardText className='text-primary ff'>
                                Our commitment extends beyond launch. We utilize advanced analytics tools to meticulously track user behavior and identify areas for improvement. This ongoing optimization ensures your UI/UX experience remains relevant and drives the most impactful results over time.
                            </CardText>
                            {/* <Button color="primary">Go somewhere</Button> */}
                        </CardBody>
                    </Card>
                </Col>

            </Row>
        </div>
    )
}

export default ServiceOffer

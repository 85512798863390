import React from "react";
import { Outlet } from "react-router-dom";
import Head from "./head/Head";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppWrap from "./global/AppWrap";
import NavbarComponent from "../components/common/Navbar";
import SecureDataFooter from "../pages/silosecuredata/components/SecureDataFooter";

const AppLayout = ({ title, ...props }) => {

    return (
        <React.Fragment>
            <Head title={process.env.REACT_APP_HEAD_TITLE2} />
            <AppRoot className={'overflow-x-hidden'}>
                <AppWrap className={'bg-white'}>
                    {/* <Header fixed /> */}
                    <NavbarComponent fixed />
                    <Outlet />
                    <SecureDataFooter />
                    <Footer />
                </AppWrap>
            </AppRoot>
        </React.Fragment>
    );
};
export default AppLayout;

import React from 'react'
import { Card, CardBody, CardHeader, CardImg, CardText, CardTitle, Col, Row } from 'reactstrap'
import SlideA from "../../../../../images/axiom_img/BrandEvolution/Brand Identity.png";
import SlideB from "../../../../../images/axiom_img/BrandEvolution/Brand Messages.png";
import SlideC from "../../../../../images/axiom_img/BrandEvolution/Visual Design.png";
import SlideD from "../../../../../images/axiom_img/BrandEvolution/Guidlines & Standerds.png";
import SlideE from "../../../../../images/axiom_img/BrandEvolution/Strategy.png";
import SlideF from "../../../../../images/axiom_img/BrandEvolution/Brand Integration.png";

const SectionService = () => {
    const bgColor1 = '#D1D7FC';
    const bgColor2 = '#CEE8FF';
    return (
        <div className='container'>
            <Row>
                <Col>
                    <p className="fw-bolder text-primary text-center ff my-5 fs-1 ">Axiom’s Unique Approach to Brand Evolution</p>
                </Col>
            </Row>
            <Row>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100" inverse style={{ "boxShadow": "12px 12px 20px -14px rgba(0,0,0,0.7)" }}>
                        <CardHeader className='text-primary ff fw-bold' style={{ backgroundColor: `${bgColor2}` }}>01
                        </CardHeader>
                        <CardBody className="card-inner p-2 p-md-4" style={{ backgroundColor: `${bgColor2}` }}>
                            <Col className='text-center'>
                                <CardImg top src={SlideA} alt="Brand Identity" className='w-25' />
                            </Col>
                            <Col className='text-center'>
                                <CardTitle tag="h5" className='text-primary ff fw-bold '>Brand Identity Development</CardTitle>
                                <CardText className='text-primary ff fw-bold text-start'>
                                    Axiom helps businesses craft a distinct brand identity that effectively communicates their values and uniqueness to their target audience. This service includes the creation of a logo, color palette, typography and other visual elements that make up the brand's aesthetic.
                                </CardText>
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100" inverse color="primary" style={{ "boxShadow": "12px 12px 20px -14px rgba(0,0,0,0.7)" }}>
                        <CardHeader className='text-primary ff fw-bold ' style={{ backgroundColor: `${bgColor1}` }}>02</CardHeader>
                        <CardBody className="card-inner p-2 p-md-4" style={{ backgroundColor: `${bgColor1}` }}>
                            <Col className='text-center'>
                                <CardImg top src={SlideB} alt="Brand Message" className='w-25' />
                            </Col>
                            <Col className='text-center'>
                                <CardTitle tag="h5" className='text-primary ff fw-bold'>Brand Messaging and Positioning</CardTitle>
                                <CardText className='text-primary ff fw-bold text-start'>
                                    This service focuses on defining the brand’s voice and the unique value proposition it offers. Axiom works to align the brand’s messaging across all platforms ensuring a cohesive and powerful narrative.
                                </CardText>
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100" inverse color="primary" style={{ "boxShadow": "12px 12px 20px -14px rgba(0,0,0,0.7)" }}>
                        <CardHeader className='text-primary ff fw-bold ' style={{ backgroundColor: `${bgColor2}` }}>03</CardHeader>
                        <CardBody className="card-inner p-2 p-md-4" style={{ backgroundColor: `${bgColor2}` }}>
                            <Col className='text-center'>
                                <CardImg top src={SlideC} alt="Visual Design" className='w-25' />
                            </Col>
                            <Col className='text-center'>
                                <CardTitle tag="h5" className='text-primary ff fw-bold'> Brand Visual Design</CardTitle>
                                <CardText className='text-primary ff fw-bold text-start'>
                                    Beyond the basic elements of brand identity, Axiom creates compelling visual designs that can be applied across various media—from digital advertising to print materials. This service ensures all visual communications align with the brand’s identity and goals.
                                </CardText>
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100" inverse color="primary" style={{ "boxShadow": "12px 12px 20px -14px rgba(0,0,0,0.7)" }}>
                        <CardHeader className='text-primary ff fw-bold ' style={{ backgroundColor: `${bgColor1}` }}>04</CardHeader>
                        <CardBody className="card-inner p-2 p-md-4" style={{ backgroundColor: `${bgColor1}` }}>
                            <Col className='text-center'>
                                <CardImg top src={SlideD} alt="Brand Guidelines and Standards" className='w-25' />
                            </Col>
                            <Col className='text-center'>
                                <CardTitle tag="h5" className='text-primary ff fw-bold'>Brand Guidelines and Standards</CardTitle>

                                <CardText className='text-primary ff fw-bold text-start'>
                                    Axiom develops comprehensive brand guidelines that detail how all aspects of the brand should be used and communicated. These guidelines include instructions on logo usage, color palette, typography and the overall tone of the brand.
                                </CardText>
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100" inverse color="primary" style={{ "boxShadow": "12px 12px 20px -14px rgba(0,0,0,0.7)" }}>
                        <CardHeader className='text-primary ff fw-bold ' style={{ backgroundColor: `${bgColor2}` }}>05</CardHeader>
                        <CardBody className="card-inner p-2 p-md-4" style={{ backgroundColor: `${bgColor2}` }}>
                            <Col className='text-center'>
                                <CardImg top src={SlideE} alt="Brand Strategy" className='w-25' />
                            </Col>
                            <Col className='text-center'>
                                <CardTitle tag="h5" className='text-primary ff fw-bold'> Brand Strategy and Planning</CardTitle>

                                <CardText className='text-primary ff fw-bold text-start'>
                                    This strategic service focuses on aligning the brand’s business objectives with its market presence. Axiom assists in long-term planning, including market analysis, competitor analysis and strategic growth opportunities.
                                </CardText>
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100" inverse color="primary" style={{ "boxShadow": "12px 12px 20px -14px rgba(0,0,0,0.7)" }}>
                        <CardHeader className='text-primary ff fw-bold' style={{ backgroundColor: `${bgColor1}` }}>06</CardHeader>
                        <CardBody className="card-inner p-2 p-md-4" style={{ backgroundColor: `${bgColor1}` }}>
                            <Col className='text-center'>
                                <CardImg top src={SlideF} alt="Brand Integration" className='w-25' />
                            </Col>
                            <Col className='text-center'>
                                <CardTitle tag="h5" className='text-primary ff fw-bold'>Digital Brand Integration</CardTitle>
                                <CardText className='text-primary ff fw-bold text-start'>
                                    Axiom extends brand identity into the digital realm, ensuring that all online platforms reflect the brand’s core principles and visual identity. This includes website design, social media profiles and digital marketing campaigns.
                                </CardText>
                            </Col>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
        </div>
    )
}

export default SectionService

import React from "react";
import Slider from "react-slick";
import { NextArrow, PrevArrow } from "./Arrow";
import SliderItem from "./SliderItem";


function SimpleSlider({ sliderItems }) {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        autoplay: true,
        autoplaySpeed: 6000,
        pauseOnHover: true,
    };

    return (
        <div className="slider-container">
            <Slider {...settings}>
                {sliderItems.map((item, index) => (
                    <SliderItem key={index} {...item} />
                ))}
            </Slider>
        </div>
    );
}

export default SimpleSlider;
import React, { useState, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { Card, CardBody, CardHeader, CardText, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classNames from 'classnames';

// Import all images
import fintec from '../../../images/siloimages/fintech.png';
import eLearning from '../../../images/siloimages/mortarboard.png';
import Manufacturing from '../../../images/siloimages/power-plant.png';
import Healthcare from '../../../images/siloimages/protection.png';
import Transportation from '../../../images/siloimages/truck.png';
import Insurance from '../../../images/siloimages/security.png';

// Define industry data
const industries = [
    { id: '1', name: 'Fintech', icon: fintec, title: 'Fintech Software Solutions', content: 'Silo Secure Data offers secure, multi-currency payment solutions for global transactions. Our platform supports seamless cross-border transfers, wallet-to-wallet transfers, bank transfers, and payments on merchant sites, ensuring secure and efficient transactions worldwide.' },
    { id: '2', name: 'Manufacturing', icon: Manufacturing, title: 'Manufacturing Industry Software Solutions', content: 'Silo Secured Data Manufacturing solutions play a crucial role in enhancing efficiency, streamlining processes, and driving innovation. These solutions are designed to automate tasks, manage production processes, and optimize operations, ultimately leading to cost-effectiveness and improved productivity.' },
    { id: '3', name: 'E-Learning', icon: eLearning, title: 'E-Learning Software Solutions', content: 'At SiloSecure Data Web Services, we offer comprehensive software solutions to revolutionize the E-Learning landscape. Our cutting-edge technologies and expertise enable educational institutions and corporate training programs to deliver engaging, effective, and secure learning experiences.' },
    { id: '4', name: 'Healthcare', icon: Healthcare, title: 'Healthcare Management Software', content: 'At SiloSecure Data Web Services, we specialize in cutting-edge software solutions designed to revolutionize healthcare management. Our comprehensive suite of tools is carefully crafted to streamline operations, enhance patient care, and optimize efficiency across healthcare organizations.' },
    { id: '5', name: 'Transportation', icon: Transportation, title: 'Transportation Management Software', content: 'Silo Secured Data understands the ever-changing regulatory landscape. We leverage this knowledge to develop cost-cutting solutions that meet compliance requirements and boost your bottom line.' },
    { id: '6', name: 'Insurance', icon: Insurance, title: 'Insurance Management Software', content: 'Silo Secured Data understands the ever-changing regulatory landscape. We leverage this knowledge to develop cost-cutting solutions that meet compliance requirements and boost your bottom line.' },
];

const IndustryNavItem = React.memo(({ industry, activeTab, toggle }) => (
    <NavItem className='py-1 border-1 border-bottom border-dark-subtle'>
        <NavLink
            className={`socialbtn fs-5 ff-alt text-dark ${classNames({ active: activeTab === industry.id })}`}
            onMouseEnter={() => toggle(industry.id)}
            style={{ cursor: 'pointer' }}
        >
            <img src={industry.icon} alt={industry.name} className='mx-1' style={{ maxWidth: '16%' }} />
            {industry.name}
        </NavLink>
    </NavItem>
));

const IndustryCard = React.memo(({ industry }) => (
    <Card className="card-bordered bg-primary" style={{ height: '490px' }}>
        <CardBody className="card-inner">
            <CardHeader className='d-flex justify-content-center text-white fs-1 bg-transparent p-md-3 p-1'>
                <img src={industry.icon} alt={industry.name} className='mx-2 text-white' style={{ maxWidth: '16%' }} />
            </CardHeader>
            <CardTitle className='text-center ff-alt text-white fs-2 fw-bold'>{industry.title}</CardTitle>
            <CardText className='ff-alt text-white p-md-3 p-1 fs-5 text-start'>
                {industry.content}
            </CardText>
        </CardBody>
    </Card>
));

const Solutions = () => {
    const [activeTab, setActiveTab] = useState("1");
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const navItems = useMemo(() => (
        industries.map(industry => (
            <IndustryNavItem key={industry.id} industry={industry} activeTab={activeTab} toggle={toggle} />
        ))
    ), [activeTab]);

    const tabPanes = useMemo(() => (
        industries.map(industry => (
            <TabPane key={industry.id} tabId={industry.id}>
                <IndustryCard industry={industry} />
            </TabPane>
        ))
    ), []);

    return (
        <div className="container py-md-5 py-3" id='industries'>
            <div className='py-5'>
                <h3 className="ff-alt fw-semibold text-capitalize text-center mb-4" style={{ color: "#17253D" }}>
                    Powerful Software Solutions for Every Industry
                </h3>
            </div>
            <div className='container border-0' style={{ backgroundColor: '#E8F7FF', boxShadow: '0px 2px 54px 20px rgba(101, 197, 223, 0.2)' }}>
                <Row>
                    <Col md={12} lg={7} className="d-flex align-items-center">
                        <div className='container'>
                            <Row className="justify-content-around">
                                <Col md={5} className="d-flex align-items-center justify-content-center">
                                    <Nav className='text-start text-black fs-4'>
                                        {navItems.slice(0, 3)}
                                    </Nav>
                                </Col>
                                <Col md={5} className="d-flex align-items-center justify-content-center mt-3 mt-md-0">
                                    <Nav className='text-start text-black fs-4'>
                                        {navItems.slice(3)}
                                    </Nav>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={12} lg={5} className="py-4">
                        <TabContent activeTab={activeTab}>
                            {tabPanes}
                        </TabContent>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default React.memo(Solutions);
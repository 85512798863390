import React from 'react'
import Head from '../../../../layout/head/Head'
import Content from '../../../../layout/content/Content'
import AboutWeb from './WebDevComponents/AboutWeb'
import ServicesSection from './WebDevComponents/ServiceSection'
import Contact from '../../components/Contact'
import Testimonial from '../components/Testimonial'
import StaticVsDynamicSection from './WebDevComponents/StaticVSDynamic'
import GenericHero from '../components/GenericHero'
import { Icon } from '../../../../components/Component'
import heroimg from '../../../../images/axiom_img/Static & Dynamic.png';


const WebDev = () => {
    return (
        <React.Fragment>
            <Head title="Axiom | Website Devlopment" />
            <Content>
                <GenericHero
                    breadcrumbs={
                        <>
                            SERVICES <Icon name={'chevron-right'} />Software Development Services<Icon name={'chevron-right'} />Static & Dynamic Website Development

                        </>
                    }
                    title=" Transform your online presence with Axiom's static and dynamic website development services"
                    description="Experience the power of custom website development that delivers results"
                    buttonText="Contact Now"
                    buttonLink="/contact"
                    heroImage={heroimg} />
                <AboutWeb />
                <ServicesSection />
                <StaticVsDynamicSection />
                <Contact />
                <Testimonial />
            </Content>


        </React.Fragment>
    )
}

export default WebDev

import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import { Link } from 'react-scroll'
import imgUrl from '../../../images/siloimages/Backgroundimg.png'

const Home = () => {
    return (
        <div className="home-section vh-100 d-flex align-items-center nk-content" id='home' style={{
            backgroundImage: `url(${imgUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'right',
            backgroundRepeat: 'no-repeat',
        }}>
            <Container fluid className='ms-md-5'>
                <Row className='justify-content-center justify-content-md-start'>
                    <Col xs={12} md={8} lg={6} className="px-4 px-md-5 py-4 py-md-5">
                        <h1 className='fw-semibold text-capitalize mb-4 ff-alt' style={{ color: '#17253D' }}>
                            Let's Take Your Brand to Next Level
                        </h1>
                        <Link to="contact" smooth={true} offset={-10} duration={100}>
                            <Button color="primary" size="lg" className='px-5 py-2 ff-alt button'>
                                Get In Touch
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Home
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const WhitelabeledButton = ({
  to,
  buttonText,
  buttonColor,
  buttonOutline,
  className,
}) => (
  <div className={`${className}`}>
    <Link to={to}>
      <Button
        outline={buttonOutline === 'true' || buttonOutline === true}
        color={buttonColor}
        className="ff">
        {buttonText}
      </Button>
    </Link>
  </div>
);

export default WhitelabeledButton;
import React from 'react'
import Head from '../../../../layout/head/Head'
import Content from '../../../../layout/content/Content'
import HostAbout from './HostingComponents/HostAbout'
import HostServices from './HostingComponents/HostServices'
import Contact from '../../components/Contact'
import WhyChooseUs from './HostingComponents/WhyChooseUs'
import Testimonial from '../components/Testimonial'
import GenericHero from '../components/GenericHero'
import { Icon } from '../../../../components/Component'
import heroimg from '../../../../images/axiom_img/Web Hosting.png';



const WebHostingPage = () => {
    return (
        <React.Fragment>
            <Head title="Axiom | Website Hosting Service" />
            <Content>
                <GenericHero
                    breadcrumbs={
                        <>
                            SERVICES <Icon name={'chevron-right'} /> Web Infrastructure & Management<Icon name={'chevron-right'} /> Website Hosting

                        </>
                    }
                    title="Welcome to Axiom web hosting: Your gateway to reliable online solutions"
                    description="Grow with Confidence: Scalable solutions that keep your website secure and reliable."
                    buttonText="Contact Now"
                    buttonLink="/contact"
                    heroImage={heroimg} />
                <HostAbout />
                <HostServices />
                <Contact />
                <WhyChooseUs />
                <Testimonial />
            </Content>
        </React.Fragment>
    )
}

export default WebHostingPage

import React from 'react'
import { Block, Col } from '../../../../../components/Component'
import SlideA from "../../../../../images/axiom_img/Domain Reg. & Managment.png";


const SectionService = () => {
    return (
        <React.Fragment>
            <Block>
                <div className='nk-content' style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                    <div className="container-fluid py-4 ">
                        <div className="container bg-transparent ">
                            <div className='d-flex justify-content-center mx-md-5'>
                                <p className='ff fs-1 fw-bolder text-primary text-center '> Partner with Axiom Team to streamline your domain management effortlessly </p>
                            </div>
                        </div>

                        <div className=" row">
                            <Col md='7'>
                                <div className=''>
                                    <div className='d-flex flex-column justify-content-center my-4 '>
                                        <p className='ff fs-4 text-start text-primary '>In today's digital landscape, a well-chosen domain name is a cornerstone of your online identity. At {process.env.REACT_APP_PROJECT_NAME}, we understand the importance of securing your ideal domain efficiently and effortlessly. That's why we offer a meticulously crafted domain registration process designed to empower you from start to finish. </p>

                                        <p className='ff fs-4 text-start text-primary '> Our user-friendly platform streamlines the entire journey, guiding you through domain name availability checks, selection and registration with ease. Should you require expert guidance or have any questions along the way, our team of domain specialists is always available to assist you. Their extensive knowledge ensures you not only secure the perfect domain name for your brand but also receive expert advice on best practices for ongoing domain management.</p>

                                        <p className='ff fs-4 text-start text-primary '> Partner with {process.env.REACT_APP_PROJECT_NAME} to lay a robust digital foundation and set your brand on the path to flourishing online. With our streamlined domain registration process and dedicated support, you can focus on building your online presence with confidence.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md='5' className='align-self-center '>
                                <img top src={SlideA} alt="Domain Management" className='img-fluid ' />
                            </Col>
                        </div>
                    </div>
                </div>
            </Block>

        </React.Fragment>
    )
}

export default SectionService

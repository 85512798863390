import React from 'react'
import { Block, Row } from '../../../../../components/Component'
import { Col } from 'reactstrap'
import SlideA from '../../../../../images/axiom_img/Erp&Crm-images/ERP CRM 2nd Img.png'
const AboutSection = () => {
    return (
        <React.Fragment>
            <Block>
                <div className="container py-5">
                    <span className="ff fs-5 " style={{ color: "#2B3E79" }}>
                        Software Development Services
                    </span>
                </div>
                <div className="container py-4 ">
                    <div className='d-flex justify-content-center '>
                        <p className='ff fs-1 fw-bolder text-primary text-center '>{process.env.REACT_APP_PROJECT_NAME}: Transformative ERP and CRM Software Solutions</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-semibold '>{process.env.REACT_APP_PROJECT_NAME} expertise combined with its client-centric approach has earned the trust of organizations across various industries. Partner with {process.env.REACT_APP_PROJECT_NAME} and unlock the true potential of your business through innovative ERP and CRM software solutions.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-bolder text-primary '>What makes our approach to ERP and CRM truly transformative?</p>
                    </div>
                    <Row style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                        <Col md='7'>
                            <div className='d-flex flex-column justify-content-center my-4 '>
                                <div className='ff fs-6 text-start text-primary pb-3 '><span className='fw-bold'> Industry-Specific Expertise:</span>
                                    <div className='list'>
                                        <ul>
                                            <li>
                                                <p>Tailored solutions for industries such as manufacturing, retail, healthcare and professional services.</p>
                                            </li>
                                            <li>
                                                <p>Deep understanding of unique challenges and requirements in each sector.</p>
                                            </li>
                                            <li>
                                                <p>Seamless integration with industry-specific processes and regulations.</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='ff fs-6 text-start text-primary pb-3'><span className='fw-bold'> Cloud-Based Deployment Options:</span>
                                    <div className='list'>
                                        <ul>
                                            <li>
                                                <p>Flexible deployment models, including on-premise, cloud and hybrid solutions.</p>
                                            </li>
                                            <li>
                                                <p>Scalable infrastructure to accommodate business growth and evolving needs.</p>
                                            </li>
                                            <li>
                                                <p>Reduced IT infrastructure costs and enhanced data security.</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='ff fs-6 text-start text-primary pb-3'><span className='fw-bold'> Comprehensive Implementation and Integration Services:</span>
                                    <div className='list'>
                                        <ul>
                                            <li>
                                                <p>A dedicated project management and implementation support.</p>
                                            </li>
                                            <li>
                                                <p>Smooth data migration and integration with existing systems.</p>
                                            </li>
                                            <li>
                                                <p>Extensive training and change management assistance for a seamless transition.
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='ff fs-6 text-start text-primary pb-3'><span className='fw-bold'> Responsive Support and Maintenance:</span>
                                    <div className='list'>
                                        <ul>
                                            <li>
                                                <p>Dedicated team of experts providing prompt and reliable support.</p>
                                            </li>
                                            <li>
                                                <p>Proactive software updates and security patches to ensure optimal performance.</p>
                                            </li>
                                            <li>
                                                <p>Comprehensive training and ongoing guidance for a hassle-free user experience.</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md='5' className='align-self-center'>
                            <img top src={SlideA} alt="about_img" className='img-fluid ' />
                        </Col>
                    </Row>
                </div>

            </Block>
        </React.Fragment >
    )
}

export default AboutSection

export const defaultOptions = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

export const colourData = [
  { value: "red", label: "Red" },
  { value: "blue", label: "Blue" },
  { value: "green", label: "Green" },
  { value: "yellow", label: "Yellow" },
  { value: "white", label: "White" },
];
export const serviceData = [
  { value: "0", label: "UI / UX Design" },
  { value: "1", label: "Graphics Design" },
  { value: "2", label: "3D Design" },
  { value: "3", label: "Game Design" },
  { value: "4", label: "Website Development" },
  { value: "5", label: "E-Commerce Website Development" },
  { value: "6", label: "ERP / CRM Development" },
  { value: "7", label: "Mobile App Development" },
  { value: "8", label: "Website Hosting" },
  { value: "9", label: "Domain Registration & Management" },
  { value: "10", label: "Dedicated Server Service" },
  { value: "11", label: "Website Maintenance & Support" },
  { value: "12", label: "SEO & Digital Marketing" },
  { value: "13", label: "Social Media Management & Creation" },
  { value: "14", label: "Brand Evolution" },
  { value: "15", label: "Content Marketing" },
];

export const groupedData = [
  {
    label: "Flavours",
    options: [
      { value: "chocolate", label: "Chocolate" },
      { value: "strawberry", label: "Strawberry" },
      { value: "vanilla", label: "Vanilla" },
    ],
  },
  {
    label: "Colors",
    options: [
      { value: "red", label: "Red" },
      { value: "blue", label: "Blue" },
      { value: "green", label: "Green" },
    ],
  },
  { label: "UnGrouped", value: "value_3" },
];

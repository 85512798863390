import React from 'react'
import { Block } from '../../../../../components/Component'
import { Col } from 'reactstrap'
import SlideA from '../../../../../images/axiom_img/GraphicPage-images/2 Image.png'


const ChooseUs = () => {
    return (
        <React.Fragment>
            <Block>
                <div className='nk-content' style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                    <div className="container py-4 ">
                        <div className="container bg-transparent ">
                            <div className='d-flex justify-content-center mx-md-5'>
                                <p className='ff fs-1 fw-bolder text-primary text-center '>What makes {process.env.REACT_APP_PROJECT_NAME}  stand out in the field of Graphic Design Services? </p>
                            </div>
                        </div>

                        <div className=" row">
                            <Col md='8'>
                                <div className=''>
                                    <div className='d-flex flex-column justify-content-center my-4 '>
                                        <p className='ff fs-4 text-start text-primary '>Struggling to visually communicate your brand's unique identity? Look no further than {process.env.REACT_APP_PROJECT_NAME}'s award-winning graphic design services. We go beyond creating pretty pictures; we're storytellers. </p>

                                        <p className='ff fs-4 text-start text-primary '> Our team of acclaimed designers, armed with data-driven insights and a collaborative spirit, crafts a cohesive visual language that resonates with your target audience.  We don't offer a "one-size-fits-all" approach; instead, we delve into your brand essence and marketing goals to deliver bespoke solutions that seamlessly integrate with your strategy.  </p>

                                        <p className='ff fs-4 text-start text-primary '>The results? Captivating visuals that not only elevate your brand identity but also deliver measurable results, demonstrably impacting brand awareness, engagement and ROI. Partner with {process.env.REACT_APP_PROJECT_NAME} and unlock the power of professional visual storytelling.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md='4' className='align-self-center'>
                                <img top src={SlideA} alt="Graphic_img" className='img-fluid ' />
                            </Col>
                        </div>
                    </div>
                </div>
            </Block>

        </React.Fragment>
    )
}

export default ChooseUs

import React, { createContext, useState, useEffect, useContext } from 'react';

const TabContext = createContext();

export const TabProvider = ({ children }) => {
    const [activeTab, setActiveTab] = useState("1"); // Always initialize to "1"

    useEffect(() => {
        // Clear any stored tab on mount (page load/reload)
        localStorage.removeItem('activeTab');
    }, []); // Empty dependency array means this runs once on mount

    const setAndStoreActiveTab = (tab) => {
        setActiveTab(tab);
        localStorage.setItem('activeTab', tab);
    };

    return (
        <TabContext.Provider value={{ activeTab, setActiveTab: setAndStoreActiveTab }}>
            {children}
        </TabContext.Provider>
    );
};

export const useTab = () => useContext(TabContext);
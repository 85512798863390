import React from "react";
import { Icon } from "../../../../components/Component";

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ left: "-24px", background: 'black', border: 'none' }}>
      <Icon name={'chevron-left'} className={'text-white'} />
    </div>
  );
};

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ right:"-24px", background: 'black', border: 'none' }}>
      <Icon name={'chevron-right'} className={'text-white'} />
    </div>
  );
};

export { PrevArrow, NextArrow };


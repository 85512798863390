import React from 'react'
import { Card, CardBody, CardImg, CardText, CardTitle, Col, Row } from 'reactstrap'
import SlideA from "../../../../../images/axiom_img/GraphicPage-images/06.png";
import SlideB from "../../../../../images/axiom_img/GraphicPage-images/User Experience.png";
import SlideC from "../../../../../images/axiom_img/GraphicPage-images/07.png";

const ImpOfService = () => {
    const bgColor2 = '#D1D7FC';
    return (
        <div className='container-fluid' style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
            <Row>
                <Col>
                    <p className="fw-bolder text-primary text-center ff my-5 fs-1 " >How can a graphic design service benefit me?</p>
                </Col>
            </Row>
            <div className="container">

                <Row className='pt-4' >
                    <Col md={4} className="mb-4">
                        <Card className="card-bordered h-100 rounded-5">
                            <CardImg top src={SlideA} alt="Brand Identity" className='h-350px object-fit-contain img-fluid rounded-5' />
                            <CardBody className="card-inner rounded-bottom-5" style={{ backgroundColor: `${bgColor2}` }}>
                                <CardTitle className='ff fs-3 fw-bold text-primary '>01. Solidified Brand Identity</CardTitle>
                                <CardText className='text-primary ff'>
                                    Professional graphic design establishes a cohesive visual language that embodies your brand essence. This fosters brand recognition, memorability and trust with your target audience, positioning you as a leader in your industry.
                                </CardText>
                                {/* <Button color="primary">Go somewhere</Button> */}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4} className="mb-4">
                        <Card className="card-bordered h-100 rounded-5 ">
                            <CardImg top src={SlideB} alt="User Experience" className='h-350px object-fit-contain img-fluid' />
                            <CardBody className="card-inner rounded-bottom-5" style={{ backgroundColor: `${bgColor2}` }}>
                                <CardTitle className='ff fs-3 fw-bold text-primary'>02. Elevated User Experience (UX)</CardTitle>
                                <CardText className='text-primary ff'>
                                    User-centered graphic design principles lead to visually appealing and intuitive interfaces. This translates into a seamless user experience on your website or application, increasing user satisfaction, engagement and time spent interacting with your brand.
                                </CardText>
                                {/* <Button color="primary">Go somewhere</Button> */}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4} className="mb-4">
                        <Card className="card-bordered h-100 rounded-5">
                            <CardImg top src={SlideC} alt="Enhanced Marketing" className='h-350px object-fit-contain img-fluid' />
                            <CardBody className="card-inner rounded-bottom-5" style={{ backgroundColor: `${bgColor2}` }}>
                                <CardTitle className='ff fs-3 fw-bold text-primary'>03. Measurably Enhanced Marketing Impact</CardTitle>
                                <CardText className='text-primary ff'>
                                    User-centered graphic design principles lead to visually appealing and intuitive interfaces. This translates into a seamless user experience on your website or application, increasing user satisfaction, engagement and time spent interacting with your brand.
                                </CardText>
                                {/* <Button color="primary">Go somewhere</Button> */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

        </div>
    )
}

export default ImpOfService

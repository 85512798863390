import React, { useState } from "react";
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { Icon } from "../../../../components/Component";

const SliderSample = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const items = [
        <Card key={1} className="card-bordered p-0 p-md-5 rounded-5 h-100 " inverse style={{
            backgroundImage: process.env.React_APP_SLIDER_BACKGROUND_COLOR
        }}>
            <CardHeader className="align-items-center rounded-5" style={{
                backgroundImage: process.env.React_APP_SLIDER_BACKGROUND_COLOR
            }}>
                <div className="d-flex justify-content-center">
                    <Icon name={'star-fill'} className={'fs-3 text-warning'} />
                    <Icon name={'star-fill'} className={'fs-3 text-warning'} />
                    <Icon name={'star-fill'} className={'fs-3 text-warning'} />
                    <Icon name={'star-fill'} className={'fs-3 text-warning'} />
                    <Icon name={'star-fill'} className={'fs-3 text-warning'} />
                </div>
            </CardHeader>
            <CardBody className="card-inner d-flex flex-column align-items-center">
                <div className="col-md-10 text-center">
                    <p className="fs-6 ff">
                        "Increased leads with a new website. AXIOM designed a user-friendly, SEO-optimized site. Boosted brand awareness. Their data-driven marketing strategies drove traffic and sales. Their dedication to customer service made the whole experience smooth and stress-free."
                    </p>
                </div>
                <div className="p-2">
                    <CardTitle tag="h5" className="ff text-center">-Mark Farley</CardTitle>
                </div>
            </CardBody>
        </Card>,
        <Card key={2} className="card-bordered p-0 p-md-5 rounded-5 h-100 " inverse style={{
            backgroundImage: process.env.React_APP_SLIDER_BACKGROUND_COLOR
        }}>
            <CardHeader className="align-items-center rounded-5" style={{
                backgroundImage: 'linear-gradient(to right, #004A9E 30%, #002A58 70%)'
            }}>
                <div className="d-flex justify-content-center">
                    <Icon name={'star-fill'} className={'fs-3 text-warning'} />
                    <Icon name={'star-fill'} className={'fs-3 text-warning'} />
                    <Icon name={'star-fill'} className={'fs-3 text-warning'} />
                    <Icon name={'star-fill'} className={'fs-3 text-warning'} />
                    <Icon name={'star-fill'} className={'fs-3 text-warning'} />
                </div>
            </CardHeader>
            <CardBody className="card-inner d-flex flex-column align-items-center">
                <div className="col-md-10 text-center">
                    <p className="fs-6 ff">
                        "Axiom's SEO services were a game-changer for our business. We saw a dramatic increase in organic traffic within a few months. Their team is knowledgeable about the latest SEO trends and implemented a comprehensive strategy that delivered results."
                    </p>
                </div>
                <div className="p-2">
                    <CardTitle tag="h5" className="ff">-Jarad Bytawave</CardTitle>
                </div>
            </CardBody>
        </Card>,
        <Card key={3} className="card-bordered p-0 p-md-5 rounded-5 h-100 " inverse style={{
            backgroundImage: process.env.React_APP_SLIDER_BACKGROUND_COLOR
        }}>
            <CardHeader className="align-items-center rounded-5 " style={{
                backgroundImage: process.env.React_APP_SLIDER_BACKGROUND_COLOR
            }}>
                <div className="d-flex justify-content-center">
                    <Icon name={'star-fill'} className={'fs-3 text-warning'} />
                    <Icon name={'star-fill'} className={'fs-3 text-warning'} />
                    <Icon name={'star-fill'} className={'fs-3 text-warning'} />
                    <Icon name={'star-fill'} className={'fs-3 text-warning'} />
                    <Icon name={'star-fill'} className={'fs-3 text-warning'} />
                </div>
            </CardHeader>
            <CardBody className="card-inner d-flex flex-column align-items-center">
                <div className="col-md-9 text-center">
                    <p className="fs-6 ff">
                        "Our team thrives on innovation. Combining passion for technology with a commitment to learning, we bridge the gap between technical and business needs. We deliver exceptional digital products and experiences."
                    </p>
                </div>
                <div className="p-2">
                    <CardTitle tag="h5" className="ff">-Neal Dunn</CardTitle>
                </div>
            </CardBody>
        </Card>,
    ];

    const slides = items.map((item, index) => {
        return (
            <CarouselItem
                className="text-white"
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={index}>
                {item}
            </CarouselItem>
        );
    });

    return (
        <div className="container p-0">
            <Carousel pause={'hover'} interval={8000} activeIndex={activeIndex} next={next} previous={previous}>
                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
            </Carousel>
        </div>
    )
}

export default SliderSample
import React from 'react'
import { Block } from '../../../../../components/Component'

const SectionAbout = () => {
    return (
        <React.Fragment>
            <Block>
                <div className="container py-md-5 py-3">
                    <span className="ff fs-5 " style={{ color: "#2B3E79" }}>
                        Digital Marketing & Management Services
                    </span>
                </div>
                <div className="container py-4 ">
                    <div className='d-flex justify-content-center '>
                        <p className='ff fs-2 fw-bolder text-primary text-center '>About Axiom: Your Partner in Brand Evolution
                        </p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-semibold '>Axiom is a distinguished authority in brand evolution, with a proven track record of success and innovation in helping businesses strengthen and transform their brand identities. At Axiom, we understand the importance of a compelling brand identity. We work closely with clients to develop strategic brand evolution plans that align with their unique needs, values and market positioning.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-bolder text-primary '>why do businesses choose our services?</p>
                    </div>
                    <div className='d-flex flex-column  justify-content-center my-4 '>
                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Proven Expertise: </span>Axiom brings extensive expertise and a successful track record in brand evolution. Our team of seasoned professionals has helped numerous businesses across industries revitalize and strengthen their brand identities.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Tailored Strategies: </span> We understand that every business is unique. Axiom develops customized brand evolution strategies that align with each client's specific goals, values and market positioning, ensuring a tailored approach that resonates with their target audience.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Innovative Approach: </span> Axiom stands out for its innovative approach to brand evolution. We leverage cutting-edge techniques and creative solutions to breathe new life into brands, fostering relevance and differentiation in competitive markets.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Adaptability and Future-Readiness:</span> Our brand evolution strategies are designed to be adaptable and future-ready. Axiom equips businesses with brand identities that can evolve and grow alongside changing market dynamics and consumer behaviors.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Dedicated Support:</span> Axiom provides dedicated support and collaboration throughout our engagement. Our goal is to become a trusted partner in your digital marketing journey, guiding you toward sustained success on social media platforms.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-semibold '>Choosing Axiom for brand evolution means partnering with a trusted advisor dedicated to helping businesses stand out, connect with audiences and thrive in competitive markets through strategic and impactful brand transformation.</p>
                    </div>
                </div>
            </Block>
        </React.Fragment>
    )
}

export default SectionAbout

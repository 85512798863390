import React from 'react'
import { Col, Row } from 'reactstrap'

const ServiceOffer = () => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <p className="fw-bolder text-primary text-center text-capitalize ff my-5 fs-2 px-3 px-md-0">Our 3D Design Services Offer</p>
                </Col>
            </Row>
            <div className="container-fluid" >
                <Row className='justify-content-center'>
                    <Col md='11' >
                        <div className="container-fluid d-flex flex-column align-items-center rounded-5  " style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                            <Col md='8' className=''>
                                <p className='text-center  text-primary ff fs-5 pt-3'>
                                    3D design services help turn your ideas into reality. They can create 3D models, prepare them for 3D printing, make realistic images of them and even help develop your initial concepts. 3D design services offer a range of capabilities that can help bring your ideas to life. Here are some of the common services they provide:
                                </p>
                            </Col>
                            <Col md='8' sm='6' className='border-top border-2 border-primary border-dotted'>
                                <div className="container my-5 ">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="card bg-transparent ">
                                                <div className="card-body list">
                                                    <ul className="ff fs-4 text-start text-primary">
                                                        <li className='list-item'>3D Modeling</li>
                                                        <li className='list-item'>3D Printing File Preparation</li>
                                                        <li className='list-item'>3D Rendering</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card bg-transparent">
                                                <div className="card-body list">
                                                    <ol className="ff fs-4 text-start text-primary">
                                                        <li className='list-item'>Concept Development</li>
                                                        <li className='list-item'>Design for Manufacturing(DFM)</li>
                                                        <li className='list-item'>Technical Drawings</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </Col>
                </Row>

            </div>
        </React.Fragment>
    )
}

export default ServiceOffer

import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Icon } from "../../../../../components/Component";

const IndustrialSection = () => {
    const bgColor1 = '#D1D7FC';
    const bgColor2 = '#CEE8FF';
    return (
        <React.Fragment>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ backgroundColor: `${bgColor2}` }}
                    contentArrowStyle={{ borderRight: "7px solid  #CEE8FF" }}
                    iconStyle={{
                        background: "rgb(33, 150, 243)",
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "xx-large",
                    }}
                    icon={<Icon name="plus-medi" />}
                >
                    <h3 className="vertical-timeline-element-title ff text-primary">Healthcare CRM</h3>
                    <p className="ff text-primary">Axiom prioritizes patient data protection by developing a robust Data Loss Prevention (DLP) strategy, ensuring sensitive medical information remains secure and compliant with healthcare regulations.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ backgroundColor: `${bgColor1}` }}
                    contentArrowStyle={{ borderRight: "7px solid  #D1D7FC" }}
                    iconStyle={{
                        background: "rgb(33, 150, 243)",
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "xx-large",
                    }}
                    icon={<Icon name="truck" />}
                >
                    <h3 className="vertical-timeline-element-title ff text-primary">Retail CRM</h3>
                    <p className="ff text-primary">
                    Axiom emphasizes CRM integration with multiple touchpoints to gather valuable insights into customer behaviour, preferences and purchasing patterns, enabling personalized customer engagement and targeted marketing strategies.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ backgroundColor: `${bgColor2}` }}
                    contentArrowStyle={{ borderRight: "7px solid  #CEE8FF" }}
                    iconStyle={{
                        background: "rgb(33, 150, 243)",
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "xx-large",
                    }}
                    icon={<Icon name="building" />}
                >
                    <h3 className="vertical-timeline-element-title ff text-primary">Manufacturing CRM</h3>
                    <p className="ff text-primary">
                    Axiom offers a mobile-compatible CRM system tailored for fieldwork and on-site interactions, enhancing convenience and efficiency in customer engagements and business partnerships within the manufacturing sector.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ backgroundColor: `${bgColor1}` }}
                    contentArrowStyle={{ borderRight: "7px solid #D1D7FC" }}
                    iconStyle={{
                        background: "rgb(33, 150, 243)",
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "xx-large",
                    }}
                    icon={<Icon name="calc" />}
                >
                    <h3 className="vertical-timeline-element-title ff text-primary">Accounting CRM</h3>
                    <p className="ff text-primary">
                    Axiom ensures seamless management of invoicing, billing and document handling within the CRM system, providing robust capabilities to streamline financial operations and improve efficiency in accounting processes.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ backgroundColor: `${bgColor2}` }}
                    contentArrowStyle={{ borderRight: "7px solid  #CEE8FF" }}
                    iconStyle={{
                        background: "rgb(33, 150, 243)",
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "xx-large",
                    }}
                    icon={<Icon name="coin-alt" />}
                >
                    <h3 className="vertical-timeline-element-title ff text-primary">Banking CRM</h3>
                    <p className="ff text-primary">
                    Axiom focuses on implementing a comprehensive data security strategy to safeguard customers' financial data, enhancing trust and confidentiality within banking operations.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ backgroundColor: `${bgColor1}` }}
                    contentArrowStyle={{ borderRight: "7px solid #D1D7FC" }}
                    iconStyle={{
                        background: "rgb(33, 150, 243)",
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "xx-large",
                    }}
                    icon={<Icon name="cart" />}
                >
                    <h3 className="vertical-timeline-element-title ff text-primary">E-commerce CRM</h3>
                    <p className="ff text-primary">
                    Axiom proposes integrating E-commerce CRM with web analytics tools to capture and leverage customer behavioural data for optimizing marketing campaigns, improving customer experience and driving sales growth in the E-commerce industry.
                    </p>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </React.Fragment>
    );
};
export default IndustrialSection;
import React from 'react'
import Head from '../../../../layout/head/Head'
import Content from '../../../../layout/content/Content'
import Contact from '../../components/Contact'
import Aboutsec from './WebSupportComponents/Aboutsec'
import MoreAboutUs from './WebSupportComponents/MoreAboutUs'
import OurServies from './WebSupportComponents/OurServies'
import Testimonial from '../components/Testimonial'
import GenericHero from '../components/GenericHero'
import { Icon } from '../../../../components/Component'
import heroimg from '../../../../images/axiom_img/Web Management.png';

const WebSupport = () => {
    return (
        <React.Fragment>
            <Head title="Axiom | Website Hosting Service" />
            <Content>
                <GenericHero
                    breadcrumbs={
                        <>
                            SERVICES <Icon name={'chevron-right'} /> Web Infrastructure & Management<Icon name={'chevron-right'} /> Website Maintenance  & Support
                        </>
                    }
                    title="Maximize your website's potential with proactive maintenance and support"
                    description="Keep your website running smoothly with our reliable and affordable services"
                    buttonText="Contact Now"
                    buttonLink="/contact"
                    heroImage={heroimg} />
                <Aboutsec />
                <MoreAboutUs />
                <Contact />
                <OurServies />
                <Testimonial />
            </Content>
        </React.Fragment>
    )
}

export default WebSupport

import React from 'react'
import { Block } from '../../../../../components/Component'

const About = () => {
    return (
        <React.Fragment>
            <Block>
                <div className="container py-5">
                    <span className="ff fs-5 " style={{ color: "#2B3E79" }}>
                        Creative & Design Services
                    </span>
                </div>
                <div className="container py-4 ">
                    <div className='d-flex justify-content-center '>
                        <p className='ff fs-1 fw-bolder text-primary text-center'>A Data-Driven Approach to UI/UX Design at {process.env.REACT_APP_PROJECT_NAME}</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-semibold '>At {process.env.REACT_APP_PROJECT_NAME}, we understand that user experience is the cornerstone of a thriving online presence. That's why we offer a comprehensive UI/UX design service that prioritizes data-driven insights and collaborative excellence.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 text-primary'>
                        <p className='ff fs-4 text-center fw-bolder'>What makes our approach to UI/UX design truly transformative?</p>
                    </div>
                    <div className='d-flex flex-column  justify-content-center my-4 text-primary'>
                        <p className='ff fs-6 text-start'><span className='fw-bold '> User-Centric Methodology:</span> We employ a meticulous user research process to deeply understand your target audience and their needs. Every design decision is data-driven, ensuring a seamless and intuitive user journey that fosters exceptional brand engagement.</p>

                        <p className='ff fs-6 text-start'><span className='fw-bold '>  Iterative Optimization:</span> We champion an iterative design process that prioritizes collaboration. Throughout the design cycle, you'll be actively involved in providing feedback as we refine and elevate your UI/UX experience. This ensures the final product not only aligns with your strategic goals but surpasses user expectations.</p>

                        <p className='ff fs-6 text-start'><span className='fw-bold '> Award-Winning Expertise:</span> Our team comprises highly acclaimed UI/UX designers who possess extensive experience in crafting user interfaces that are both aesthetically captivating and functionally optimized. We leverage a strategic blend of creative vision and in-depth technical knowledge to deliver solutions that exceed industry benchmarks.</p>

                        <p className='ff fs-6 text-start'><span className='fw-bold '> Quantifiable Results:</span> Our commitment extends beyond design creation. We utilize advanced analytics to meticulously track user behavior and continually optimize the user journey based on real-world data. This ensures your website or application continuously evolves for maximum engagement and measurable ROI.</p>
                    </div>
                </div>
            </Block>
        </React.Fragment>

    )
}

export default About

import React from 'react'
import { Col, Row } from '../../../../../components/Component'
import slideA from '../../../../../images/axiom_img/EcomPage-images/Custom Solution.png'
import slideB from '../../../../../images/axiom_img/EcomPage-images/UX.png'
import slideC from '../../../../../images/axiom_img/EcomPage-images/Ennovative Mkt..png'
import slideD from '../../../../../images/axiom_img/EcomPage-images/Strategic Growth.png'

const Sample = () => {
    const bgColor1 = '#D1D7FC';
    const bgColor2 = '#CEE8FF';
    return (
        <div className='d-flex justify-content-center my-4 '>
            <Row className={'gs-md'}>
                <Col md={6} className="mb-4 ">
                    <div className=" p-4 h-100 rounded shadow ff position-relative" style={{ backgroundColor: `${bgColor1}` }}>
                        <img src={slideA} alt="Customized_Solutions" className='position-absolute icon_img5 h-50' />
                        <h4 className="mb-3 ff text-primary ">Customized Solutions </h4>
                        <p className='text-primary pt-4'>We provide customized e-commerce solutions to meet your specific needs and requirements. Whether you're a small startup or a large enterprise, we can create a scalable and robust platform that fits your unique business model.</p>
                    </div>
                </Col>
                <Col md={6} className="mb-4">
                    <div className=" p-4 h-100 rounded shadow ff position-relative" style={{ backgroundColor: `${bgColor2}` }}>
                        <img src={slideB} alt="User Experience" className='position-absolute icon_img5 h-50' />
                        <h4 className="mb-3 ff text-primary ">Focus on User Experience</h4>

                        <p className='text-primary pt-4'>As the Axiom E-Commerce web development team, we prioritize crafting exceptional user experiences. Through user-centric design principles, meticulous research and adherence to best practices, we create intuitive and visually appealing online stores that captivate users and drive conversions. Delivering an unparalleled shopping journey is our ultimate goal.</p>
                    </div>
                </Col>
                <Col md={6} className="mb-4">
                    <div className=" p-4 h-100 rounded shadow ff position-relative" style={{ backgroundColor: `${bgColor2}` }}>
                        <img src={slideC} alt="Marketing_Tools" className='position-absolute icon_img5 h-50' />
                        <h4 className="mb-3 ff text-primary ">Innovative Marketing Tools</h4>

                        <p className='text-primary pt-4'>{process.env.REACT_APP_PROJECT_NAME} is not just a shopping platform; it's a marketing toolkit. It includes features like SEO optimization, email marketing, social media integration and analytics tools that help businesses attract and retain customers. These tools enable businesses to leverage data to make informed marketing decisions and optimize their online presence.</p>
                    </div>
                </Col>
                <Col md={6} className="mb-4">
                    <div className=" p-4 h-100 rounded shadow ff position-relative" style={{ backgroundColor: `${bgColor1}` }}>
                        <img src={slideD} alt="Growth" className='position-absolute icon_img5 h-50' />
                        <h4 className="mb-3 ff text-primary ">Strategic Approach to Growth</h4>

                        <p className='text-primary pt-4'>Beyond development, Axiom offers strategic guidance to drive your e-commerce business forward. We provide insights into digital marketing, SEO optimization and conversion rate optimization (CRO) to maximize your online presence and revenue potential.</p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Sample

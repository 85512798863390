import React from 'react'
import Head from '../../../../layout/head/Head'
import Content from '../../../../layout/content/Content'
import AboutSection from './ERPCEMPageComponents/AboutSection'
import ServiceSection from './ERPCEMPageComponents/ServiceSection'
import Contact from '../../components/Contact'
import Testimonial from '../components/Testimonial'
import IndustrialSection from './ERPCEMPageComponents/IndustrialSection'
import GenericHero from '../components/GenericHero'
import { Icon } from '../../../../components/Component'
import heroimg from '../../../../images/axiom_img/ERP CRM.png';

const ErpCrmpage = () => {
  return (
    <React.Fragment>
      <Head title="Axiom | ERP / CRM Services"></Head>
      <Content>
        <GenericHero
          breadcrumbs={
            <>
              SERVICES <Icon name={'chevron-right'} /> Software Development<Icon name={'chevron-right'} /> ERP / CRM Development
            </>
          }
          title="Empower your enterprise with Axiom's cutting-edge ERP and CRM solutions"
          description="Expert Consultations, Strategy Implementations, Upgrade and Support Services"
          buttonText="Contact Now"
          buttonLink="/contact"
          heroImage={heroimg} />
        <AboutSection />
        <ServiceSection />
        <IndustrialSection />
        <Contact />
        <Testimonial />
      </Content>
    </React.Fragment>
  )
}

export default ErpCrmpage

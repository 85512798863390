import React from 'react'
import { Block, Col } from '../../../../../components/Component'
import SlideA from "../../../../../images/axiom_img/Dedicate Server Hosting.png";


const SectionService = () => {
    return (
        <React.Fragment>
            <Block>
                <div className='nk-content' style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                    <div className="container-fluid py-4 ">
                        <div className="container bg-transparent ">
                            <div className='d-flex justify-content-center mx-md-5'>
                                <p className='ff fs-1 fw-bolder text-primary text-center '> Optimize Your Infrastructure: Superior Dedicated Server Hosting Solutions by Axiom Team </p>
                            </div>
                        </div>

                        <div className=" row">
                            <Col md='7'>
                                <div className=''>
                                    <div className='d-flex flex-column justify-content-center my-4 '>
                                        <p className='ff fs-4 text-start text-primary '>At Axiom Team, we understand the critical importance of reliable and high-performance server infrastructure for businesses of all sizes. Our Dedicated Server Hosting solutions are meticulously designed to empower your organization with robust, scalable and secure hosting environments. Whether you're running mission-critical applications, hosting intensive websites, or managing complex databases, our dedicated servers deliver the performance and stability you need to succeed. </p>

                                        <p className='ff fs-4 text-start text-primary '>Our dedicated hosting services offer unparalleled control and customization, allowing you to tailor your server environment to meet specific business requirements. With Axiom Team, you gain access to enterprise-grade hardware, advanced security measures and proactive monitoring to ensure optimal uptime and data protection. We prioritize transparency and flexibility, providing comprehensive management options and responsive support from our team of hosting experts.</p>

                                        <p className='ff fs-4 text-start text-primary '>Partner with Axiom Team for Dedicated Server Hosting that goes beyond standard offerings. Experience peace of mind knowing that your digital infrastructure is in capable hands, allowing you to focus on driving innovation and growth for your business.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md='5' className='align-self-center '>
                                <img top src={SlideA} alt="hosting" className='img-fluid ' />
                            </Col>
                        </div>
                    </div>
                </div>
            </Block>

        </React.Fragment>
    )
}

export default SectionService

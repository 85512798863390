import React from 'react'
import { Card, CardBody, CardImg, CardText, CardTitle, Col, Row } from 'reactstrap'
import { Block } from '../../../../../components/Component'
import SlideA from "../../../../../images/axiom_img/DigitalMarketing/Strategic Vision.png";
import SlideB from "../../../../../images/axiom_img/DigitalMarketing/Insights.png";
import SlideC from "../../../../../images/axiom_img/DigitalMarketing/Customized Solutions.png";
import SlideD from "../../../../../images/axiom_img/DigitalMarketing/Holistic Approach.png";
import SlideE from "../../../../../images/axiom_img/DigitalMarketing/Focused Strategies.png";
import SlideF from "../../../../../images/axiom_img/DigitalMarketing/Continous Optimiztin.png";


const AboutSection = () => {
    const bgColor1 = '#D1D7FC';
    const bgColor2 = '#CEE8FF';
    return (
        <React.Fragment>
            <Block>
                <div className="container py-5">
                    <span className="ff fs-5 " style={{ color: "#2B3E79" }}>
                        Digital Marketing & Management Services
                    </span>
                </div>
                <div className="container py-4 ">
                    <div className='d-flex justify-content-center '>
                        <p className='ff fs-1 fw-bolder text-primary text-center '>Cultivate Online Success with Axiom's Expert SEO & Digital Marketing Strategies</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-semibold '>Axiom is a leading provider of SEO and digital marketing services, dedicated to helping businesses grow online. Our mission is to empower businesses to reach their full potential through strategic SEO and digital marketing strategies.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-bolder text-primary '>What sets our approach to SEO and Digital Marketing apart as transformative?</p>
                    </div>
                    <div className='my-3 my-md-5'>
                        <Row className="g-4">
                            <Col md='4'>
                                <Card className="card-bordered h-100">
                                    <CardBody className="card-inner p-3" style={{ backgroundColor: `${bgColor1}` }}>
                                        <Row className='justify-content-between '>
                                            <Col md='7' className='align-self-center'>
                                                <CardTitle tag="h5" className='ff text-primary'>Strategic Vision: </CardTitle>
                                            </Col>
                                            <Col md='3' >
                                                <CardImg top src={SlideA} alt="Strategic Vision" />
                                            </Col>
                                        </Row>
                                        <CardText className='ff text-primary p-2'>
                                            We don't just focus on short-term gains. Our approach is rooted in developing a long-term strategic vision for your online presence, aligning with your business goals and evolving alongside industry trends.
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md='4'>
                                <Card className="card-bordered h-100">
                                    <CardBody className="card-inner p-3" style={{ backgroundColor: `${bgColor2}` }}>
                                        <Row className='justify-content-between '>
                                            <Col md='8' className='align-self-center'>
                                                <CardTitle tag="h5" className='ff text-primary'>Data-Driven Insights: </CardTitle>
                                            </Col>
                                            <Col md='3' >
                                                <CardImg top src={SlideB} alt="Data-Driven Insights" />
                                            </Col>
                                        </Row>
                                        <CardText className='ff text-primary p-2'>
                                            We leverage advanced analytics and data-driven insights to inform every aspect of our strategies. This ensures that our decisions are based on real-time performance metrics and industry benchmarks, optimizing your digital marketing efforts effectively.
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md='4'>
                                <Card className="card-bordered h-100">
                                    <CardBody className="card-inner p-3" style={{ backgroundColor: `${bgColor1}` }}>
                                        <Row className='justify-content-between '>
                                            <Col md='8' className='align-self-center'>
                                                <CardTitle tag="h5" className=' ff text-primary'>Customized Solutions:</CardTitle>
                                            </Col>
                                            <Col md='3' >
                                                <CardImg top src={SlideC} alt="Customized Solutions" />
                                            </Col>
                                        </Row>
                                        <CardText className='ff text-primary p-2'>
                                            We understand that each business is unique. That's why we tailor our SEO and digital marketing solutions to fit your specific needs, audience and industry landscape. This personalized approach maximizes your ROI and drives sustainable growth.
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md='4'>
                                <Card className="card-bordered h-100">
                                    <CardBody className="card-inner p-3" style={{ backgroundColor: `${bgColor2}` }}>
                                        <Row className='justify-content-between '>
                                            <Col md='8' className='align-self-center'>
                                                <CardTitle tag="h5" className=' ff text-primary'>Holistic Approach:</CardTitle>
                                            </Col>
                                            <Col md='3' >
                                                <CardImg top src={SlideD} alt="Holistic Approach" />
                                            </Col>
                                        </Row>
                                        <CardText className='ff text-primary p-2'>
                                            Beyond just SEO, we take a holistic approach that integrates various digital marketing channels such as content marketing, social media, PPC advertising and more. This comprehensive strategy ensures a cohesive and impactful online presence that reaches your target audience across multiple touchpoints.
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md='4'>
                                <Card className="card-bordered h-100">
                                    <CardBody className="card-inner p-3" style={{ backgroundColor: `${bgColor1}` }}>
                                        <Row className='justify-content-between '>
                                            <Col md='8' className='align-self-center'>
                                                <CardTitle tag="h5" className=' ff text-primary'>Conversion-Focused Strategies:</CardTitle>
                                            </Col>
                                            <Col md='3' >
                                                <CardImg top src={SlideE} alt="Conversion-Focused Strategies" />
                                            </Col>
                                        </Row>
                                        <CardText className='ff text-primary p-2'>
                                            Beyond driving traffic, we focus on optimizing for conversions. Whether it's enhancing user experience, refining messaging, or optimizing landing pages, our strategies are geared towards maximizing conversions and driving tangible business outcomes.
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md='4'>
                                <Card className="card-bordered h-100">
                                    <CardBody className="card-inner p-3" style={{ backgroundColor: `${bgColor2}` }}>
                                        <Row className='justify-content-between '>
                                            <Col md='9' className='align-self-center'>
                                                <CardTitle tag="h5" className=' ff text-primary'>Continuous Optimization: </CardTitle>

                                            </Col>
                                            <Col md='3' >
                                                <CardImg top src={SlideF} alt="Optimization" />
                                            </Col>
                                        </Row>
                                        <CardText className='ff text-primary p-2'>
                                            Our work doesn't stop at implementation. We continuously optimize our SEO and digital marketing campaigns based on performance data, user behavior analysis and feedback, ensuring sustained growth and improvement over time.
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Block >
        </React.Fragment >
    )
}

export default AboutSection

import React from 'react'
import { CardImg, Col, Row } from 'reactstrap'
import SlideA from "../../../../../images/axiom_img/SocialMedia/Profile Setup.png";
import SlideB from "../../../../../images/axiom_img/SocialMedia/Content Creation.png";
import SlideC from "../../../../../images/axiom_img/SocialMedia/Community Management.png";
import SlideD from "../../../../../images/axiom_img/SocialMedia/Reporting.png";

const ServiceSection = () => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <p className="text-primary text-center text-capitalize ff fs-1 fw-bold my-5 px-3 px-md-0">Our Social Media Creation Services Offers</p>
                </Col>
            </Row>
            <div className="container-fluid">
                <Row className='justify-content-center'>
                    <Col md='11' >
                        <div className="container-fluid d-flex flex-column align-items-center " style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                            <Col md='11' className=''>
                                <p className='text-start text-primary ff fs-5 py-3'>
                                    By leveraging our expertise in social media management, creative innovation and a steadfast commitment to achieving results, Axiom offers a comprehensive and effective solution for businesses aiming to enhance their social media presence and maximize their online influence.
                                </p>
                            </Col>
                            <Col md='11' sm='6' className='border-top border-2 border-primary border-dotted'>
                                <div className='d-flex justify-content-center my-4 '>
                                    <Row>
                                        <Col md={6} className="mb-4">
                                            <div className="card bg-transparent">
                                                <div className="card-body row list">
                                                    <Col lg='3' className='d-lg-block d-none' >
                                                        <CardImg top src={SlideA} alt="ProfileSetup" className='img-fluid ' />
                                                    </Col>
                                                    <Col lg='9'>
                                                        <h4 className="mb-3 ff text-primary ">Social Media Profile Setup</h4>
                                                        <ul className="ff fs-5 text-start text-primary">
                                                            <li className='list-item'>We establish professional and branded profiles on major platforms like Facebook, Instagram, Twitter and LinkedIn.</li>
                                                            <li className='list-item'>Our goal is to ensure consistency in branding and messaging across all channels, enhancing brand visibility and credibility.</li>
                                                        </ul>
                                                    </Col>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6} className="mb-4">
                                            <div className="card bg-transparent">
                                                <div className="card-body row list">
                                                    <Col lg='3' className='d-lg-block d-none'>
                                                        <CardImg top src={SlideB} alt="ContentCreation" />
                                                    </Col>
                                                    <Col lg='9'>
                                                        <h4 className="mb-3 ff text-primary ">Content Creation</h4>
                                                        <ul className="ff fs-5 text-start text-primary">
                                                            <li className='list-item'>Axiom creates engaging and tailored content, including posts, images, videos and infographics.</li>
                                                            <li className='list-item'>Content is developed strategically to resonate with your target audience and align with your business objectives, driving engagement and brand awareness.</li>
                                                        </ul>
                                                    </Col>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6} className="mb-4">
                                            <div className="card bg-transparent ">
                                                <div className="card-body row list">
                                                    <Col lg='3' className='d-lg-block d-none'>
                                                        <CardImg top src={SlideC} alt="CommunityManagement" />
                                                    </Col>
                                                    <Col lg='9'>
                                                        <h4 className="mb-3 ff text-primary ">Community Management</h4>
                                                        <ul className="ff fs-5 text-start text-primary">
                                                            <li className='list-item'>We actively engage with your audience by responding to comments, messages and shares.</li>
                                                            <li className='list-item'>This interaction fosters relationships, builds brand loyalty and encourages positive sentiment around your brand.</li>
                                                        </ul>
                                                    </Col>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6} className="mb-4">
                                            <div className="card bg-transparent ">
                                                <div className="card-body row list">
                                                    <Col lg='3' className='d-lg-block d-none'>
                                                        <CardImg top src={SlideD} alt="Reporting" />
                                                    </Col>
                                                    <Col lg='9'>
                                                        <h4 className="mb-3 ff text-primary ">Analytics and Reporting</h4>
                                                        <ul className="ff fs-5 text-start text-primary">
                                                            <li className='list-item'>Tracking and analyzing key performance metrics such as engagement rates, reach, clicks and conversions.</li>
                                                            <li className='list-item'>Providing comprehensive monthly reports with in-depth insights and actionable recommendations.</li>
                                                        </ul>
                                                    </Col>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default ServiceSection

import React, { useState, useCallback, useMemo } from 'react';
import { Button, Col, Row } from 'reactstrap';
import DatePicker from "react-datepicker";
import { PreviewCard } from '../../../components/Component';
import Required from '../Required';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';

const initialData = {
    date: null,
    time: null,
    full_name: '',
    email: '',
    phone: '',
    message: '',
};

const ConsultationForm = ({
    apiUrl,
    backgroundImage,
    title,
    onSuccess,
    onError,
    maxDays = 30,
    minTime = new Date(0, 0, 0, 9, 0),
    maxTime = new Date(0, 0, 0, 17, 0)
}) => {
    const [formData, setFormData] = useState(initialData);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitMessage, setSubmitMessage] = useState({ type: '', text: '' });

    const today = useMemo(() => new Date(), []);
    const maxDate = useMemo(() => {
        const date = new Date();
        date.setDate(today.getDate() + maxDays);
        return date;
    }, [today, maxDays]);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    }, []);

    const handleDateChange = useCallback((date) => {
        setFormData(prev => ({ ...prev, date }));
    }, []);

    const handleTimeChange = useCallback((time) => {
        setFormData(prev => ({ ...prev, time }));
    }, []);

    const handlePhoneChange = useCallback((value) => {
        setFormData(prev => ({ ...prev, phone: value }));
    }, []);

    const validateForm = useCallback(() => {
        const newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!formData.full_name.trim()) newErrors.full_name = 'Full Name is required';
        if (!formData.email.trim() || !emailRegex.test(formData.email)) newErrors.email = 'Valid email is required';
        if (formData.message.trim().length > 500) newErrors.message = 'Message must be 500 characters or less';
        if (!formData.date) newErrors.date = 'Date is required';
        else if (formData.date < today || formData.date > maxDate) newErrors.date = 'Please select a date within the next 30 days';
        if (!formData.time) newErrors.time = 'Time is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }, [formData, today, maxDate]);

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        setSubmitMessage({ type: '', text: '' });

        if (validateForm()) {
            setIsSubmitting(true);
            try {
                const formattedDate = formData.date ? formData.date.toISOString().split('T')[0] : null;
                const formattedTime = formData.time ? formData.time.toTimeString().slice(0, 5) : null;

                const dataToSend = {
                    ...formData,
                    date: formattedDate,
                    time: formattedTime,
                };

                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(dataToSend),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Server responded with an error');
                }

                const result = await response.json();
                setSubmitMessage({ type: 'success', text: 'Consultation scheduled successfully!' });
                setFormData(initialData);
                onSuccess && onSuccess(result);
                toast.success("Thank you for contacting us, We will reach back to you very soon!", {
                    autoClose: 3000,
                });
            } catch (error) {
                toast.error("Error submitting form:", error);
                // console.error('Error submitting form:', error);
                setSubmitMessage({ type: 'error', text: error.message || 'An error occurred. Please try again.' });
                onError && onError(error);
            } finally {
                setIsSubmitting(false);
            }
        } else {
            setSubmitMessage({ type: 'error', text: 'Please correct the errors in the form.' });
        }
    }, [formData, validateForm, apiUrl, onSuccess, onError]);

    const renderFormField = useCallback((label, name, type, value, onChange, error) => (
        <div className="form-group">
            <label className="form-label" htmlFor={name}>{label}:<Required /></label>
            {type === 'textarea' ? (
                <textarea
                    id={name}
                    name={name}
                    placeholder={label}
                    value={value}
                    onChange={onChange}
                    className={`form-control ${error ? 'is-invalid' : ''}`}
                />
            ) : (
                <input
                    type={type}
                    id={name}
                    name={name}
                    placeholder={label}
                    value={value}
                    onChange={onChange}
                    className={`form-control ${error ? 'is-invalid' : ''}`}
                />
            )}
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    ), []);

    return (
        <div className="container-fluid p-3 m-auto" id='contactus' style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
        }}>
            <div className="container py-md-4 py-2">
                <div className='pt-3'>
                    <h4 className='ff text-primary'>{title}</h4>
                </div>
                <PreviewCard className='bg-transparent border'>
                    <form onSubmit={handleSubmit} className="gy-3">
                        <Row>
                            <Col lg="6">
                                <div className="form-group">
                                    <label className="form-label">Select Date:<Required /></label>
                                    <DatePicker
                                        selected={formData.date}
                                        onChange={handleDateChange}
                                        className={`form-control ${errors.date ? 'is-invalid' : ''}`}
                                        placeholderText="Select a date YYYY/MM/DD"
                                        dateFormat="yyyy/MM/dd"
                                        minDate={today}
                                        maxDate={maxDate}
                                        filterDate={date => date.getDay() !== 0 && date.getDay() !== 6}
                                    />
                                    {errors.date && <div className="invalid-feedback">{errors.date}</div>}
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="form-group">
                                    <label className="form-label">Select Time:<Required /></label>
                                    <DatePicker
                                        selected={formData.time}
                                        onChange={handleTimeChange}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={60}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        placeholderText="Select a time"
                                        className={`form-control ${errors.time ? 'is-invalid' : ''}`}
                                        minTime={minTime}
                                        maxTime={maxTime}
                                    />
                                    {errors.time && <div className="invalid-feedback">{errors.time}</div>}
                                </div>
                            </Col>
                        </Row>
                        <div className=' p-2 rounded-2'>
                            <Row className='py-md-3 py-0'>
                                <Col lg="6" className='py-lg-0 py-3'>
                                    {renderFormField('Full Name', 'full_name', 'text', formData.full_name, handleInputChange, errors.full_name)}
                                </Col>
                                <Col lg="6" className='py-lg-0 py-3'>
                                    {renderFormField('Email', 'email', 'email', formData.email, handleInputChange, errors.email)}
                                </Col>
                            </Row>
                            <Row className='py-md-3 py-0'>
                                <Col lg="6" className='py-lg-0 py-3'>
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="phone">Phone:<Required /></label>
                                        <PhoneInput
                                            placeholder="Enter Phone Number"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handlePhoneChange}
                                            className={`rounded`}
                                            error={formData.phone ? (isValidPhoneNumber(formData.phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                            rules={{ required: true, validate: isPossiblePhoneNumber }}
                                        />
                                        {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                                    </div>
                                </Col>
                                <Col lg="6" className='py-lg-0 py-3'>
                                    {renderFormField('Message', 'message', 'textarea', formData.message, handleInputChange, errors.message)}
                                </Col>
                            </Row>
                        </div>
                        <Row className="g-3">
                            <Col lg="7" className="offset-lg-5">
                                <div className="form-group mt-2">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        size="lg"
                                        disabled={isSubmitting}>
                                        {isSubmitting ? 'Scheduling...' : 'Schedule Now'}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        {submitMessage.text && (
                            <div className={`alert alert-${submitMessage.type}`}>
                                {submitMessage.text}
                            </div>
                        )}
                    </form>
                </PreviewCard>
            </div>
        </div>
    );
};

export default ConsultationForm;
import React from 'react';
import { Block } from '../../../../../components/Component'


const AboutWeb = () => {
    return (
        <React.Fragment>
            <Block>
                <div className="container py-5">
                    <span className="ff fs-5 " style={{ color: "#2B3E79" }}>
                        Software Development Services
                    </span>
                </div>
                <div className="container py-4 ">
                    <div className='d-flex justify-content-center '>
                        <p className='ff fs-1 fw-bolder text-primary text-center '>Elevate Your Brand with Visually Stunning and Intuitive Websites with the Help of {process.env.REACT_APP_PROJECT_NAME} </p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-semibold '>Enhance your brand's digital presence with visually captivating and user-centric websites developed by {process.env.REACT_APP_PROJECT_NAME}. Our tailored services are designed to elevate your brand's identity and engage your audience through exceptional web design and intuitive user experiences.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-bolder text-primary'>Why choose {process.env.REACT_APP_PROJECT_NAME} for your next digital venture?</p>
                    </div>
                    <div className='d-flex flex-column  justify-content-center my-4 '>
                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '>Tailored Solutions: </span> Our web development team specializes in crafting customized websites that precisely meet your unique business requirements. Whether you envision a simple static site or a sophisticated dynamic application, we meticulously design and develop solutions that align seamlessly with your goals and objectives, ensuring your online presence is a true reflection of your brand.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '>Technical Proficiency: </span> Backed by a team of skilled developers, we excel in a diverse range of technologies, enabling us to leverage the best-fit tools and platforms for the success of your project. Our technical expertise ensures your website is built on a foundation of stability, scalability and innovation, empowering your business to thrive in the digital era.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '> User-Centric Design: </span> At the core of our approach is a deep understanding of user experience. We prioritize intuitive and engaging interfaces, creating websites that not only captivate your audience visually but also deliver an exceptional user journey, fostering stronger connections and driving meaningful conversions.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '> Agile Methodology:</span>  Utilizing agile development practices, we deliver projects efficiently, adapting to evolving requirements and ensuring timely results. This dynamic approach allows us to respond swiftly to changes, keeping your website fresh, relevant and aligned with your business needs.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '>Quality Assurance: </span>  Quality is paramount at Axiom Web Solutions. We conduct rigorous testing to guarantee bug-free, responsive and high-performance websites, ensuring your digital presence is a reliable and engaging experience for your customers.</p>
                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '>Ongoing Support: </span> Our commitment to your success extends beyond project completion. We offer continuous support and maintenance services, keeping your website secure, optimized and adaptable to the ever-evolving digital landscape. With Axiom Web Solutions, you can rest assured that your online presence is in the hands of experts dedicated to its long-term success.</p>
                    </div>
                </div>
            </Block>
        </React.Fragment >
    )
}

export default AboutWeb

import React from 'react'
import Head from '../../../../layout/head/Head'
import Contact from '../../components/Contact'
import Testimonial from '../components/Testimonial'
import Content from '../../../../layout/content/Content'
import WhyChooseus from './ContentMarketingComponents/WhyChooseus'
import AboutSection from './ContentMarketingComponents/AboutSection'
import ServiceSection from './ContentMarketingComponents/ServiceSection'
import GenericHero from '../components/GenericHero'
import { Icon } from '../../../../components/Component'
import heroimg from '../../../../images/axiom_img/ContentMarketing/Main Image.png';


const ContentMarketing = () => {
    return (
        <React.Fragment>
            <Head title="Axiom | Content Marketing"></Head>
            <Content>
                <GenericHero
                    breadcrumbs={
                        <>
                            SERVICES <Icon name={'chevron-right'} /> Digital Marketing & Management Services<Icon name={'chevron-right'} />Content Marketing
                        </>
                    }
                    title="Transform your business with Axiom's Content marketing services"
                    description="Engage your audience, build brand awareness and drive conversions with our expert content marketing strategies."
                    buttonText="Contact Now"
                    buttonLink="/contact"
                    heroImage={heroimg} />
                <AboutSection />
                <WhyChooseus />
                <ServiceSection />
                <Contact />
                <Testimonial />
            </Content>
        </React.Fragment>
    )
}

export default ContentMarketing

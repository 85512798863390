import React from 'react'
import { Icon } from '../../../../../components/Component';
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Row } from 'reactstrap';

const ServiceSection = () => {
    const bgColor1 = '#D1D7FC';
    const bgColor2 = '#CEE8FF';

    return (
        <React.Fragment>
            <div className="container-fluid" style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                <Row>
                    <Col>
                        <p className="fw-bolder text-primary text-center ff my-5 fs-1 ">Our Mobile Application Development Services</p>
                    </Col>
                </Row>
                <div className='container' style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                    <Row>
                        <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100" style={{ backgroundColor: `${bgColor1}` }}>
                                <CardBody className="card-inner">
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                        <Icon name={'edit-alt-fill'} />
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary '>Mobile App Design</CardTitle>
                                    <CardText className='ff text-primary'>
                                        Our expert designers specialize in crafting captivating user interfaces (UI) and seamless user experiences (UX) tailored for mobile platforms.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100" style={{ backgroundColor: `${bgColor2}` }}>
                                <CardBody className="card-inner">
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                        <Icon name={'file-code'} />
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Mobile App Development</CardTitle>
                                    <CardText className='ff text-primary'>
                                        With meticulous attention to detail and a commitment to industry best practices, we deliver fast, reliable and high-performing mobile apps tailored to your unique requirements.
                                    </CardText>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100" style={{ backgroundColor: `${bgColor1}` }}>
                                <CardBody className="card-inner">
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                        <Icon name={'puzzle-fill'} />
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Mobile App Integration</CardTitle>
                                    <CardText className='ff text-primary'>
                                        Leveraging our extensive experience and technical proficiency, we guarantee smooth and efficient integration that enhances the functionality and versatility of your applications.
                                    </CardText>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100" style={{ backgroundColor: `${bgColor2}` }}>
                                <CardBody className="card-inner">
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                        <Icon name={'search'} />
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Testing & QA</CardTitle>
                                    <CardText className='ff text-primary'>
                                        With industry-recognized expertise and meticulous attention to detail, we ensure the quality and reliability of your software applications through comprehensive testing practices.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100" style={{ backgroundColor: `${bgColor1}` }}>
                                <CardBody className="card-inner">
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                        <Icon name={'growth-fill'} />
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Mobile App Modernization</CardTitle>
                                    <CardText className='ff text-primary'>
                                        With a focus on enhancing performance, scalability and usability, we transform outdated apps into modern, efficient and visually appealing solutions aligned with current industry standards.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100" style={{ backgroundColor: `${bgColor2}` }}>
                                <CardBody className="card-inner">
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                        <Icon name={'setting-fill'} />
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Maintenance and Support</CardTitle>
                                    <CardText className='ff text-primary'>
                                        Our dedicated team ensures the stability, security and continuous improvement of your applications throughout their lifecycle.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ServiceSection

import React from 'react'
import { Icon, PreviewCard } from '../../../../../components/Component';
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Row } from 'reactstrap';

const OurServies = () => {
    return (
        <React.Fragment>
            <div className="container">
                <Row>
                    <Col>
                        <p className="fw-bolder text-primary text-center ff my-5 fs-1 ">Maintenance & Support Services We Offer</p>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center my-4 '>
                    <p className='ff fs-4 text-center fw-semibold '>Each of these services plays a critical role in maintaining the overall health, security and performance of our clients' websites. By entrusting Axiom with its website maintenance and support needs, businesses can focus on core activities while enjoying a robust and reliable online presence that drives growth and success.</p>
                </div>
                <PreviewCard>
                    <Row>
                        <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100" style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                                <CardBody className="card-inner">
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                        <Icon name={'upload-cloud'} />
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary '>Regular Website Backups</CardTitle>
                                    <CardText className='ff text-primary list'>
                                        <ul>
                                            <li>
                                                <p>We perform scheduled backups of your website data and files to secure external servers.
                                                </p>
                                            </li>
                                            <span className='fw-bold '>Benefit: </span>Protects against data loss due to unforeseen circumstances or cyberattacks, ensuring quick recovery and minimal downtime.
                                        </ul>
                                    </CardText>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100" style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                                <CardBody className="card-inner">
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                        <Icon name={'code-download'} />
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Software Updates and Maintenance</CardTitle>
                                    <CardText className='ff text-primary list'>
                                        <ul>
                                            <li>
                                                <p>We keep your website's software, plugins and frameworks up-to-date with the latest versions.
                                                </p>
                                            </li>
                                            <span className='fw-bold '>Benefit: </span>Enhances website security, stability and compatibility with evolving web standards.
                                        </ul>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100" style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                                <CardBody className="card-inner">
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                        <Icon name={'shield-check'} />
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Security Monitoring and Malware Removal</CardTitle>
                                    <CardText className='ff text-primary list'>
                                        <ul>
                                            <li>
                                                <p>We continuously monitor your website for security threats and promptly remove malware or suspicious activities.
                                                </p>
                                            </li>
                                            <span className='fw-bold '>Benefit: </span>Minimizes the risk of hacking, data breaches and downtime, fostering trust among users and search engines.
                                        </ul>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100" style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                                <CardBody className="card-inner">
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                        <Icon name={'meter'} />
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Performance Optimization</CardTitle>
                                    <CardText className='ff text-primary list'>
                                        <ul>
                                            <li>
                                                <p>We optimize website speed, responsiveness and overall performance through various techniques, such as caching and code optimizations.
                                                </p>
                                            </li>
                                            <span className='fw-bold '>Benefit: </span>Improves user experience, reduces bounce rates and boosts search engine rankings.
                                        </ul>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100" style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                                <CardBody className="card-inner">
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                        <Icon name={'growth-fill'} />
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>User Experience Testing and Optimization</CardTitle>
                                    <CardText className='ff text-primary list'>
                                        <ul>
                                            <li>
                                                <p>We conduct usability testing and analyze user behavior to identify and implement improvements in navigation, layout and functionality.
                                                </p>
                                            </li>
                                            <span className='fw-bold '>Benefit: </span> Enhances user satisfaction, encourages conversions and strengthens brand credibility.
                                        </ul>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" className='mb-4'>
                            <Card className="card-bordered h-100" style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                                <CardBody className="card-inner">
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                        <Icon name={'setting'} />
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Technical Support and Troubleshooting</CardTitle>
                                    <CardText className='ff text-primary list'>
                                        <ul>
                                            <li>
                                                <p>We provide ongoing technical support and troubleshooting for website issues and user inquiries.
                                                </p>
                                            </li>
                                            <span className='fw-bold '>Benefit: </span>Offers prompt resolution of issues, reducing frustration and maintaining a positive user experience.
                                        </ul>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </PreviewCard>
            </div>
        </React.Fragment>
    )
}

export default OurServies

import React from "react";


const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="nk-footer bg-white">
      <div className="container-fluid">
        <div className="nk-footer-wrap justify-content-center ">
          <div className="nk-footer-copyright ">
           <a href="#" className="ff">All Rights Reserved</a>  &copy; {currentYear}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;

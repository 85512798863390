import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { LinkItem } from "../../../../../components/Component";

const AskedQue = ({ className, variation, ...props }) => {
    const [isOpen, setIsOpen] = useState("1");

    const toggleCollapse = (param) => {
        if (param === isOpen) {
            setIsOpen("0");
        } else {
            setIsOpen(param);
        }
    };
    return (
        <div className='container'>
            <div className='py-4 text-center'>
                <h3 className='ff text-primary'>Frequently Asked Questions</h3>
            </div>
            <div className={[`accordion${variation ? " accordion-s" + variation : ""}${className ? " " + className : ""}`]}>
                <div className="accordion-item">
                    <div className={[`accordion-head${isOpen !== "1" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("1")}>
                        <h6 className="title ff">What is your typical project timeline from start to finish?</h6>
                        <span className="accordion-icon"></span>
                    </div>
                    <Collapse className="accordion-body" isOpen={isOpen === "1" ? true : false}>
                        <div className="accordion-inner">
                            <p className="ff">
                                The project timeline for any project can vary depending on the complexity of the product and the requirements of the project. However, we typically follow a structured approach that includes ideation, design, prototyping, testing and production. The duration for each phase can vary, but we always look forward to working closely with our clients in order to ensure, we meet deadlines by delivering high-quality products.
                            </p>
                        </div>
                    </Collapse>
                </div>
                <div className="accordion-item">
                    <div className={[`accordion-head${isOpen !== "2" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("2")}>
                        <h6 className="title ff">How do you handle intellectual property and confidentiality for your projects?</h6>
                        <span className="accordion-icon"></span>
                    </div>
                    <Collapse className="accordion-body" isOpen={isOpen === "2" ? true : false}>
                        <div className="accordion-inner">
                            <p className="ff">
                                We work with our clients to ensure all proprietary information is kept secure and protected throughout the product development process. We typically use non-disclosure agreements (NDAs) to safeguard your intellectual property and confidential information. Additionally, we have strict security protocols in place to ensure our team members only have access to the information they need to complete their work on the project.
                            </p>
                        </div>
                    </Collapse>
                </div>
                <div className="accordion-item">
                    <div className={[`accordion-head${isOpen !== "3" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("3")}>
                        <h6 className="title ff">Can you provide references or examples of past projects you've completed?</h6>
                        <span className="accordion-icon"></span>
                    </div>
                    <Collapse className="accordion-body" isOpen={isOpen === "3" ? true : false}>
                        <div className="accordion-inner">
                            <p className="ff">
                                We make our work speaks for itself and we are proud of the successful projects we've done for our clients. You can access ours<LinkItem to={'/portfolio'}>portfolio page</LinkItem>to refer to case studies of similar projects we have completed in your industry. Apart from this, you can connect with us to discuss your specific needs and learn more about our past projects.
                            </p>
                        </div>
                    </Collapse>
                </div>
                <div className="accordion-item">
                    <div className={[`accordion-head${isOpen !== "4" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("4")}>
                        <h6 className="title">How do your website development services benefit my business?</h6>
                        <span className="accordion-icon"></span>
                    </div>
                    <Collapse className="accordion-body" isOpen={isOpen === "4" ? true : false}>
                        <div className="accordion-inner">
                            <p>
                                Our web development services can help your company in a variety of ways. A well-designed and user-engaging website may boost your online visibility and credibility. It can also help to automate and simplify your existing business processes. Ultimately, the high-quality website that we create for you will help you convert more visitors into customers and grow your business.
                            </p>
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>

    )
}

export default AskedQue


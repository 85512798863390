import React from 'react'
import Sample from './Sample'
import { Block } from '../../../../../components/Component'

const AboutEcom = () => {

    return (
        <React.Fragment>
            <Block>
                <div className="container py-5">
                    <span className="ff fs-5 " style={{ color: "#2B3E79" }}>
                        Software Development Services
                    </span>
                </div>
                <div className="container py-4 ">
                    <div className='d-flex justify-content-center '>
                        <p className='ff fs-1 fw-bolder text-primary text-center '>
                            Axiom excels in E-commerce website development, crafting visually captivating and intuitive online stores to fuel business growth.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-semibold '>Axiom offers expert E-commerce services, providing tailored solutions for businesses to thrive online. From streamlined platforms to scalable solutions, we empower our clients with cutting-edge technology and strategic expertise for sustainable growth.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-bolder text-primary'>Why choose Axiom for E-commerce website development?</p>
                    </div>
                    <div className='d-flex flex-column  justify-content-center my-4 '>
                        <Sample />
                    </div>
                </div>
            </Block>
        </React.Fragment >
    )
}

export default AboutEcom

import React from 'react'
import SimpleSlider from '../../components/Slider'
import home from "../../../../../images/axiom_img/dallasbuyshouses.png";
import IGTBOK from "../../../../../images/axiom_img/IGTBOK.png";
import IParty from "../../../../../images/axiom_img/ipartydjcrm.png";

const OurWork = () => {
    const sliderItems = [
        {
            title: "A Website for Property Listings",
            description: "This is a property listing website that users can use to browse through a vast selection of properties. The website offer Home Selling, Home Buying, Rental Properties, Renovations, New Construction.",
            image: home,
            altText: "Dallas Buys Houses"
        },
        {
            title: "A Platform to combat human trafficking",
            description: "Empower communities to fight human trafficking by supporting survivors, enhancing legal measures and raising awareness, creating a safer world for all.",
            image: IGTBOK,
            altText: "IGTBOK"
        },
        {
            title: "Your Ultimate DJ Booking Platform",
            description: "The platform should allow DJs to build profiles with a library of content accessible to consumers for review and booking.Content will be classified by Category and Services.",
            image: IParty,
            altText: "IPDJCRM"
        },
        // Add more items as needed
    ];
    return (
        <div className="container-fluid text-center py-4" style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
            <div className='py-4'>
                <h3 className='ff text-primary'>A Glimpse of Our Work</h3>
            </div>
            <div className="container">
                <SimpleSlider sliderItems={sliderItems} />
            </div>
        </div>
    )
}

export default OurWork

import React from 'react'
import { Card, CardBody, CardImg, CardText, CardTitle, Col, Row } from 'reactstrap';
import SlideA from "../../../../../images/axiom_img/GraphicPage-images/02.png";
import SlideB from "../../../../../images/axiom_img/GraphicPage-images/Strategic Marketing.png";
import SlideC from "../../../../../images/axiom_img/GraphicPage-images/Product Illustration.png";
import SlideD from "../../../../../images/axiom_img/GraphicPage-images/05.png";
import SlideE from "../../../../../images/axiom_img/GraphicPage-images/Data Driven Infographics.png";
import SlideF from "../../../../../images/axiom_img/GraphicPage-images/Illustration Dev..png";



const ServiceOffer = () => {
    const bgColor1 = '#D1D7FC';
    const bgColor2 = '#CEE8FF';
    return (
        <div className='container'>
            <Row>
                <Col>
                    <p className="fw-bolder text-primary text-center ff my-5 fs-1 ">Our Graphics Design Services Offer</p>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideA} alt="Logo Design" className='h-350px object-fit-contain img-fluid' />
                        <CardBody className="card-inner " style={{ backgroundColor: `${bgColor2}` }}>
                            <CardTitle className='ff fs-3 fw-bold text-primary '>Brand Identity & Logo Design</CardTitle>
                            <CardText className='text-primary ff'>
                                Our team crafts distinctive and memorable logos that encapsulate your brand essence. We meticulously develop a comprehensive brand identity style guide, outlining color palettes, typography and visual elements. This ensures consistent and impactful brand presentation across all marketing materials.
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideB} alt="Strategic Marketing" className='h-350px object-fit-contain img-fluid' />
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                            <CardTitle className='ff fs-3 fw-bold text-primary'>Strategic Marketing Collateral Design</CardTitle>
                            <CardText className='text-primary ff'>
                                We design captivating marketing materials such as brochures, flyers, presentations and social media graphics that effectively communicate your brand message and strategically engage your target audience.
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideC} alt="product design" className='h-350px object-fit-contain img-fluid'/>
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                            <CardTitle className='ff fs-3 fw-bold text-primary'>Compelling Packaging Design & Product Illustration</CardTitle>
                            <CardText className='text-primary ff'>
                                We create visually stunning and informative packaging solutions that grab attention on shelves and accurately represent the value proposition of your product. Our skilled illustrators craft captivating product illustrations that elevate your brand storytelling and enhance customer engagement.
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideD} alt="User Interface" className='h-350px object-fit-contain img-fluid'/>
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                            <CardTitle className='ff fs-3 fw-bold text-primary'>Cohesive Website & User Interface (UI) Design</CardTitle>
                            <CardText className='text-primary ff'>
                                Our graphic design expertise extends to crafting visually compelling and user-friendly user interfaces (UIs) for your website or application. This ensures a seamless user experience that fosters intuitive navigation and strengthens your overall brand identity.
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideE} alt="Visualization Design" className='h-350px object-fit-contain img-fluid'/>
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                            <CardTitle className='ff fs-3 fw-bold text-primary'>Data-Driven Infographic & Visualization Design</CardTitle>
                            <CardText className='text-primary ff'>
                                We transform complex data into visually engaging infographics that simplify information, enhance audience understanding and support your marketing objectives.
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideF} alt="Icon Development" className='h-350px object-fit-contain img-fluid'/>
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                            <CardTitle className='ff fs-3 fw-bold text-primary'>Bespoke Icon & Illustration Development</CardTitle>
                            <CardText className='text-primary ff'>
                                Our designers create custom icons and illustrations that visually represent your brand, services, or concepts. These elements add a touch of personality and clarity to your communication materials, reinforcing brand recognition and improving user comprehension.
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
        </div>
    )
}

export default ServiceOffer

import React, { useEffect, useState } from 'react';
import { Block } from '../../../components/Component';
import VerticalTabs from '../../axiomlayout/pages/components/VerticalTabs';
import tabimg2 from '../../../images/siloimages/A11.png';
import tabimg3 from '../../../images/siloimages/c11.png';

const Services = () => {
    const [fontSize, setFontSize] = useState('12px');

    const updateFontSize = () => {
        const width = window.innerWidth;
        if (width >= 1200) {
            setFontSize('14px');
        } else if (width >= 992) {
            setFontSize('12px');
        } else if (width >= 768) {
            setFontSize('12px');
        } else if (width >= 576) {
            setFontSize('12px');
        } else {
            setFontSize('12px');
        }
    };

    useEffect(() => {
        updateFontSize();
        window.addEventListener('resize', updateFontSize);
        return () => window.removeEventListener('resize', updateFontSize);
    }, [])
    return (
        <React.Fragment>
            <div className="nk-content my-5 pb-md-4 pb-2 position-relative ">
                <Block>
                    <div className='container-fluid ' id='service'>
                        <div className='bg-white z-1 position-relative rounded-5' >
                            <div className="content mx-auto py-4">
                                <div className='d-flex justify-content-center mx-md-5 pb-md-4 pb-0 '>
                                    <p className="ff-alt fw-semibold text-uppercase" style={{ color: "#17253D", letterSpacing: '3px', fontSize }}>
                                        Services WE Provide
                                    </p>
                                </div>
                                <div className='d-flex justify-content-center mx-md-5 py-4'>
                                    <h3 className="ff-alt fw-semibold text-capitalize text-center" style={{ color: "#17253D" }}>
                                        Solutions for Every Stage of Your Project
                                    </h3>
                                </div>
                            </div>
                            <div className="content p-md-3 p-0">
                                <VerticalTabs />
                            </div>
                        </div>
                        <div className='A1 d-md-inline d-none'>
                            <img src={tabimg2} alt="" width='75%' />
                        </div>
                        <div className='A2 d-md-inline d-none'>
                            <img src={tabimg3} alt="" className='w-50' />
                        </div>
                    </div>
                </Block>
            </div>
        </React.Fragment>
    )
}

export default Services

import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import silo from '../../../images/siloimages/silocloud.png';
import dolr from '../../../images/siloimages/Dolr Coin Black.png';
import nuairs from '../../../images/siloimages/Nuair_BLACK_.png';
import representme from '../../../images/siloimages/representme.png';
import realflow from '../../../images/siloimages/realflow.png';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';

const ProjectItem = ({ name, url, imageSrc, altText, className, style }) => (
  <Col xs={6} sm={4} md={4} lg={2} className="mb-4">
    <a href={url} className="text-decoration-none">
      <div className="px-1 ff-alt fw-semibold text-center text-dark mb-2">{name}</div>
      <div className={`text-center ${className || ''}`}>
        <img src={imageSrc} alt={altText} className="img-fluid" style={{ maxWidth: '80%', ...style }} />
      </div>
    </a>
  </Col>
);

const Projects = () => {
  const projectData = [
    { name: 'SiloCloud', url: 'https://silocloud.com/', imageSrc: silo, altText: 'SiloCloud', style: { maxWidth: '60%' } },
    { name: 'NuAirs', url: 'https://nuairs.com/', imageSrc: nuairs, altText: 'NuAirs', style:{ maxWidth: '70%' } },
    { name: 'DOLRCoin', url: 'https://dolrcoin.com/', imageSrc: dolr, altText: 'DOLRCoin', className: 'pt-md-5 pt-2', style: { maxWidth: '100%' } },
    { name: 'Therealflow', url: 'https://therealflow.com/', imageSrc: realflow, altText: 'Therealflow', className: 'pt-2', style: { maxWidth: '70%' } },
    { name: 'RepresentingMe', url: 'https://representingme.com/', imageSrc: representme, altText: 'RepresentingMe', className: 'pt-md-5 pt-2', style: { maxWidth: '100%' }  }
  ];

  return (
    <React.Fragment>
      <Container fluid className="py-5">
        <Row className="justify-content-center mb-4">
          <Col xs={12}>
            <h3 className="ff-alt fw-semibold text-dark text-capitalize text-center" style={{ color: "#17253D" }}>
              Our Projects
            </h3>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          {projectData.map((project, index) => (
            <ProjectItem key={index} {...project} />
          ))}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Projects;
import React from "react";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";

import SlideA from "../../../../../images/axiom_img/EcomPage-images/Custom Website.png";
import SlideB from "../../../../../images/axiom_img/EcomPage-images/Payment Gateway Integration.png";
import SlideC from "../../../../../images/axiom_img/EcomPage-images/E- Commerce.png";
import SlideD from "../../../../../images/axiom_img/EcomPage-images/Analytics.png";
import SlideE from "../../../../../images/axiom_img/EcomPage-images/Mobile Optimiztion.png";
import SlideF from "../../../../../images/axiom_img/EcomPage-images/Expert & Supporting.png";
import { Col, Row } from "../../../../../components/Component";




const EcommServices = () => {
    return (
        <>
            <div className="container">
                <Row>
                    <Col>
                        <p className="fw-bolder text-primary text-center text-capitalize ff my-5 fs-2 ">How Does Axiom Enhance Your E-Commerce Website?</p>
                    </Col>
                </Row>
                <div className="row g-gs text-center bg-transparent ">
                    <div className="col-sm-4 col-lg-4 col-xxl-3">
                        <Card className="text-primary h-100">
                            <CardBody className="card-inner text-primary">
                                <img src={SlideA} alt="Custom Website" className="h-350px object-fit-contain img-fluid" />
                                <CardTitle tag="h5" className="ff">Custom website design</CardTitle>
                                <CardText className="mb-4 ff">
                                    The design is not just about aesthetics; it's about creating a seamless user experience (UX) that guides
                                    visitors effortlessly through the site
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-sm-4 col-lg-4 col-xxl-3">
                        <Card className="text-Primary h-100">
                            <CardBody className="card-inner text-primary">
                                <img src={SlideB} alt="Payment Gateway" className="h-350px object-fit-contain img-fluid"/>
                                <CardTitle tag="h5" className="ff">Payment gateway integration</CardTitle>
                                <CardText className="mb-4 ff">
                                    Integrating a payment gateway into your E-commerce website is a crucial step in enabling secure and
                                    efficient online transactions.
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-sm-4 col-lg-4 col-xxl-3">
                        <Card className="text-Primary h-100 ">
                            <CardBody className="card-inner text-primary">
                                <img src={SlideC} alt="platform integration" className="h-350px object-fit-contain img-fluid" />
                                <CardTitle tag="h5" className="ff">E-commerce platform integration</CardTitle>
                                <CardText className="ff">
                                    An integration platform acts as a bridge between different software systems, facilitating communication
                                    and automation.
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-sm-4 col-lg-4 col-xxl-3 ">
                        <Card className="text-Primary h-100 ">
                            <CardBody className="card-inner text-primary ">
                                <img src={SlideD} alt="Analytics and Reporting" className="h-350px object-fit-cover img-fluid" />
                                <CardTitle tag="h5" className="text-Primary ff">Analytics and Reporting</CardTitle>
                                <CardText className="text-Primary ff">
                                    Analytics and reporting in the context of e-commerce are essential for understanding customer behavior, tracking performance and making data-driven decisions to drive growth.
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-sm-4 col-lg-4 col-xxl-3">
                        <Card className="text-Primary h-100 ">
                            <CardBody className="card-inner text-primary">
                                <img src={SlideE} alt="Optimization" className="h-350px object-fit-contain img-fluid" />
                                <CardTitle tag="h5" className="ff">Mobile Optimization</CardTitle>
                                <CardText className="ff">
                                    Mobile optimization for e-commerce websites is the process of designing and formatting your online store so that it functions well and looks good on mobile devices.
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-sm-4 col-lg-4 col-xxl-3 ">
                        <Card className="text-Primary h-100">
                            <CardBody className="card-inner text-primary">
                                <img src={SlideF} alt="Support" className="h-350px object-fit-contain img-fluid" />
                                <CardTitle tag="h5" className="ff">Expert Support and Training</CardTitle>
                                <CardText className="ff">
                                    {process.env.REACT_APP_PROJECT_NAME} recognizes that every business is unique and thus, offers expert support and training to help businesses maximize the potential of their online store.
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EcommServices;

import React from 'react'
import { Block } from '../../../../../components/Component'
import { Col } from 'reactstrap'
import SlideA from "../../../../../images/axiom_img/3Ddesign/02.png";

const ChooseUs = () => {
    return (
        <React.Fragment>
            <Block>
                <div className='nk-content' style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                    <div className="container-fluid p-4 ">
                        <div className="container bg-transparent ">
                            <div className='d-flex justify-content-center mx-md-5'>
                                <p className='ff fs-2 fw-bolder text-primary text-center text-capitalize '>Why {process.env.REACT_APP_PROJECT_NAME} should be your 3D design partner ?</p>
                            </div>
                        </div>
                        <div className=" row">
                            <Col md='7'>
                                <div className=''>
                                    <div className='d-flex flex-column justify-content-center my-4 '>
                                        <p className='ff fs-4 text-start text-primary '>In today's competitive landscape, captivating visuals are no longer a luxury, but a necessity. At {process.env.REACT_APP_PROJECT_NAME}, we understand the power of 3D design to elevate your brand and breathe life into your ideas. But where do you begin?
                                        </p>
                                        <p className='ff fs-4 text-start text-primary '> Our 3D design services bridge the gap between imagination and reality. We leverage the expertise of our passionate design team, armed with cutting-edge 3D modeling software, to craft stunning models tailored to your specific needs. Unlike a one-size-fits-all approach, we prioritize collaboration, meticulously translating your vision into a captivating 3D experience. This ensures seamless integration with your website or application, further enhancing user engagement and brand impact. Ready to see the difference 3D design can make? Let's discuss your project and bring your vision to life.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md='5' className='align-self-center'>
                                <img top src={SlideA} alt="3Dimg" className='img-fluid ' />
                            </Col>
                        </div>
                    </div>
                </div>
            </Block>

        </React.Fragment>
    )
}

export default ChooseUs

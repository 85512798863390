import React, { useState, useCallback, useEffect } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, CardText, CardTitle, Col, Collapse, Row, TabContent, TabPane } from 'reactstrap';
import Content from '../../../../layout/content/Content';
import { Icon } from '../../../../components/Component';
import classnames from "classnames";
import { Link } from 'react-scroll';

import tabimg1 from '../../../../images/siloimages/ui_ux.png';
import tabimg2 from '../../../../images/siloimages/graphics.png';
import tabimg3 from '../../../../images/siloimages/3D.png';
import tabimg4 from '../../../../images/siloimages/game.png';
import tabimg5 from '../../../../images/siloimages/static&dynamic.png';
import tabimg6 from '../../../../images/siloimages/ecom.png';
import tabimg7 from '../../../../images/siloimages/erp_crm.png';
import tabimg8 from '../../../../images/siloimages/mobiledev.png';
import tabimg9 from '../../../../images/siloimages/webhosting.png';
import tabimg10 from '../../../../images/siloimages/Domainmanage.png';
import tabimg11 from '../../../../images/siloimages/server.png';
import tabimg12 from '../../../../images/siloimages/websupport.png';
import tabimg13 from '../../../../images/siloimages/digital_marketing.png';
import tabimg14 from '../../../../images/siloimages/social_media.png';
import tabimg15 from '../../../../images/siloimages/Brand.png';
import tabimg16 from '../../../../images/siloimages/content.png';

const services = [
    { title: "Design Services", id: "1", items: ["UI/UX Design", "Graphics Design", "3D Design", "Game Design"], hrWidth: "50%" },
    { title: "Software Development", id: "2", items: ["Static & Dynamic Website", "E-Commerce Website", "ERP / CRM", "Mobile App"], hrWidth: "65%" },
    { title: "Web Infrastructure & Management", id: "3", items: ["Web Hosting Services", "Domain Registration and Management", "Dedicated Server Hosting", "Website Maintenance & Support"], hrWidth: "93%" },
    { title: "Digital Marketing & Maintenance", id: "4", items: ["SEO & Digital Marketing", "Social Media Management & Creation", "Brand Evolution", "Content Marketing"], hrWidth: "90%" }
];

const tabData = [
    {
        id: "1", title: "UI/UX Design", image: tabimg1, subtitle: "The Foundation for Exceptional UI/UX Design",
        advantages: [
            { title: "Granular User Segmentation", description: "Silo Secured Data empowers us to create highly detailed user personas, ensuring every design element resonates with your specific target audience segments" },
            { title: "Unparalleled Data Integrity", description: "User privacy is never compromised. Silo Secured Data keeps sensitive information compartmentalized and protected from unauthorized access." },
            { title: "Data-Driven Design Decisions", description: "Backed by robust, secure data, our design team crafts intuitive, efficient interfaces that foster user trust and confidence." }
        ],
        footer: null
    },
    {
        id: "2", title: "Graphic Design", image: tabimg2, subtitle: "The Catalyst for Compelling Graphic Design",
        advantages: [
            { title: "Targeted Audience Insights", description: "Secure user data fuels the creation of highly targeted creative assets. We understand your audience demographics, preferences and behaviours, allowing for visuals that truly connect." },
            { title: "Data-Driven Color Psychology", description: "Leveraging Silo Secure Data, we move beyond guesswork. We analyze user color preferences to inform color palette selections that evoke specific emotions and drive engagement." },
            { title: "Personalized Visual Experiences", description: "Silo Secure Data empowers us to tailor visuals based on user behaviour. This personalization fosters deeper connections with your audience and strengthens brand loyalty." }
        ],
        footer: null
    },
    {
        id: "3", title: "3D Design", image: tabimg3, subtitle: "The Bedrock of High-Fidelity 3D Design",
        advantages: [
            { title: "Dimensional Accuracy", description: "Secure user data informs the creation of highly detailed 3D models, guaranteeing precise replication of real-world objects or environments." },
            { title: "Material Selection Optimization", description: "By leveraging Silo Secure Data on user preferences and purchasing habits, we craft 3D models with materials that resonate with your target audience, enhancing their visual appeal and perceived value." },
            { title: "Intuitive User Interaction", description: "Data guides the design of user-friendly 3D interfaces, fostering seamless user experiences in virtual reality (VR) or augmented reality (AR) applications." }
        ],
        footer: null
    },
    {
        id: "4", title: "Game Design", image: tabimg4, subtitle: "Fueling Next-Gen Game Design",
        advantages: [
            { title: "Granular Player Segmentation", description: "Secure player data allows for the development of highly detailed player personas, enabling the creation of personalized in-game journeys that resonate with diverse player demographics and preferences." },
            { title: "Data-Driven Game Balancing", description: "We leverage anonymized player data to identify and address balance issues within gameplay mechanics. This ensures a consistently fair and engaging experience for all players." },
            { title: "Immersive World Design Informed by Data", description: "Secure player data informs the creation of captivating in-game environments tailored to player preferences. This fosters deeper immersion and a stronger connection with the game world." }
        ],
        footer: null
    },
    {
        id: "5", title: "Static & Dynamic Websites", image: tabimg5, subtitle: "Building Exceptional Static & Dynamic Websites",
        advantages: [
            { title: "Personalize Static & Dynamic Content", description: "Deliver targeted content and user experiences across both static and dynamic website elements for deeper connections and conversions" },
            { title: "Optimize Performance & Scalability", description: "Ensure fast loading times and a scalable website that adapts to your traffic, regardless of website type." },
            { title: "Drive Results (Dynamic)", description: "Leverage data for A/B testing, refining dynamic elements for maximum user engagement and conversions." }
        ],
        footer: null
    },
    {
        id: "6", title: "E-Commerce Website", image: tabimg6, subtitle: "Powering Exceptional E-commerce Experiences",
        advantages: [
            { title: "Personalize Customer Journeys", description: "Targeted recommendations and promotions drive sales and loyalty." },
            { title: "Optimize Inventory & Placement", description: "Data insights minimize lost sales and maximize profitability." },
            { title: "Boost Security & Trust", description: "Secure environments build customer confidence for a frictionless shopping experience." }
        ],
        footer: "We create data-driven e-commerce solutions that convert. Let's discuss how secure data can elevate your online store."
    },
    {
        id: "7", title: "ERP/CRM", image: tabimg7, subtitle: "The Power Behind Your ERP/CRM Website",
        advantages: [
            { title: "Streamlined Data Integration", description: "Silo Secure Data architecture eliminates the complexities of system integration. Your ERP/CRM website seamlessly connects with existing infrastructure, fostering a unified data landscape for streamlined operations." },
            { title: "Enhanced Data Governance and Visibility", description: "Gain comprehensive control and clear visibility into your data. Silo Secure Data empowers informed decision-making at all levels, ensuring strategic business practices." },
            { title: "Robust Security and User Access Control", description: "Our secure data environments safeguard sensitive information and maintain granular user access controls. This mitigates security risks and ensures data integrity within your ERP/CRM ecosystem." }
        ],
        footer: null
    },
    {
        id: "8", title: "Mobile Application", image: tabimg8, subtitle: "Secure & User-Centric Mobile Apps",
        advantages: [
            { title: "Personalized User Journeys", description: "Securely stored user data empowers us to craft highly targeted app experiences. This ensures each user receives an experience tailored to their preferences, fostering deeper engagement and driving long-term retention." },
            { title: "Unparalleled Security and Privacy", description: "Silo Secure Data compartments create a secure environment for sensitive user information. This builds trust and confidence in your app, enhancing user experience and brand loyalty." },
            { title: "Data-Driven App Optimization", description: "Leveraging anonymized user data unlocks valuable insights. We utilize these insights to continuously improve your app's design, functionality and user flow, guaranteeing a seamless and efficient mobile experience." }
        ],
        footer: null
    },
    {
        id: "9", title: "Website Hosting", image: tabimg9, subtitle: "Building Exceptional Static & Dynamic Websites",
        advantages: [
            { title: "Unparalleled Data Isolation", description: "Your data remains completely segregated from other users on our servers, minimizing security vulnerabilities and unauthorized access attempts." },
            { title: "Enhanced Performance and Scalability", description: "Silo secured data allows for optimized server resource allocation, guaranteeing a consistently fast and reliable hosting experience for your website, regardless of traffic fluctuations." },
            { title: "Robust Compliance and Peace of Mind", description: "Strict access controls and industry-compliant security protocols ensure your data meets the most rigorous regulations, giving you peace of mind and fostering trust with your visitors." }
        ],
        footer: null
    },
    {
        id: "10", title: "Domain Registration & Management", image: tabimg10, subtitle: "Secure Your Domain, Build Brand Trust",
        advantages: [
            { title: "Uncompromising Protection", description: "Isolated data minimizes security risks, ensuring domain integrity and brand reputation." },
            { title: "Strict Privacy", description: "Rigorous protocols keep your domain information confidential, fostering user trust." },
            { title: "Simplified Security", description: "Manage your domain with ease, knowing it's protected by the latest measures." }
        ],
        footer: "Focus on building your online presence, with confidence in our secure domain solutions. Let's discuss how we can empower your online success."
    },
    {
        id: "11", title: "Dedicated Server Hosting", image: tabimg11, subtitle: "Dedicated Security for Dedicated Servers",
        advantages: [
            { title: "Unmatched Isolation & Control", description: "Your data on a server solely for you. Configure robust security tailored to your needs." },
            { title: "Enhanced Security Posture", description: "Minimized attack surface reduces vulnerabilities and strengthens trust." },
            { title: "Peak Performance & Scalability", description: "Dedicated resources and isolated data ensure seamless user experience and effortless scaling" }
        ],
        footer: "Go beyond traditional hosting. Secure your mission-critical data and unlock business growth with Silo Secure Data architecture. Let's discuss your needs."
    },
    {
        id: "12", title: "Website Maintenance & Support", image: tabimg12, subtitle: "Powering Top-Tier Website Maintenance",
        advantages: [
            { title: "Faster Issue Resolution", description: "Secure, organized data allows for swift diagnosis and fixes, minimizing downtime." },
            { title: "Proactive Security", description: "Silo data enables us to proactively identify and address security threats before they impact your website." },
            { title: "Effortless Maintenance", description: "Secure backups and version control ensure efficient website updates and rollbacks." }
        ],
        footer: "We secure your data to keep your website secure, reliable and performing at its best."
    },
    {
        id: "13", title: "SEO & Digital Marketing", image: tabimg13, subtitle: "Optimize Your SEO & Digital Marketing",
        advantages: [
            { title: "Targeted Audiences", description: "We create laser-focused strategies with personalized experiences that resonate deeply." },
            { title: "Data-Driven Content", description: "Craft high-quality content aligned with user intent for top search rankings and qualified traffic." },
            { title: "Measurable ROI", description: "Track and optimize campaigns with secure data, ensuring maximum return on investment." }
        ],
        footer: "We secure your data to fuel results. Let's discuss your SEO & digital marketing goals."
    },
    {
        id: "14", title: "Social Media Management & Creation", image: tabimg14, subtitle: "Your Brand's Trusted Partner",
        advantages: [
            { title: "Target the Right Audience", description: "Reach the perfect demographic with laser-focused content." },
            { title: "Craft Engaging Content", description: "Secure data guides content creation for maximum engagement." },
            { title: "Optimize & Measure", description: "Track results and refine strategies for peak performance." }
        ],
        footer: "We secure your data to fuel social media success."
    },
    {
        id: "15", title: "Brand Evolution", image: tabimg15, subtitle: "The Strategic Foundation for Brand Evolution",
        advantages: [
            { title: "Granular Customer Insights", description: "We analyze secure data to uncover hidden patterns, revealing a deeper understanding of your target audience." },
            { title: "Brand Strategy Development", description: "These insights inform a data-backed brand strategy, ensuring your message resonates and drives engagement." },
            { title: "Quantifiable Success Measurement", description: "Secure data lets us track key metrics and optimize your brand strategy for continuous improvement and success." }
        ],
        footer: "We partner with you to evolve your brand with confidence, guided by the power of secure data."
    },
    {
        id: "16", title: "Content Marketing", image: tabimg16, subtitle: "High-Impact Content Marketing",
        advantages: [
            { title: "Targeted Content", description: "Craft hyper-personalized content that resonates with specific audiences, driving deeper engagement." },
            { title: "Data-Driven Strategy", description: "Analyze user behaviour to create content aligned with user intent, maximizing reach and qualified traffic." },
            { title: "Measurable ROI", description: "Track key metrics with secure data to optimize your strategy and ensure maximum return on investment." }
        ],
        footer: "We secure your data to unlock content marketing's full potential."
    }
];

const AccordionItem = ({ service, isOpen, toggleCollapse, activeTab, toggleTab, borderLink, handleBorderLinkChange }) => (
    <div className="accordion-item border-0">
        <div className={`accordion-head${isOpen !== service.id ? " collapsed" : ""} border-0 px-lg-4 px-md-3 px-1`} onClick={() => toggleCollapse(service.id)} style={{ cursor: 'pointer' }}>
            <div className="d-flex align-items-center">
                <div className="accordhead">
                    <p className="title ff-alt fs-4 me-4" style={{ color: '#17253D' }}>{service.title}</p>
                </div>
                <Icon name={isOpen === service.id ? "chevron-up" : "chevron-down"} className="fs-2" />
            </div>
            {isOpen === service.id && (
                <hr className='border-bottom border-top-0 border-4 border-primary mb-0' width={service.hrWidth} />
            )}
        </div>
        <Collapse className="accordion-body border-0" isOpen={isOpen === service.id}>
            <div className="accordion-inner py-0 border-0">
                <ul className="px-md-2 px-0 nav link-list-menu">
                    {service.items.map((item, index) => {
                        const itemIndex = services.findIndex(s => s.id === service.id) * 4 + index + 1;
                        return (
                            <li key={index}
                                className={`pb-2 fs-6 ff-alt p-2 ${borderLink === index.toString() ? "bg-gray-100" : ""
                                    } ${classnames({ active: activeTab === itemIndex.toString() })}`}
                                onMouseLeave={() => handleBorderLinkChange("")}
                                onMouseEnter={() => {
                                    handleBorderLinkChange(index.toString());
                                    toggleTab(itemIndex.toString());
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                {item}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Collapse>
    </div>
);

const ServiceCard = React.memo(({ title, image, subtitle, advantages, footer, height, animationClass }) => (
    <Card className={`card-bordered rounded-5 shadow-lg ff-alt ${animationClass}`} style={{ height }}>
        <CardHeader className="bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">
            {title}
            <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                <img src={image} alt="service" className='img-fluid cardimg rounded-5 position-absolute z-3' width="40%" />
            </div>
        </CardHeader>
        <CardBody className="card-inner">
            <CardTitle tag="h5" className='text-dark'>{subtitle}</CardTitle>
            <CardText className='p-2 text-dark'>
                Our approach offers distinct advantages:
                <ul className='list'>
                    {advantages.map((advantage, index) => (
                        <li key={index}>
                            <p>
                                <span className='fw-semibold text-dark'>{advantage.title}: </span>
                                {advantage.description}
                            </p>
                        </li>
                    ))}
                </ul>
                {footer && <CardFooter className='bg-white px-0 text-dark'>{footer}</CardFooter>}
                <div className="py-3 d-flex justify-content-center">
                    <Link to="contact" smooth={true} offset={-10} duration={100}>
                        <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                            <span>Book Consultation</span>
                        </Button>
                    </Link>
                </div>
            </CardText>
        </CardBody>
    </Card>
));
const VerticalTabs = ({ className, variation, ...props }) => {
    const [isOpen, setIsOpen] = useState("1");
    const [activeTab, setActiveTab] = useState("1");
    const [borderLink, setBorderLink] = useState("");
    const [height, setHeight] = useState('580px');
    const [animationClass, setAnimationClass] = useState("");

    const toggleTab = useCallback((tab) => {
        if (activeTab !== tab) {
            setAnimationClass("card-disappear");
            setTimeout(() => {
                setActiveTab(tab);
                setAnimationClass("card-appear");
            }, 300);
        }
    }, [activeTab]);

    const toggleCollapse = useCallback((param) => {
        setIsOpen(prevIsOpen => prevIsOpen === param ? "0" : param);
    }, []);

    const handleBorderLinkChange = useCallback((index) => {
        setBorderLink(index);
    }, []);

    useEffect(() => {
        const updateHeight = () => {
            const width = window.innerWidth;
            if (width >= 1200) {
                setHeight('530px');
            } else if (width >= 1024) {
                setHeight('580px');
            } else if (width >= 992) {
                setHeight('570px');
            } else if (width >= 768) {
                setHeight('100%');
            } else if (width >= 576) {
                setHeight('570px');
            } else {
                setHeight('100%');
            }
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    return (
        <Content>
            <Row className="g-gs">
                <Col md={12} lg={4}>
                    <div className={`accordion${variation ? " accordion-s" + variation : ""}${className ? " " + className : ""} border-0`}>
                        {services.map((service) => (
                            <AccordionItem
                                key={service.id}
                                service={service}
                                isOpen={isOpen}
                                toggleCollapse={toggleCollapse}
                                activeTab={activeTab}
                                toggleTab={toggleTab}
                                borderLink={borderLink}
                                handleBorderLinkChange={handleBorderLinkChange}
                            />
                        ))}
                    </div>
                </Col>
                <Col md={12} lg={8}>
                    <TabContent activeTab={activeTab}>
                        {tabData.map((tab) => (
                            <TabPane key={tab.id} tabId={tab.id}>
                                <div className="container">
                                    <ServiceCard
                                        title={tab.title}
                                        image={tab.image}
                                        subtitle={tab.subtitle}
                                        advantages={tab.advantages}
                                        footer={tab.footer}
                                        height={height}
                                        animationClass={animationClass}
                                    />
                                </div>
                            </TabPane>
                        ))}
                    </TabContent>
                </Col>
            </Row>
        </Content>
    );
};

export default VerticalTabs
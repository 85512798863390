import React from 'react';
import { Icon } from '../../../../components/Component';

const FeatureList = () => {
    return (
        <div className="row m-0 ">
            <div className="col-md-6 col-lg-3 mb-3">
                <div className="d-flex m-2 mw-100">
                    <div className="feature-icon pb-5 mb-md-5 pe-2 ">
                        <Icon name={'award'} className={'fs-1'} style={{ color: '#98CB2A' }} />
                    </div>
                    <div className="feature-content">
                        <h3 className='fs-4 mb-1 text-primary ff'>Expertise</h3>
                        <p className='text-black ff'> We excel in web development and digital marketing, empowering businesses to thrive online.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-3">
                <div className="d-flex  m-2 mw-100">
                    <div className="feature-icon pb-5 mb-md-5 pe-2 ">
                        <Icon name={'coins'} className={'fs-1'} style={{ color: '#98CB2A' }} />
                    </div>
                    <div className="">
                        <h3 className='fs-4 mb-1 text-primary ff'>Budget Efficiency</h3>
                        <p className='text-black ff'> We focus on reducing expenses and improving efficiency to maximize financial resources.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-3">
                <div className="d-flex  m-2 mw-100">
                    <div className="feature-icon pb-5 mb-md-5 pe-2 ">
                        <Icon name={'security'} className={'fs-1 '} style={{ color: '#98CB2A' }} />
                    </div>
                    <div className="feature-content">
                        <h3 className='fs-4 mb-1 text-primary ff'>Enhanced Security</h3>
                        <p className='text-black ff'>Additional layers of protection, fortify your system against evolving threats.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-3">
                <div className="d-flex m-2 mw-100">
                    <div className="feature-icon pb-5 mb-md-5 pe-2 ">
                        <Icon name={'growth-fill'} className={'fs-1'} style={{ color: '#98CB2A' }} />
                    </div>
                    <div className="feature-content">
                        <h3 className='fs-4 mb-1 text-primary ff'>Increased Efficiency</h3>
                        <p className='text-black ff'> Achieving superior results with optimized resource utilization. Including time, materials and manpower.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureList;
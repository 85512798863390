import React from 'react'
import { Col, Row } from '../../../../../components/Component'
import { Card, CardBody, CardImg, CardText, CardTitle } from 'reactstrap'
import SlideA from "../../../../../images/axiom_img/WebHosting-images/power.png";
import SlideB from "../../../../../images/axiom_img/WebHosting-images/protection.png";
import SlideC from "../../../../../images/axiom_img/WebHosting-images/uptime.png";
import SlideD from "../../../../../images/axiom_img/WebHosting-images/24-hours-support.png";
import SlideE from "../../../../../images/axiom_img/WebHosting-images/Easy Control.png";
import SlideF from "../../../../../images/axiom_img/WebHosting-images/Upgrade.png";

const WhyChooseUs = () => {
    const bgColor1 = '#D1D7FC';
    const bgColor2 = '#CEE8FF';
    return (
        <React.Fragment>
            <div className='container'>
                <Row>
                    <Col>
                        <p className="fw-bolder text-primary text-center ff my-5 fs-1 ">Discover Our Hosting Features</p>
                    </Col>
                </Row>
                <div className='my-3 my-md-5'>
                    <Row className="g-4">
                        <Col md='4'>
                            <Card className="card-bordered h-100">

                                <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Powerful Servers</CardTitle>
                                    <div className='d-flex justify-content-center '>
                                        <CardImg top src={SlideA} alt="Servers" className='w-35' />
                                    </div>
                                    <CardText className='ff text-primary'>
                                        Our hosting services utilize state-of-the-art servers equipped with the latest hardware for optimal performance.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md='4'>
                            <Card className="card-bordered h-100">
                                <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Robust Security Measures</CardTitle>
                                    <div className='d-flex justify-content-center '>
                                        <CardImg top src={SlideB} alt="Security" className='w-35' />
                                    </div>
                                    <CardText className='ff text-primary'>
                                        Security is paramount. We employ advanced security protocols including DDoS protection, firewalls, regular malware scans and SSL certificates.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md='4'>
                            <Card className="card-bordered h-100">
                                <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Guaranteed Uptime</CardTitle>
                                    <div className='d-flex justify-content-center '>
                                        <CardImg top src={SlideC} alt="Uptime" className='w-35' />
                                    </div>
                                    <CardText className='ff text-primary'>
                                        We offer a 99.9% uptime guarantee, ensuring your website is always accessible to visitors.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md='4'>
                            <Card className="card-bordered h-100">
                                <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>24/7 Customer Support</CardTitle>
                                    <div className='d-flex justify-content-center '>
                                        <CardImg top src={SlideD} alt="24/7Support" className='w-35' />
                                    </div>
                                    <CardText className='ff text-primary'>
                                        Our dedicated support team is available round-the-clock to assist you with any hosting-related issues.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md='4'>
                            <Card className="card-bordered h-100">
                                <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Scalability and Flexibility</CardTitle>
                                    <div className='d-flex justify-content-center '>
                                        <CardImg top src={SlideF} alt="Scalability" className='w-35' />
                                    </div>
                                    <CardText className='ff text-primary'>
                                        Our hosting plans are scalable, allowing you to upgrade resources seamlessly as your website traffic and demands increase.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md='4'>
                            <Card className="card-bordered h-100">
                                <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Easy-to-Use Control Panel</CardTitle>
                                    <div className='d-flex justify-content-center '>
                                        <CardImg top src={SlideE} alt="Control Panel" className='w-35' />
                                    </div>
                                    <CardText className='ff text-primary'>
                                    Effortlessly manage your hosting with our intuitive control panel. Enjoy seamless control, enhanced features, and user-friendly tools designed for your convenience and efficiency.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment >
    )
}

export default WhyChooseUs

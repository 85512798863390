import React from 'react'
import { Block, Col } from '../../../../../components/Component'
import slideA from '../../../../../images/axiom_img/EcomPage-images/E- Commerce.png'

const ChooseUS = () => {
    return (
        <React.Fragment>
            <Block>
                <div className='nk-content' style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                    <div className="container-fluid py-0 py-md-4 ">
                        <div className="container bg-transparent ">
                            <div className='d-flex justify-content-center mx-2  mx-md-5'>
                                <p className='ff fs-2 fw-bolder text-primary text-center text-capitalize '>Why {process.env.REACT_APP_PROJECT_NAME} Should Be Your E-commerce Web Partner?</p>
                            </div>
                        </div>
                        <div className=" row">
                            <Col md='7'>
                                <div className='container'>
                                    <div className='d-flex flex-column justify-content-center my-4 '>
                                        <p className='ff fs-4 text-start text-primary '>{process.env.REACT_APP_PROJECT_NAME} is a cutting-edge E-Commerce web partner that offers a robust and comprehensive platform designed to elevate online shopping experiences. This platform is tailored to meet the unique needs of businesses, providing a seamless integration of customization, security, scalability, marketing tools and expert support. </p>

                                        <p className='ff fs-4 text-start text-primary '>{process.env.REACT_APP_PROJECT_NAME} stands out for its ability to cater to a global audience, ensuring that businesses can reach customers worldwide with ease. With competitive pricing and a commitment to providing expert support and training, {process.env.REACT_APP_PROJECT_NAME} is the ideal choice for businesses looking to enhance their online presence and drive growth in the competitive E-Commerce landscape. </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md='5' className={`align-self-center`}>
                                <img src={slideA} alt="Ecom_img" />
                            </Col>
                        </div>
                    </div>
                </div>
            </Block>

        </React.Fragment>
    )
}

export default ChooseUS

import React from 'react'
import { NavLink, Row } from 'reactstrap'
import Logo from '../../../images/siloimages/SecuredDatalogonew.png'
import { Icon, LinkItem } from '../../../components/Component'
import { Link } from 'react-scroll'

const SecureDataFooter = () => {
    return (
        <React.Fragment>
            <div className="nk-footer border-0" style={{ backgroundColor: '#E8F7FF' }}>
                <div className="container-fluid">
                    <div className='d-flex pb-3'>
                        <div className="col-md-12 align-self-center ">
                            <div className="nk-footer-copyright text-center">
                                <LinkItem to="/" className=" scrollto logo-link mx-2">
                                    <img className="align-self-center" src={Logo} alt="logo" width={'40%'} style={{ cursor: 'pointer' }} />
                                </LinkItem>
                            </div>
                        </div>
                    </div>
                    <div className="nk-footer-wrap justify-content-center">
                        <div className='container'>
                            <Row className='justify-content-lg-around justify-content-md-start pb-2'>
                                <h4 className="text-black-50 fw-bold ff-alt d-flex align-content-center justify-content-center mb-2">Our Services</h4>
                                <div className="nk-footer-copyright col-md-3">
                                    <p className="text-black fs-6 fw-bold ff-alt">Software Development</p>
                                    <ul className="list-unstyled ff">
                                        <li className='mb-2'>
                                            <Link to="service ff-alt">Static & Dynamic Web Development</Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to="service ff-alt">E-commerce Web Development</Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to="service ff-alt">ERP / CRM Development</Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to="service ff-alt">Mobile App Development</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="nk-footer-copyright col-md-2">
                                    <p className="fw-bold ff-alt fs-6 text-black">Creative & Design</p>
                                    <ul className="list-unstyled ff">
                                        <li className='mb-2'>
                                            <Link to="service ff-alt">UI/UX Design</Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to="service ff-alt">Graphic Design</Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to="service ff-alt">3D Design</Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to="service ff-alt">Game Design</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="nk-footer-copyright col-md-3">
                                    <p className="text-black fs-6 fw-bold ff-alt">Digital Marketing & Management</p>
                                    <ul className="list-unstyled ff">
                                        <li className='mb-2'>
                                            <Link to="service ff-alt">SEO & Digital Marketing</Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to="service ff-alt">Social Media Management & Creation</Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to="service ff-alt">Brand Evolution</Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to="service ff-alt">Content Marketing</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="nk-footer-copyright col-md-4">
                                    <p className="text-black fs-6 fw-bold ff-alt">Web Infrastructure & Management</p>
                                    <ul className="list-unstyled ff">
                                        <li className='mb-2'>
                                            <Link to="service ff-alt">Web Hosting</Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to="service ff-alt" >Domain Registration & Management</Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to="service ff-alt">Dedicated Server Hosting</Link>
                                        </li>
                                        <li className='mb-2'>
                                            <Link to="service ff-alt">Website Maintenance & Support</Link>
                                        </li>
                                    </ul>
                                </div>
                            </Row>
                        </div>
                        <div className="container d-md-flex justify-content-start">
                            <div className="nk-footer-copyright pb-3 d-flex align-items-center">
                                <h4 className="text-black-50 fw-bold ff-alt fs-4 pe-3">Contact Us</h4>
                                <ul className="list-unstyled">
                                    <li className='px-2'>
                                        <Icon name={"call"} />
                                        <a href="tel:833-468-6648" className="px-2 ff-alt">833-468-6648</a>
                                    </li>
                                    <li className='px-2'>
                                        <Icon name={"mail"} />
                                        <a href="mailto:support@silocloud.io" className="px-2 ff-alt">support@silocloud.io</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="nk-footer-copyright d-flex align-items-center">
                                <h4 className="text-black-50 fw-bold ff-alt fs-4 pe-3">Follow Us</h4>
                                <div className="d-flex ps-3 ps-md-0">
                                    <Icon name={"linkedin-round"} className={"fs-1 socialbtn"} />
                                    <Icon name={"facebook-fill"} className={"fs-1 socialbtn"} />
                                    <Icon name={"instagram-round"} className={"fs-1 socialbtn"} />
                                    <Icon name={"youtube-round"} className={"fs-1 socialbtn"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </React.Fragment >
    )
}

export default SecureDataFooter

import React from 'react'
import { Col, Row } from 'reactstrap'
import Content from '../../../../../layout/content/Content'

const UsefullTo = () => {
    return (
        <React.Fragment>
            <Content >
                <div className="container pb-4 rounded-5 " style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                    <Row>
                        <Col>
                            <p className="text-primary text-center text-capitalize ff fs-1 fw-bold my-5 px-3 px-md-0">Who Benefits from Professional Game Design Services?</p>
                        </Col>
                    </Row>
                    <div className='d-flex flex-column  justify-content-center my-4 '>
                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '> Established Game Development Studios:</span>  Studios leverage design services to refine concepts, ensure project clarity and guide development towards a cohesive vision.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '>Independent Game Developers and Small Teams:</span> Independent creators benefit from the expertise of experienced designers, minimizing risk and maximizing the potential of their unique ideas.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '>Non-Game Industry Professionals:</span> Businesses across various sectors are increasingly utilizing game design principles to create engaging and interactive experiences for their audiences, such as educational tools, marketing simulations and training programs.</p>
                    </div>
                </div>
            </Content>
        </React.Fragment>
    )
}

export default UsefullTo

import React from 'react'
import { Card, CardBody, CardImg, CardText, CardTitle, Col, Row } from 'reactstrap';
import SlideA from "../../../../../images/axiom_img/ContentMarketing/Content Creation.png";
import SlideB from "../../../../../images/axiom_img/ContentMarketing/Content Planning (1).png";
import SlideC from "../../../../../images/axiom_img/ContentMarketing/Content Marketing.png";
import SlideD from "../../../../../images/axiom_img/ContentMarketing/Content Optimization.png";

const ServiceSection = () => {
    const bgColor1 = '#D1D7FC';
    const bgColor2 = '#CEE8FF';
    return (
        <React.Fragment>
            <div className='container'>
                <Row>
                    <Col>
                        <p className="fw-bolder text-primary text-center ff my-5 fs-1 ">Our Content Marketing Services Offer</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="mb-4">
                        <Card className="card-bordered h-100">
                            <CardImg top src={SlideA} alt="Content Creation" className='h-350px object-fit-contain img-fluid' />
                            <CardBody className="card-inner " style={{ backgroundColor: `${bgColor2}` }}>
                                <CardTitle className='ff fs-3 fw-bold text-primary '>Content Creation and Ideation</CardTitle>
                                <CardText className='text-primary ff'>
                                Axiom specializes in curating captivating and original content meticulously tailored to embody your brand's unique voice and resonate with your target audience. Our expertise spans a versatile range of content formats, including articles, blog posts, videos, infographics, podcasts and more.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card className="card-bordered h-100">
                            <CardImg top src={SlideB} alt="Content Planning" className='h-350px object-fit-contain img-fluid' />
                            <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                                <CardTitle className='ff fs-3 fw-bold text-primary'>Content Strategy and Planning</CardTitle>
                                <CardText className='text-primary ff'>
                                Axiom excels in developing robust content strategies meticulously aligned with your business objectives. Through a strategic process, we identify pivotal topics, themes and optimal channels to maximize the impact of your content efforts.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card className="card-bordered h-100">
                            <CardImg top src={SlideC} alt="Content Marketing" className='h-350px object-fit-contain img-fluid' />
                            <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                                <CardTitle className='ff fs-3 fw-bold text-primary'>Content Distribution and Promotion</CardTitle>
                                <CardText className='text-primary ff'>

                                    Axiom strategically harnesses a diverse array of distribution channels to amplify and promote your content with precision. Our multi-faceted approach encompasses leveraging social media platforms, targeted email marketing campaigns, strategic partnerships with influencers and collaborative alliances within relevant industries.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card className="card-bordered h-100">
                            <CardImg top src={SlideD} alt="Content Optimization" className='h-350px object-fit-contain img-fluid' />
                            <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                                <CardTitle className='ff fs-3 fw-bold text-primary'>Content Analytics and Optimization</CardTitle>
                                <CardText className='text-primary ff'>
                                    Axiom employs sophisticated analytics methodologies to meticulously assess content performance and refine strategies. Our analytics framework encompasses a spectrum of key metrics, including engagement rates, conversion metrics and granular insights into audience behavior and preferences.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default ServiceSection


import React from 'react'
import { Block } from '../../../../../components/Component'

const HostAbout = () => {
    return (
        <React.Fragment>
            <Block>
                <div className="container py-md-5 py-3">
                    <span className="ff fs-5 " style={{ color: "#2B3E79" }}>
                        Web Infrastructure & Management
                    </span>
                </div>
                <div className="container py-4 ">
                    <div className='d-flex justify-content-center '>
                        <p className='ff fs-2 fw-bolder text-primary text-center '>Achieve Unparalleled Web Performance with Axiom's Hosting Infrastructure & Management
                        </p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-semibold '>At {process.env.REACT_APP_PROJECT_NAME}, we recognize the critical role a robust web infrastructure plays in your online success. Our comprehensive Web Hosting, Infrastructure & Management Services are meticulously crafted to alleviate the burden of technical complexities and propel your website towards exceptional performance.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-bolder text-primary '>Why Choose Axiom for Web Hosting?</p>
                    </div>
                    <div className='d-flex flex-column  justify-content-center my-4 '>
                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Unparalleled Technical Expertise: </span>  Our team of seasoned professionals possess unparalleled expertise in web infrastructure and management. We expertly handle intricate technical facets, encompassing server configuration, performance optimization and unwavering security implementation. This allows you to dedicate your focus to the core aspects of your business.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Scalability Tailored to Your Growth Trajectory:</span>  Whether you're a budding startup venturing into the digital sphere or a well-established brand, Axiom's solutions are meticulously designed to seamlessly scale alongside your growth trajectory. We provide a comprehensive suite of flexible hosting plans that effortlessly adapt to your evolving needs and traffic surges, ensuring your website maintains unwavering reliability and exceptional efficiency.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Impregnable Security Measures: </span>  In today's dynamic digital landscape, robust security is non-negotiable. We prioritize the implementation of industry-leading security measures to safeguard your website and invaluable data. Our proactive monitoring systems coupled with advanced firewall technology perpetually stand guard against potential threats, granting you unparalleled peace of mind.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Performance Engineered for Success:</span> Website speed is a cornerstone of user engagement. We leverage cutting-edge technology and strategically implemented servers to deliver unparalleled loading times. This ensures your visitors experience a seamless journey, fostering satisfaction and encouraging them to return.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Unwavering 24/7 Support:</span> Our dedicated and highly skilled support team is readily available 24/7 to address your inquiries and concerns. We prioritize building long-term partnerships and providing exceptional customer service, ensuring you receive the support you deserve whenever you need it.</p>
                    </div>
                </div>
            </Block>
        </React.Fragment>
    )
}

export default HostAbout

import React from 'react'
import { Block } from '../../../../../components/Component'

const About = () => {
    return (
        <React.Fragment>
            <Block>
                <div className="container py-md-5 py-3">
                    <span className="ff fs-5 " style={{ color: "#2B3E79" }}>
                        Creative & Design Services
                    </span>
                </div>
                <div className="container py-4 ">
                    <div className='d-flex justify-content-center '>
                        <p className='ff fs-2 fw-bolder text-primary text-center '>{process.env.REACT_APP_PROJECT_NAME}: Your Trusted Partner for Superior 3D Design ServicesStrategic Graphic Design Services
                        </p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-semibold '>At {process.env.REACT_APP_PROJECT_NAME}, we transcend the realm of web development, establishing ourselves as your comprehensive solution for realizing your design concepts in breathtaking 3D. Our 3D design services leverage the vanguard of 3D modeling technology, coupled with the unwavering expertise of our passionate design team. This potent combination guarantees the creation of exceptional 3D models that not only elevate your projects but also enthrall your target audience.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-bolder text-primary'>What sets Axiom apart as your trusted 3D design partner?</p>
                    </div>
                    <div className='d-flex flex-column  justify-content-center my-4 '>
                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '> Unparalleled Expertise:</span>  Our team possesses a demonstrably successful track record, boasting extensive experience in the intricacies of 3D modeling and design. We have a proven ability to craft immaculate models across a diverse spectrum of industries and applications.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '>  Bespoke Solutions:</span>  We firmly reject the notion of a generic approach. We prioritize establishing a collaborative partnership with you, meticulously delving into your vision to meticulously craft custom 3D models that align flawlessly with your specific requirements.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '>  Cutting-Edge Technology: </span> Our unwavering commitment to excellence compels us to leverage the most advanced 3D modeling software and industry-leading tools. This unwavering dedication ensures the realization of exceptional quality, intricate detail and unparalleled realism within your 3D designs.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '> Enhanced Communication:</span>  We prioritize fostering a collaborative environment through transparent communication throughout the project lifecycle. We firmly believe in keeping you informed and actively involved at every juncture of the creative process.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '> Seamless Integration:</span>  Envisioning the incorporation of your 3D models within your website or application? Our proficiency in web development guarantees a smooth and flawless integration process, eliminating any potential roadblocks.</p>
                    </div>
                </div>
            </Block>
        </React.Fragment>

    )
}

export default About

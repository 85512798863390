import React from 'react'
import Head from '../../../../layout/head/Head'
import Contact from '../../components/Contact'
import Testimonial from '../components/Testimonial'
import Content from '../../../../layout/content/Content'
import About from './GamePageComponents/GameAbout'
import WhyChoose from './GamePageComponents/WhyChoose'
import ServiceOffers from './GamePageComponents/ServiceOffers'
import UsefullTo from './GamePageComponents/UsefullTo'
import GenericHero from '../components/GenericHero'
import { Icon } from '../../../../components/Component'
import heroimg from '../../../../images/axiom_img/Game.png';


const GamePage = () => {
    return (
        <React.Fragment>
            <Head title="Axiom | Game Design"></Head>
            <Content>
                <GenericHero
                    breadcrumbs={
                        <>
                            SERVICES <Icon name={'chevron-right'} /> Creative & Design Services<Icon name={'chevron-right'} /> Game Design
                        </>
                    }
                    title="Conceptualize, Design, Prototype: Your expert game design partner"
                    description="Crafting compelling game experiences: Design efficiency and proven methodologies for a competitive edge."
                    buttonText="Contact Now"
                    buttonLink="/contact"
                    heroImage={heroimg} />
                <About />
                <WhyChoose />
                <ServiceOffers />
                <UsefullTo />
                <Contact />
                <Testimonial />
            </Content>
        </React.Fragment>
    )
}

export default GamePage
import React from 'react'
import { Card, CardBody,  CardImg, CardText, CardTitle, Col, Row } from 'reactstrap'
import SlideA from "../../../../../images/axiom_img/3Ddesign/03.png";
import SlideB from "../../../../../images/axiom_img/3Ddesign/04.png";
import SlideC from "../../../../../images/axiom_img/3Ddesign/05.png";

const ImpOfService = () => {
    const bgColor2 = '#CEE8FF';
    return (
        <div className='container'>
            <Row>
                <Col>
                    <p className="fw-bolder text-primary text-center text-capitalize ff my-5 fs-2 ">How can a graphic design service benefit me?</p>
                </Col>
            </Row>
            <Row>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideA} alt="3D_img" />
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                            <CardTitle className='ff fs-3 fw-bold text-primary '>01. Solidified Brand Identity</CardTitle>
                            <CardText className='text-primary ff'>
                                Professional graphic design establishes a cohesive visual language that embodies your brand essence. This fosters brand recognition, memorability and trust with your target audience, positioning you as a leader in your industry.
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideB} alt="3D_img" />
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                            <CardTitle className='ff fs-3 fw-bold text-primary'>02. Elevated User Experience (UX)</CardTitle>
                            <CardText className='text-primary ff'>
                                User-centered graphic design principles lead to visually appealing and intuitive interfaces. This translates into a seamless user experience on your website or application, increasing user satisfaction, engagement and time spent interacting with your brand.
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100">
                        <CardImg top src={SlideC} alt="3D_img" />
                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                            <CardTitle className='ff fs-3 fw-bold text-primary'>03. Elevated Brand Perception & Competitive Edge</CardTitle>
                            <CardText className='text-primary ff'>
                                High-fidelity 3D visuals elevate the presentation of your products, concepts, or environments, fostering a captivating and immersive user experience. This translates to a strengthened brand image, enhanced customer engagement and a distinct competitive advantage within your market.
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ImpOfService

import React from 'react'
// import { Icon } from '../../../../../components/Component'
import { Button, Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import bgimg1 from "../../../../images/axiom_img/background2.png";

const GenericHero = ({
    breadcrumbs,
    title,
    description,
    buttonText,
    buttonLink,
    heroImage,
    backgroundColor
}) => {
    return (
        <React.Fragment>
            <div className="nk-content pt-md-4 pt-2 position-relative" style={{ backgroundColor: backgroundColor || process.env.REACT_APP_BACKGROUND_COLOR }}>
                <Row className='justify-content-lg-between justify-content-center pb-md-5 pb-3 mb-3'>
                    <Col md='6'>
                        <div className="container-fluid p-0 pt-md-3">
                            <div className='position-absolute icon_img3 d-none d-md-block opacity-50 '>
                                <img src={bgimg1} alt="backimg" className='h-425px bgimg1' style={{ transform: 'rotate(147deg)' }} />
                            </div>
                            <div className="container ms-0 ms-md-3 ms-lg-5 p-md-3" >
                                <span className="ff" style={{ color: "#2B3E79" }}>
                                    {breadcrumbs}
                                </span>
                            </div>
                            <div className='container ms-0 ms-md-3 ms-lg-5 py-4' >
                                <Col className='ff'>
                                    <div className=''>
                                        <p style={{ color: "#004CA1" }} className='f-size fw-bold ff'>{title}</p>
                                    </div>
                                </Col>
                                <div className="container py-2 px-0" >
                                    <p className="text-black ff fs-5 ">{description}</p>
                                </div>
                                <div className="py-3 d-flex justify-content-md-start justify-content-center " >
                                    <Link to={buttonLink}>
                                        <Button className={' "btn-dim px-5 py-3 ff fw-bolder rounded fs-5'} outline color="primary" style={{ zIndex: 999 }}>
                                            {buttonText}
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md='5' className='align-self-center pb-md-0 pb-3 me-0 me-md-4' >
                        <img src={heroImage} alt="heroimg" className='img-fluid' style={{ zIndex: 999 }} />
                    </Col>
                </Row>
                <div className='position-absolute icon_img4 d-none d-md-block z-3'>
                    <img src={bgimg1} alt="backimg" className='h-325px bgimg1 opacity-25' style={{ transform: 'rotate(20deg)' }} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default GenericHero
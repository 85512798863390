import React from 'react'
import { Block } from '../../../../../components/Component'
import { Col } from 'reactstrap'
import SlideA from '../../../../../images/axiom_img/Gameimg2.png'

const WhyChoose = () => {
    return (
        <React.Fragment>
            <Block>
                <div className='nk-content' style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                    <div className="container-fluid py-md-4 py-2 px-md-4 px-2">
                        <div className="container bg-transparent ">
                            <div className='d-flex justify-content-center mx-md-5'>
                                <p className='ff fs-1 fw-bold text-primary text-center text-capitalize '>Why Choose {process.env.REACT_APP_PROJECT_NAME} for Your Game Design Needs?</p>
                            </div>
                        </div>

                        <div className=" row">
                            <Col md='7'>
                                <div className=''>
                                    <div className='d-flex flex-column justify-content-center my-4 '>
                                        <p className='ff fs-4 text-start text-primary '>Unlock the potential of your game with Axiom's exceptional game design services. Our award-winning team is not just mechanics architects but also expert storytellers and world-builders who bring your vision to life. With their vast experience across genres and platforms, they make every design decision informed and strategic.</p>

                                        <p className='ff fs-4 text-start text-primary '>We foster a collaborative environment, prioritizing open communication to flawlessly capture the essence of your game. Axiom provides comprehensive design services, starting from brainstorming initial sparks of creativity to meticulously crafting core mechanics and weaving immersive narratives. Our rapid prototyping expertise takes it a step further – we bring your vision to life, allowing you to test, refine and validate core gameplay loops before full-scale development begins, minimizing risk and maximizing impact. </p>

                                        <p className='ff fs-4 text-start text-primary '>Partnering with Axiom means unlocking a streamlined development cycle fueled by our established processes. We don't just design games – we craft captivating experiences that resonate with players on a deeper level. Join forces with Axiom and embark on a collaborative journey to transform your unique concept into a game that will leave a lasting impression on players worldwide.</p>

                                    </div>
                                </div>
                            </Col>
                            <Col md='5' className='align-self-center '>
                                <img top src={SlideA} alt="game_img" className='img-fluid ' />
                            </Col>
                        </div>
                    </div>
                </div>
            </Block>

        </React.Fragment>
    )
}

export default WhyChoose

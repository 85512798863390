import React from 'react';
import Content from '../../../../layout/content/Content';
import imgUrl from '../../../../images/axiom_img/background5.png';
import ConsultationForm from '../../../components/common/ConsultationForm';

const Consultation = () => {
    return (
        <Content>
            <ConsultationForm
                apiUrl={`${process.env.REACT_APP_API_URL}api/v1/public/consultation`}
                backgroundImage={imgUrl}
                title="Check out availability and book the date and time"
                maxDays={30}
                minTime={new Date(0, 0, 0, 9, 0)}
                maxTime={new Date(0, 0, 0, 17, 0)} />
        </Content>
    );
};

export default Consultation;
import React from 'react'
import { Block } from '../../../../../components/Component'

const About = () => {
    return (
        <React.Fragment>
            <Block>
                <div className="container py-5">
                    <span className="ff fs-5 " style={{ color: "#2B3E79" }}>
                        Software Development Services
                    </span>
                </div>
                <div className="container py-4 ">
                    <div className='d-flex justify-content-center '>
                        <p className='ff fs-1 fw-bolder text-primary text-center '>Advancing Mobile Innovation with {process.env.REACT_APP_PROJECT_NAME}</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-semibold '>Using {process.env.REACT_APP_PROJECT_NAME} a system or platform designed for managing and optimizing web-based functionalities to enhance and streamline mobile applications or services.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-bolder text-primary '>How {process.env.REACT_APP_PROJECT_NAME} can serve as a catalyst for driving mobile innovation ?</p>
                    </div>
                    <div className='d-flex flex-column  justify-content-center my-4 '>
                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '> Enhanced User Experience:</span> Implementing {process.env.REACT_APP_PROJECT_NAME} can lead to improved user experiences in mobile applications by optimizing web-based components such as user interfaces, data management and real-time interactions.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '> Cross-Platform Integration:</span> {process.env.REACT_APP_PROJECT_NAME} facilitates seamless integration across different mobile platforms (iOS & android, etc.), enabling developers to build versatile and compatible applications.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Performance Optimization:</span>  Leveraging {process.env.REACT_APP_PROJECT_NAME} technology can result in better performance of mobile apps, including faster loading times, smoother navigation and efficient data processing.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Advanced Web Capabilities:</span> The utilization of {process.env.REACT_APP_PROJECT_NAME} empowers mobile applications with advanced web functionalities, such as interactive features, dynamic content delivery and secure data handling.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Real-time Updates and Monitoring:</span> {process.env.REACT_APP_PROJECT_NAME} offers real-time monitoring and updates, enabling proactive management of mobile apps and services to ensure optimal performance and reliability.</p>
                    </div>
                </div>
            </Block>
        </React.Fragment>

    )
}

export default About

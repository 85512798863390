import React from 'react'
import Logo from '../../../../layout/logo/Logo'
import { Icon, Row } from '../../../../components/Component'
import { Link } from 'react-router-dom'

const FooterNew = () => {
    return (
        <React.Fragment>
            <div className="nk-footer" style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                <div className="container-fluid">
                    <div className="d-flex justify-content-center align-self-center pb-4">
                        <div className="nk-footer-copyright text-center ">
                            <Logo />
                        </div>
                    </div>
                    <div className="nk-footer-wrap justify-content-center">
                        <Row className='justify-content-lg-around justify-content-md-start pb-2'>
                            <h4 className="text-black-50 fw-bold ff d-flex align-content-center justify-content-center mb-2">Our Services</h4>
                            <div className="nk-footer-copyright col-md-3">
                                <h6 className="text-primary py-2 fw-bold ff ">Software Development</h6>
                                <ul className="list-unstyled ff">
                                    <li className='mb-2'>
                                        <Link to="/static&dynamicweb">Static & Dynamic Web Development</Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to="/e-commerceservices">E-commerce Web Development</Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to="/erpcrmservices">ERP / CRM Development</Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to="/mobileappdevelopment">Mobile App Development</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="nk-footer-copyright col-md-2">
                                <h6 className="fw-bold ff  text-primary py-2">Creative & Design</h6>
                                <ul className="list-unstyled ff">
                                    <li className='mb-2'>
                                        <Link to="/ui/uxservices">UI/UX Design</Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to="/graphicsservices">Graphic Design</Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to="/3Dservices">3D Design</Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to="/gamedesignservices">Game Design</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="nk-footer-copyright col-md-3">
                                <h6 className="text-primary py-2 fw-bold ff">Digital Marketing & Management</h6>
                                <ul className="list-unstyled ff">
                                    <li className='mb-2'>
                                        <Link to="/digitalmarketing">SEO & Digital Marketing</Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to="/socialmediaservices">Social Media Management & Creation</Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to="/brandevolutionservices">Brand Evolution</Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to="/contentmarketingservices">Content Marketing</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="nk-footer-copyright col-md-4">
                                <h6 className="text-primary py-2 fw-bold ff">Web Infrastructure & Management</h6>
                                <ul className="list-unstyled ff">
                                    <li className='mb-2'>
                                        <Link to="/webhostingservice">Web Hosting</Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to="/domainregisterservice" >Domain Registration & Management</Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to="/serverhostingservice">Dedicated Server Hosting</Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to="/websupportservice">Website Maintenance & Support</Link>
                                    </li>
                                </ul>
                            </div>
                        </Row>
                    </div>
                    <div className="container d-md-flex justify-content-start">
                        <div className="nk-footer-copyright pb-3 d-flex align-items-center">
                            <h4 className="text-black-50 fw-bold ff fs-4 pe-3">Contact Us :</h4>
                            <ul className="list-unstyled">
                                <li className='px-2'>
                                    <Icon name={"call"} />
                                    <a href="tel:+4693249860" className="px-2 ff">469.324.9860</a>
                                </li>
                                <li className='px-2'>
                                    <Icon name={"mail"} />
                                    <a href="mailto:info@axiomwebcontrol.com" className="px-2 ff">info@axiomwebcontrol.com</a>
                                </li>
                            </ul>
                        </div>
                        <div className="nk-footer-copyright d-flex align-items-center">
                            <h4 className="text-black-50 fw-bold ff fs-4 pe-3">Follow Us :</h4>
                            <div className="d-flex">
                                <Icon name={"linkedin-round"} className={"fs-1 socialbtn"} />
                                <Icon name={"facebook-fill"} className={"fs-1 socialbtn"} />
                                <Icon name={"instagram-round"} className={"fs-1 socialbtn"} />
                                <Icon name={"youtube-round"} className={"fs-1 socialbtn"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FooterNew
import React from 'react'
import Head from '../../../../layout/head/Head'
import Content from '../../../../layout/content/Content'
import AboutSection from './DigitalMarketingComponents/AboutSection'
import OurServices from './DigitalMarketingComponents/OurServices'
import Contact from '../../components/Contact'
import Testimonial from '../components/Testimonial'
import GenericHero from '../components/GenericHero'
import heroimg from '../../../../images/axiom_img/Digital Marketing.png';
import { Icon } from '../../../../components/Component'

const DigitalMarketing = () => {
    return (
        <React.Fragment>
            <Head title="Axiom | Digital Marketing Services"></Head>
            <Content>
                <GenericHero
                    breadcrumbs={
                        <>
                            SERVICES <Icon name={'chevron-right'} /> Digital Marketing & Management Services<Icon name={'chevron-right'} /> SEO / Digital Marketing
                        </>
                    }
                    title="Axiom: Your SEO and digital marketing solution"
                    description="Elevate your online presence with our expert SEO and digital marketing services."
                    buttonText="Contact Now"
                    buttonLink="/contact"
                    heroImage={heroimg} />
                <AboutSection />
                <OurServices />
                <Contact />
                <Testimonial />
            </Content>
        </React.Fragment>
    )
}

export default DigitalMarketing

import React from 'react'
import { Block } from '../../../../../components/Component'

const SectionAbout = () => {
    return (
        <React.Fragment>
            <Block>
                <div className="container py-md-5 py-3">
                    <span className="ff fs-5 " style={{ color: "#2B3E79" }}>
                        Web Infrastructure & Management
                    </span>
                </div>
                <div className="container py-4 ">
                    <div className='d-flex justify-content-center '>
                        <p className='ff fs-2 fw-bolder text-primary text-center '>Secure and Streamline Your Online Domain Portfolio with {process.env.REACT_APP_PROJECT_NAME}
                        </p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-semibold '>At {process.env.REACT_APP_PROJECT_NAME}, we recognize the critical role your domain names play in establishing and maintaining a robust online presence. We offer industry-leading domain registration and management solutions designed to empower your organization with the tools and expertise necessary to achieve.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-bolder text-primary'>Why Choose Axiom for Web Hosting?</p>
                    </div>
                    <div className='d-flex flex-column justify-content-center my-4 '>
                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '>Unparalleled Domain Security: </span> We prioritize the safety and security of your domain assets. Our robust systems and dedicated security team are committed to safeguarding your domains against unauthorized access and ever-evolving online threats.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '>Simplified Domain Management Complexity: </span> Managing a large portfolio of domains can be a time-consuming endeavor. Axiom provides a centralized, user-friendly platform that streamlines domain management, allowing you to effortlessly administer your entire domain portfolio from a single, intuitive interface.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '>Enhanced Efficiency and Scalability: </span> Our automated workflows seamlessly manage domain registration and renewal processes, freeing up your IT team's valuable resources. Additionally, our infrastructure is built to scale seamlessly, ensuring effortless domain management as your organization grows.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '>Dedicated Account Management and Unwavering Support: </span> Our team of highly trained domain experts is available 24/7 to provide prompt and professional assistance with any questions or concerns you may have. We offer dedicated account management to ensure your online presence remains uninterrupted and your domain strategy aligns with your evolving business needs.</p>

                        
                    </div>
                </div>
            </Block>
        </React.Fragment>
    )
}

export default SectionAbout

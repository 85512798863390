import React from 'react'

import SliderSample from './SliderSample'

const Testimonial = ({ testimonial, author }) => {
    return (
        <React.Fragment>
            <div className="nk-container d-flex justify-content-center px-2 px-md-5 py-2 py-md-5 mt-4 ">
                <p className='text-primary ff fw-bold fs-2 text-center '>What Our Customer Say</p>
            </div>
            <div className='container py-4'>
                <SliderSample/>
            </div>
        </React.Fragment>
    )
}

export default Testimonial

import React from 'react'
import Head from '../../../../layout/head/Head'
import Content from '../../../../layout/content/Content'
import AboutEcom from './EcomPageComponents/AboutEcom'
import ChooseUS from './EcomPageComponents/ChooseUS'
import Contact from '../../components/Contact'
import Testimonial from '../components/Testimonial'
import EcommServices from './EcomPageComponents/EcomServices'
import GenericHero from '../components/GenericHero'
import { Icon } from '../../../../components/Component'
import heroimg from '../../../../images/axiom_img/Custom Web Dev..png';


const EcomPage = () => {
    return (
        <React.Fragment>
            <Head title="Axiom | E-Commerce Website Devlopment"></Head>
            <Content>
                <GenericHero
                    breadcrumbs={
                        <>
                            SERVICES <Icon name={'chevron-right'} />Software Development Services<Icon name={'chevron-right'} />E-Commerce Website Development
                        </>
                    }
                    title="Empowering your E-commerce success: Axiom's innovative website development services"
                    description="Harness the impact of personalized website development to drive sales and foster business growth."
                    buttonText="Contact Now"
                    buttonLink="/contact"
                    heroImage={heroimg} />
                <AboutEcom />
                <ChooseUS />
                <EcommServices />
                <Contact />
                <Testimonial />
            </Content>
        </React.Fragment>
    )
}

export default EcomPage

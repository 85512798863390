import React from 'react'
import Head from '../../../../layout/head/Head'
import Content from '../../../../layout/content/Content'
import SectionAbout from './BrandEvolutionComponents/SectionAbout'
import SectionService from './BrandEvolutionComponents/SectionService'
import Contact from '../../components/Contact'
import Testimonial from '../components/Testimonial'
import GenericHero from '../components/GenericHero'
import { Icon } from '../../../../components/Component'
import heroimg from '../../../../images/axiom_img/Brand.png';


const BrandEvolution = () => {
  return (
    <React.Fragment>
      <Head title="Axiom | Brand Evolution "></Head>
      <Content>
        <GenericHero
          breadcrumbs={
            <>
              SERVICES <Icon name={'chevron-right'} /> Digital Marketing & Management Services<Icon name={'chevron-right'} /> Brand Evolution
            </>
          }
          title="Transform your brand with Axiom's expert brand evolution services"
          description="Elevate your brand's potential with our comprehensive brand evolution strategies"
          buttonText="Contact Now"
          buttonLink="/contact"
          heroImage={heroimg} />
        <SectionAbout />
        <SectionService />
        <Contact />
        <Testimonial />
      </Content>
    </React.Fragment>
  )
}

export default BrandEvolution

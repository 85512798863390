import React from 'react'
import { Block } from '../../../../../components/Component'

const About = () => {
    return (
        <React.Fragment>
            <Block>
                <div className="container py-5">
                    <span className="ff fs-5 " style={{ color: "#2B3E79" }}>
                        Creative & Design Services
                    </span>
                </div>
                <div className="container py-4 ">
                    <div className='d-flex justify-content-center '>
                        <p className='ff fs-1 fw-bolder text-primary text-center '>Cultivating Compelling Brand Narratives: {process.env.REACT_APP_PROJECT_NAME}'s Strategic Graphic Design Services
                        </p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-semibold '>At {process.env.REACT_APP_PROJECT_NAME}, we recognize the profound influence of visual communication in shaping a distinctive brand identity. We offer a comprehensive graphic design service that transcends mere aesthetics. Our focus lies in crafting compelling visual narratives that resonate deeply with your target audience, propelling your brand to new heights within the digital landscape.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-bolder text-primary'>What sets Axiom apart as your trusted graphic designing partner?</p>
                    </div>
                    <div className='d-flex flex-column  justify-content-center my-4 '>
                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '> Strategic Design Thinking:</span>  Our approach extends beyond creating visuals; we strategically craft a cohesive visual language that embodies your brand identity. Our team meticulously delves into your unique brand essence, target audience and marketing objectives. This ensures every element, from logos and icons to marketing materials and packaging, seamlessly reinforces your brand message and fosters enduring connections with your target audience</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '>  Data-Driven Decisions:</span> Our graphic designers are highly acclaimed for their ability to translate vision into captivating visuals. They leverage a potent blend of creativity and technical knowledge, consistently exceeding industry benchmarks. We further elevate the process by incorporating data-driven insights. Market research and audience behavior analyses inform our design direction, ensuring your visuals resonate powerfully with your target audience, maximizing impact and driving engagement.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '>  Bespoke Solutions & Collaborative Partnership:</span> We firmly believe that a "one-size-fits-all" approach hinders effective graphic design. Through a collaborative partnership, we actively engage with you to understand your specific needs and preferences. This allows us to craft bespoke design solutions that perfectly encapsulate your brand essence and seamlessly integrate with your overarching marketing strategy.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '> Measurable Results & ROI Focus:</span>  Our dedication extends beyond design creation. We utilize advanced analytics to meticulously track the performance of your visual assets. This empowers us to measure the impact of our designs on brand awareness, engagement and conversions. With this data-driven approach, we ensure your brand identity continuously evolves and delivers a verifiable return on investment (ROI)</p>
                    </div>
                </div>
            </Block>
        </React.Fragment>

    )
}

export default About

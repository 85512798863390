import React from 'react'
import { Block, Col } from '../../../../../components/Component'
import SlideA from "../../../../../images/axiom_img/WebHosting-images/Website Maintenance & Support.png";

const MoreAboutUs = () => {
    return (
        <React.Fragment>
            <Block>
                <div className='nk-content' style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                    <div className="container-fluid py-4 ">
                        <div className="container bg-transparent ">
                            <div className='d-flex justify-content-center mx-md-5'>
                                <p className='ff fs-1 fw-bolder text-primary text-center '>Comprehensive Website Maintenance and Support Services by Axiom</p>
                            </div>
                        </div>

                        <div className=" row">
                            <Col md='7'>
                                <div className=''>
                                    <div className='d-flex flex-column justify-content-center my-4 '>
                                        <p className='ff fs-4 text-start text-primary '>At Axiom, we offer a comprehensive suite of professional website maintenance and support services to empower businesses to maximizing the performance and reliability of their online platforms.</p>

                                        <p className='ff fs-4 text-start text-primary '>Our dedicated team specializes in proactive monitoring, regular updates and ongoing optimizations to ensure that your website remains secure, functional and aligned with your business objectives. From managing software updates and resolving technical issues to implementing robust security measures and optimizing site speed, we provide tailored solutions that cater to the unique needs of each client.</p>

                                        <p className='ff fs-4 text-start text-primary '>With Axiom as your trusted partner, you can rest assured that your digital presence is in expert hands, allowing you to focus on driving business growth and delivering exceptional user experiences. Gain peace of mind and unlock the full potential of your website with Axiom's professional maintenance and support services.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md='5' className='align-self-center '>
                                <div className="container">
                                <img top src={SlideA} alt="webMaintenance" className='img-fluid ' />
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
            </Block>

        </React.Fragment>
    )
}

export default MoreAboutUs

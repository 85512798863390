import React from 'react'
import Head from '../../../../layout/head/Head'
import Content from '../../../../layout/content/Content'
import Contact from '../../components/Contact'
import Testimonial from '../components/Testimonial'
import About from './MobileAppComponents/AboutService'
import ServiceSection from './MobileAppComponents/ServiceSection'
import OurProjects from './MobileAppComponents/OurProjects'
import GenericHero from '../components/GenericHero'
import { Icon } from '../../../../components/Component'
import heroimg from '../../../../images/axiom_img/Mobile App.png';


const MobileAppPage = () => {
  return (
    <React.Fragment>
      <Head title="Axiom | Mobile Application Development"></Head>
      <Content>
        <GenericHero
          breadcrumbs={
            <>
              SERVICES <Icon name={'chevron-right'} /> Software Development Services<Icon name={'chevron-right'} /> Mobile Application Development
            </>
          }
          title="Unlock business potential with our mobile app solutions"
          description="Leverage our extensive expertise in mobile app development to transform your business operations and customer engagements."
          buttonText="Contact Now"
          buttonLink="/contact"
          heroImage={heroimg} />
        <About />
        <ServiceSection />
        <Contact />
        <OurProjects />
        <Testimonial />
      </Content>
    </React.Fragment>
  )
}

export default MobileAppPage

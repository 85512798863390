import React, { useEffect, useState } from "react";
import Content from "../layout/content/Content";
import Home from "./axiomlayout/components/Home";
import Service from "./axiomlayout/components/Service";
import About from "./axiomlayout/components/About";
import Contact from "./axiomlayout/components/Contact";
import Testimonial from "./axiomlayout/pages/components/Testimonial";
import WelcomeModal from "./components/common/WelcomeModel";

const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handlePageLoad = () => {
      sessionStorage.removeItem('modalShown');
    };

    window.addEventListener('beforeunload', handlePageLoad);

    const hasModalBeenShown = sessionStorage.getItem('modalShown');

    if (!hasModalBeenShown) {
      const timer = setTimeout(() => {
        setIsOpen(true);
        sessionStorage.setItem('modalShown', 'true');
      }, 2500);

      return () => {
        clearTimeout(timer);
        window.removeEventListener('beforeunload', handlePageLoad);
      };
    }
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <Content>
        <Home />
        <Service />
        <About />
        <Contact />
        <Testimonial />
      </Content>
      <WelcomeModal
        isOpen={isOpen}
        onClose={closeModal}
        title="Unlock Your Business Potential with a Free Consultation!"
        subtitle="Get expert advice on how our software solutions can drive your success."
        leftColumnTitle="What You Get:"
        leftColumnItems={[
          "Comprehensive analysis of your business needs",
          "Personalized software recommendations",
          "Roadmap to implement effective solutions"
        ]}
        rightColumnTitle="Why Choose Us:"
        rightColumnItems={[
          "Identify the best software solutions for your business",
          "Receive expert guidance from experienced professionals",
          "Get a tailored strategy to boost productivity and efficiency"
        ]}
        ctaButtonText="Schedule Now"
        ctaButtonLink="/consultation"
        footerTitle="Schedule Your Free Consultation Today!"
        phoneNumber="469.324.9860"
        email="info@axiomwebcontrol.com"
      />
    </React.Fragment>
  );
};

export default HomePage;

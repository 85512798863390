import Router from "./route/Index";

import ThemeProvider from "./layout/provider/Theme";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TabProvider } from "./pages/axiomlayout/components/TabContext";

const App = () => {

  return (
    <>
      <TabProvider>

        <ToastContainer
          position="top-right center"
          autoClose={3000}
          limit={1}
          hideProgressBar={false}
          closeOnClick
          pauseOnHover
          draggable
          transition={Flip}
          newestOnTop={true}
        />
        <ThemeProvider>
          <Router />
        </ThemeProvider>
      </TabProvider>

    </>
  );
};
export default App;
import React from 'react'
import Head from '../../../../layout/head/Head'
import Content from '../../../../layout/content/Content'
import Aboutsec from './ServerHostingComponents/Aboutsec'
import SectionService from './ServerHostingComponents/Servicesec'
import ServiceProvides from './ServerHostingComponents/ServiceProvides'
import Contact from '../../components/Contact'
import Testimonial from '../components/Testimonial'
import GenericHero from '../components/GenericHero'
import { Icon } from '../../../../components/Component'
import heroimg from '../../../../images/axiom_img/Server Space.png';


const ServerHosting = () => {
    return (
        <React.Fragment>
            <Head title="Axiom | Server Hosting Service" />
            <Content>
                <GenericHero
                    breadcrumbs={
                        <>
                  SERVICES <Icon name={'chevron-right'} /> Web Infrastructure & Management<Icon name={'chevron-right'} /> Dedicated Server Hosting
                  </>
                    }
                    title="Scalable dedicated hosting solutions: Optimized for business growth"
                    description="Future-proof your infrastructure for long-term growth"
                    buttonText="Contact Now"
                    buttonLink="/contact"
                    heroImage={heroimg} />
                <Aboutsec />
                <SectionService />
                <ServiceProvides />
                <Contact />
                <Testimonial />
            </Content>
        </React.Fragment>
    )
}

export default ServerHosting

import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Collapse, Nav, NavItem, Navbar, NavbarToggler } from "reactstrap";
import Logo2 from '../../images/siloimages/SecuredDatalogonew.png';
import { LinkItem } from '../Component';

const NavbarComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [borderLink, setBorderLink] = useState("");
  const [active, setActive] = useState("/");
  const location = useLocation();
  const navigate = useNavigate();

  const handleSetActive = (to) => {
    setActive(to);
    setIsOpen(false);
  };

  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  const handleBorderLinkChange = (index) => {
    setBorderLink(index);
  };

  const toggle = () => setIsOpen(!isOpen);

  const closeToggle = () => {
    if (isOpen) setIsOpen(false);
  };

  const handleNavigation = (to, isScroll = true) => {
    if (to.startsWith('/')) {
      navigate(to);
    } else {
      if (location.pathname !== '/') {
        navigate('/');
        setTimeout(() => {
          if (isScroll) {
            const element = document.getElementById(to);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }
        }, 100);
      } else if (isScroll) {
        const element = document.getElementById(to);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
    handleSetActive(to);
    closeToggle();
  };

  return (
    <div className="container-fluid px-0">
      <Navbar color="white" light expand="md" fixed="top">
        <NavLink to="/" className="scrollto mx-md-2 mx-0" onClick={closeToggle}>
          <img className="logo-dark align-self-center" src={Logo2} alt="logo" width="200px" />
        </NavLink>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar className='d-md-flex justify-content-end'>
          <Nav className="ml-auto" navbar>
            <NavItem
              className={`${borderLink === "0" ? "border-black border-2 border-bottom" : ""} align-self-start align-self-md-center pt-1`}
              onMouseEnter={() => handleBorderLinkChange('0')}
              onMouseLeave={() => setBorderLink("")}>
              <span
                onClick={() => handleNavigation("service")}
                className={`scrollto px-md-2 ff-alt fw-semibold fs-6 text-black m-md-0 m-1 align-self-md-center ${active === "service" ? "active border-black border-2 border-bottom" : ""}`}
                style={{ cursor: "pointer" }}>
                Services
              </span>
            </NavItem>
            <NavItem
              className={`${borderLink === "1" ? "border-black border-2 border-bottom" : ""} align-self-start align-self-md-center pt-1`}
              onMouseEnter={() => handleBorderLinkChange('1')}
              onMouseLeave={() => setBorderLink("")}>
              <span
                onClick={() => handleNavigation("industries")}
                className={`scrollto px-md-2 ff-alt fw-semibold fs-6 text-black m-md-0 m-1 align-self-md-center ${active === "industries" ? "active border-black border-2 border-bottom" : ""}`}
                style={{ cursor: "pointer" }}>
                Industries
              </span>
            </NavItem>
            <NavItem
              className={`${borderLink === "3" ? "border-black border-2 border-bottom" : ""} align-self-start align-self-md-center pt-1`}
              onMouseEnter={() => handleBorderLinkChange('3')}
              onMouseLeave={() => setBorderLink("")}>
              <span
                onClick={() => handleNavigation("whysilo")}
                className={`scrollto px-md-2 ff-alt fw-semibold fs-6 text-black m-md-0 m-1 align-self-md-center ${active === "whysilo" ? "active border-black border-2 border-bottom" : ""}`}
                style={{ cursor: "pointer" }}>
                WhySilo
              </span>
            </NavItem>
            <NavItem
              className={`${borderLink === "4" ? "border-black border-2 border-bottom" : ""} align-self-start align-self-md-center pt-1`}
              onMouseEnter={() => handleBorderLinkChange('4')}
              onMouseLeave={() => setBorderLink("")}>
              <LinkItem
                to={'/about'}
                className={`px-md-2 ff-alt fw-semibold fs-6 text-black m-md-0 m-1 align-self-md-center ${active === "/about" ? "active border-black border-2 border-bottom" : ""}`}
                style={{ cursor: "pointer" }}
                onClick={() => handleNavigation("/about", false)}>
                About
              </LinkItem>
            </NavItem>
            <NavItem
              className={`${borderLink === "5" ? "border-black border-2 border-bottom" : ""} align-self-start align-self-md-center pt-1`}
              onMouseEnter={() => handleBorderLinkChange('5')}
              onMouseLeave={() => setBorderLink("")}>
              <LinkItem
                to={'/siloportfolio'}
                className={`px-md-2 ff-alt fw-semibold fs-6 text-black m-md-0 m-1 align-self-md-center ${active === "/siloportfolio" ? "active border-black border-2 border-bottom" : ""}`}
                style={{ cursor: "pointer" }}
                onClick={() => handleNavigation("/siloportfolio", false)}>
                Portfolio
              </LinkItem>
            </NavItem>
            <div className='d-flex justify-content-start pt-1'>
              <NavItem className="m-md-2 m-1">
                <Button className="ff-alt fw-bold" outline color="secondary" onClick={() => handleNavigation("contact")}>
                  Get In Touch
                </Button>
              </NavItem>
              <NavItem className="m-md-2 m-1">
                <NavLink to={`${process.env.REACT_APP_ACCOUNT_URL}`}>
                  <Button className="ff-alt fw-bold" outline color="danger" onClick={closeToggle}>
                    Sign In
                  </Button>
                </NavLink>
              </NavItem>
            </div>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default NavbarComponent;
import React from 'react'
import { CardImg, Col, Row } from 'reactstrap'
import SlideA from "../../../../../images/axiom_img/GamePage-images/UX.png";
import SlideB from "../../../../../images/axiom_img/GamePage-images/Preproduction.png";
import SlideC from "../../../../../images/axiom_img/GamePage-images/Programing EXp.png";
import SlideD from "../../../../../images/axiom_img/GamePage-images/Additional Service.png";


const ServiceOffers = () => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <p className="text-primary text-center text-capitalize ff fs-1 fw-bold my-5 px-3 px-md-0">Our Game Design Services Offers</p>
                </Col>
            </Row>
            <div className="container-fluid" >
                <Row className='justify-content-center'>
                    <Col md='11' >
                        <div className="container-fluid d-flex flex-column align-items-center ">
                            <Col md='8' className=''>
                                <p className='text-center  text-primary ff fs-5 py-3'>
                                    The world of game design is a vast and intricate landscape. Here at {process.env.REACT_APP_PROJECT_NAME}, we understand the critical role a comprehensive design service plays in transforming your vision into a captivating player experience. We offer a meticulously crafted suite of services that encompass every facet of game development, from the initial spark of an idea to the final polish.
                                </p>
                            </Col>
                            <Col md='11' sm='6' className='border-top border-2 border-primary border-dotted'>
                                <div className='d-flex justify-content-center my-4 '>
                                    <Row>
                                        <Col md={6} className="mb-4">
                                            <div className="card bg-transparent">
                                                <div className="card-body row list">
                                                    <Col md='2' >
                                                        <CardImg top src={SlideB} alt="Pre-Production" />
                                                    </Col>
                                                    <Col md='9'>
                                                        <h4 className="mb-3 ff text-primary ">Pre-Production Powerhouse</h4>
                                                        <ul className="ff fs-4 text-start text-primary">
                                                            <li className='list-item'>Concept Ideation and Collaborative Brainstorming</li>
                                                            <li className='list-item'>Game Design Document (GDD) Authorship</li>
                                                            <li className='list-item'>Level Design Mastery</li>
                                                        </ul>
                                                    </Col>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6} className="mb-4">
                                            <div className="card bg-transparent ">
                                                <div className="card-body row list">
                                                    <Col md='2' >
                                                        <CardImg top src={SlideA} alt="UX Design" />
                                                    </Col>
                                                    <Col md='9'>
                                                        <h4 className="mb-3 ff text-primary ">Art and User Experience (UX) Orchestration</h4>

                                                        <ul className="ff fs-4 text-start text-primary">
                                                            <li className='list-item'>Concept Art and Style Guide Development</li>
                                                            <li className='list-item'>3D Modeling and Animation Virtuosity</li>
                                                            <li className='list-item'>Intuitive UI/UX Design</li>
                                                        </ul>
                                                    </Col>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6} className="mb-4">
                                            <div className="card bg-transparent ">
                                                <div className="card-body row list">
                                                    <Col md='2' >
                                                        <CardImg top src={SlideC} alt="Technical Design" />
                                                    </Col>
                                                    <Col md='9'>
                                                        <h4 className="mb-3 ff text-primary ">Technical Design and Programming Expertise</h4>

                                                        <ul className="ff fs-4 text-start text-primary">
                                                            <li className='list-item'>Game Mechanics Programming Precision</li>
                                                            <li className='list-item'>Scripting and AI Design Acuity</li>
                                                            <li className='list-item'>Technical Design Documentation Compendium</li>
                                                        </ul>
                                                    </Col>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6} className="mb-4">
                                            <div className="card bg-transparent ">
                                                <div className="card-body row  list">
                                                    <Col md='2' >
                                                        <CardImg top src={SlideD} alt="additional_services" />
                                                    </Col>
                                                    <Col md='9'>
                                                        <h4 className="mb-3 ff text-primary ">Additional Services to Bolster Your Game's Success</h4>

                                                        <ul className="ff fs-4 text-start text-primary">
                                                            <li className='list-item'>Playtesting and Iterative Refinement</li>
                                                            <li className='list-item'>Balancing and Polishing Finesse</li>
                                                            <li className='list-item'>Localization Savvy</li>
                                                        </ul>
                                                    </Col>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </div>
                    </Col>
                </Row>

            </div>
        </React.Fragment>
    )
}

export default ServiceOffers

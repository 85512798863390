import React from 'react'
import { Block } from '../../../../../components/Component'

const AboutSection = () => {
    return (
        <React.Fragment>
            <Block>
                <div className="container py-md-5 py-3">
                    <span className="ff fs-5 " style={{ color: "#2B3E79" }}>
                        Digital Marketing & Management Services
                    </span>
                </div>
                <div className="container py-4 ">
                    <div className='d-flex justify-content-center '>
                        <p className='ff fs-2 fw-bolder text-primary text-center '>Cultivating Online Success through Innovative Social Media Management with Axiom
                        </p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-semibold '>Welcome to {process.env.REACT_APP_PROJECT_NAME}, where our Social Media Creation and Management service empowers businesses to cultivate robust online presences. From profile setup to analytics, we provide comprehensive solutions that drive engagement, foster brand loyalty and fuel business growth. Let us navigate the dynamic social media landscape and unlock new opportunities for your brand.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-bolder text-primary '>Why businesses should choose our services ?</p>
                    </div>
                    <div className='d-flex flex-column  justify-content-center my-4 '>
                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Expertise and Experience: </span> Our team comprises seasoned social media specialists with extensive experience in digital marketing. We stay updated with industry trends and best practices to deliver effective strategies tailored to each client's objectives.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Comprehensive Solutions: </span> Axiom offers end-to-end social media management services, including profile setup, content creation, community management, advertising and analytics. We provide a holistic approach to maximize results and achieve business goals.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Creative Content: </span> Axiom excels in crafting engaging and relevant content that resonates with audiences across various social platforms. From compelling visuals to impactful copywriting, we ensure that your brand stands out in the crowded social media landscape.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Responsive Community Management: </span> Our team actively engages with your audience, responding to inquiries, comments and messages promptly and professionally. This fosters positive relationships and enhances brand reputation.</p>

                        <p className='ff fs-6 text-start text-primary '><span className='fw-bold '>Dedicated Support: </span> Axiom provides dedicated support and collaboration throughout our engagement. Our goal is to become a trusted partner in your digital marketing journey, guiding you towards sustained success on social media platforms.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-start fw-semibold '>Choosing Axiom for social media management means partnering with a dedicated team of experts committed to elevating your brand's online presence and driving tangible business results through strategic and impactful social media strategies.</p>
                    </div>
                </div>
            </Block>
        </React.Fragment>
    )
}

export default AboutSection

import React from 'react'
import { Col, Row } from 'reactstrap'
import heroimg from '../../../../../images/axiom_img/Servicesimg/Hero1.png';

const InfoCompo = () => {
    return (
        <React.Fragment>
            <div className="container-fluid py-4">
                <Row>
                    <Col md='5' className='align-self-center'>
                        <img src={heroimg} alt="heroimg" className='img-fluid' />
                    </Col>
                    <Col md='7' className='align-self-center'>
                        <div>
                            <h2 className='ff text-primary' >
                                Build a <span >Unique</span>  Website for Higher Conversions
                            </h2>
                        </div>
                        <div className='py-2'>
                            <h5 className='ff ' style={{ color: '#4DA44B' }}>Engage the Best Website developers and Beat the Competition</h5>
                        </div>
                        <div>
                            <p className='fs-6 ff'> Building a successful website requires ongoing effort due to high customer expectations and intense competition. Engaging users quickly is crucial to prevent them from leaving for competitors' sites. Prioritizing a superior user experience is essential to maintain and enhance your competitive advantage.
                            </p>
                            <p className='fs-6 ff'> Your website is a vital part of your business, showcasing your brand and building trust with your target audience. Our custom web design services are tailored through collaboration with clients to authentically represent your brand. Partnering with our skilled developers ensures an engaging digital experience for your visitors.
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default InfoCompo

import React from 'react'
import { PreviewCard } from '../../../../../components/Component';
import { Card, CardBody, CardHeader, CardImg, CardText, CardTitle, Col, Row } from 'reactstrap';
import SlideA from "../../../../../images/axiom_img/MobilePage-images/Payment app.png";
import SlideB from "../../../../../images/axiom_img/MobilePage-images/Medical app.png";
import SlideC from "../../../../../images/axiom_img/MobilePage-images/chat app.png";
import SlideD from "../../../../../images/axiom_img/MobilePage-images/e commerce app.png";
import SlideE from "../../../../../images/axiom_img/MobilePage-images/Streaming app.png";
import SlideF from "../../../../../images/axiom_img/MobilePage-images/Investment app.png";


const OurProjects = () => {
    const bgColor1 = '#D1D7FC';
    const bgColor2 = '#CEE8FF';
    return (
        <React.Fragment>
            <div className="container">
                <Row>
                    <Col>
                        <p className="fw-bolder text-primary text-center ff my-3 my-md-5 fs-1 ">Mobile Application We Develop</p>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center my-2 my-md-4 '>
                    <p className='ff fs-4 text-center fw-semibold '>Our mobile app development services focus on crafting robust iOS and Android applications that seamlessly complement or even replace web-based solutions. </p>
                </div>
                <div className='d-flex justify-content-center my-4 '>
                    <p className='ff fs-4 text-center fw-semibold '>We specialize in the professional development of the following types of applications:</p>
                </div>
                <PreviewCard>
                    <Row>
                        <Col md="4" className='mb-4'>
                            <Card className="card-bordered h-100">
                                <CardImg top src={SlideA} alt="paymentapp" className='h-350px object-fit-cover img-fluid'/>
                                <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Mobile Banking & Billing Apps</CardTitle>
                                    <CardText className='ff text-primary'>
                                        Our apps provide secure, user-friendly and feature-rich experiences that empower customers to access banking services and manage payments on the go.
                                    </CardText>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4" className='mb-4'>
                            <Card className="card-bordered h-100">
                                <CardImg top src={SlideB} alt="medicalapp" className='h-350px object-fit-cover img-fluid'/>
                                <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Patient & Hospital Apps</CardTitle>
                                    <CardText className='ff text-primary'>
                                        We create apps that serve as essential tools for improving healthcare delivery, enhancing patient engagement and optimizing hospital operations.
                                    </CardText>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4" className='mb-4'>
                            <Card className="card-bordered h-100">
                                <CardImg top src={SlideC} alt="chatapp" className='h-350px object-fit-cover img-fluid'/>
                                <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Messaging Apps</CardTitle>
                                    <CardText className='ff text-primary'>
                                        Our app provides platforms that facilitate real-time text, voice and multimedia exchanges between individuals or groups, daily communications, etc.
                                    </CardText>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4" className='mb-4'>
                            <Card className="card-bordered h-100">
                                <CardImg top src={SlideD} alt="e-comapp" className='h-350px object-fit-cover img-fluid'/>
                                <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>E-Commerce Apps</CardTitle>
                                    <CardText className='ff text-primary'>
                                        These apps have transformed the way people engage in online shopping, offering convenience, personalization and a seamless shopping experience on the go.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4" className='mb-4'>
                            <Card className="card-bordered h-100">
                                <CardImg top src={SlideE} alt="streamingapp" className='h-350px object-fit-cover img-fluid'/>
                                <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>TV & Online Streaming Apps</CardTitle>
                                    <CardText className='ff text-primary'>
                                        Our apps provide access to a wide range of entertainment content, including movies, TV shows, live sports, news, music and more.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4" className='mb-4'>
                            <Card className="card-bordered h-100">
                                <CardImg top src={SlideF} alt="investmentapp" className='h-350px object-fit-cover img-fluid'/>
                                <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent '>
                                    </CardHeader>
                                    <CardTitle tag="h5" className='text-center ff text-primary'>Investment Apps</CardTitle>
                                    <CardText className='ff text-primary'>
                                        These apps cater to both experienced investors and beginners, providing tools and resources to make informed investment decisions.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </PreviewCard>
            </div>
        </React.Fragment>
    )
}

export default OurProjects

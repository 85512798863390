import React, { useState } from 'react'
import { Block, Icon, LinkItem } from '../../../../../components/Component'
import Content from '../../../../../layout/content/Content'
import { Button, Card, CardBody, CardFooter, CardHeader, CardText, CardTitle, Col, Row } from 'reactstrap'
import WhitelabeledButton from '../../../components/WhitelabeledButton';
import UIUX from '../../../../../images/axiom_img/Servicesimg/UIUX.png'
import graphic from '../../../../../images/axiom_img/Servicesimg/Graphic.png'
import threeD from '../../../../../images/axiom_img/Servicesimg/3D.png'
import website from '../../../../../images/axiom_img/Servicesimg/Dynamic.png'
import ERP from '../../../../../images/axiom_img/Servicesimg/ERP.png'
import eCom from '../../../../../images/axiom_img/Servicesimg/E-Comm.png'
import mobile from '../../../../../images/axiom_img/Servicesimg/MobApp.png'
import hosting from '../../../../../images/axiom_img/Servicesimg/cloud-server.png'
import domain from '../../../../../images/axiom_img/Servicesimg/maintainance.png'
import server from '../../../../../images/axiom_img/Servicesimg/server.png'
import Digital from '../../../../../images/axiom_img/Servicesimg/social-media.png'
import brand from '../../../../../images/axiom_img/Servicesimg/brand-image.png'
import content from '../../../../../images/axiom_img/Servicesimg/content.png'
import social from '../../../../../images/axiom_img/Servicesimg/social-media-management.png'
import game from '../../../../../images/axiom_img/Servicesimg/Game.png'
import register from '../../../../../images/axiom_img/Servicesimg/domain.png'

const OurServices = () => {
    const bgColor1 = '#D1D7FC';
    const bgColor2 = '#CEE8FF';
    const [showMore, setShowMore] = useState(false);
    const allCards = [
        {
            img: UIUX,
            title: 'UI / UX Design',
            text: 'Our expert designers craft captivating user interfaces and seamless user experiences, ensuring your digital products are both visually appealing and highly functional.',
            link: '/ui/uxservices'
        },
        {
            img: graphic,
            title: 'Graphics Design',
            text: 'From logos to marketing materials, our graphic design team creates visually stunning assets that elevate your brand and capture your audiences attention.',
            link: '/graphicsservices'
        },
        {
            img: threeD,
            title: '3D Design',
            text: 'We bring ideas to life with cutting-edge 3D modeling and animation, perfect for product visualization, architectural renderings and immersive experiences.',
            link: '/3Dservices'
        },
        {
            img: game,
            title: 'Game Design',
            text: 'Our game designers combine creativity with technical expertise to develop engaging, innovative games across various platforms and genres.',
            link: '/gamedesignservices'
        },
        {
            img: website,
            title: 'Static & Dynamic Website',
            text: 'We create both static websites for simplicity and speed and dynamic websites for interactive, data-driven experiences, tailored to your specific needs.',
            link: '/static&dynamicweb'
        },
        {
            img: ERP,
            title: 'ERP / CRM Development',
            text: 'Our custom ERP and CRM solutions streamline your business processes, improve customer relationships and boost overall operational efficiency.',
            link: '/erpcrmservices'
        },
        {
            img: eCom,
            title: 'E-Com Website',
            text: 'We build robust, user-friendly e-commerce platforms that provide seamless shopping experiences and help grow your online business.',
            link: '/e-commerceservices'
        },
        {
            img: mobile,
            title: 'Mobile application',
            text: 'Our team develops intuitive, high-performance mobile apps for iOS and Android, helping you reach and engage your audience on the go.',
            link: '/mobileappdevelopment'
        },
        {
            img: hosting,
            title: 'Website Hosting',
            text: 'We offer reliable, secure and scalable hosting solutions to ensure your website remains fast, accessible and protected at all times.',
            link: '/webhostingservice'
        },
        {
            img: register,
            title: 'Domain Registration & Management',
            text: 'Secure your online identity with our domain registration and management services, including renewals, transfers and DNS management.',
            link: '/domainregisterservice'
        },
        {
            img: server,
            title: 'Dedicated Server Hosting',
            text: 'Our dedicated server hosting provides powerful, customizable and secure infrastructure for high-traffic websites and resource-intensive applications.',
            link: '/serverhostingservice'
        },
        {
            img: domain,
            title: 'Website Maintenance & Support',
            text: 'Keep your website running smoothly with our comprehensive maintenance and support services, including updates, backups and technical assistance.',
            link: '/websupportservice'
        },
        {
            img: Digital,
            title: 'SEO & Digital Marketing',
            text: 'Boost your online visibility and drive targeted traffic with our SEO and digital marketing strategies, tailored to your business goals.',
            link: '/digitalmarketing'
        },
        {
            img: social,
            title: 'Social Media Management',
            text: 'We help you build and maintain a strong social media presence, engaging your audience and growing your brand across various platforms.',
            link: '/socialmediaservices'
        },
        {
            img: brand,
            title: 'Brand Evolution',
            text: 'Transform and elevate your brand with our strategic brand evolution services, ensuring your identity remains relevant and impactful in a changing market.',
            link: '/brandevolutionservices'
        },
        {
            img: content,
            title: 'Content Marketing',
            text: 'Our content marketing strategies help you create and distribute valuable, relevant content to attract and retain a clearly defined audience.',
            link: '/contentmarketingservices'
        }
    ];
    const initialCards = allCards.slice(0, 8);
    const additionalCards = allCards.slice(8);

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };
    const renderCard = (card, index) => (
        <Col lg="3" className='mb-4' key={index}>
            <Card className="card-bordered h-100" style={{ backgroundColor: index % 2 === 0 ? bgColor1 : bgColor2 }}>
                <CardBody className="card-inner">
                    <CardHeader className='d-flex justify-content-center text-primary fs-1 bg-transparent'>
                        <div className='container'>
                            <img className='img-gluid w-100px' src={card.img} alt={card.title} />
                        </div>
                    </CardHeader>
                    <CardTitle tag="h5" className='text-center ff text-primary'>{card.title}</CardTitle>
                    <CardText className='ff text-primary'>{card.text}</CardText>
                </CardBody>
                <CardFooter className='ff text-primary bg-transparent'>
                    <WhitelabeledButton
                        to={card.link}
                        buttonText="Know More"
                        buttonColor="primary"
                        buttonOutline
                        className={`d-flex justify-content-center`}
                    />
                </CardFooter>
            </Card>
        </Col>
    );
    return (
        <>
            <div className="container-fluid text-center py-4">
                <div className='py-4'>
                    <h3 className='ff text-primary'>Services We Provide</h3>
                </div>
                <Row>
                    {initialCards.map((card, index) => renderCard(card, index))}
                </Row>
                {showMore && (
                    <Row>
                        {additionalCards.map((card, index) => renderCard(card, index + 8))}
                    </Row>
                )}
                <Row className="mt-4">
                    <Col className="text-center">
                        <Button color="primary" onClick={toggleShowMore}>
                            {showMore ? 'Show Less' : 'More...'}
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default OurServices

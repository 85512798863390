import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { Icon } from '../../../../../components/Component';
import heroimg from '../../../../../images/axiom_img/Servicesimg/Hero2.png';
import bgimg1 from "../../../../../images/axiom_img/background2.png";
import { Link } from 'react-router-dom';
import logo from '../../../../../images/axiom_img/background2.png';


const Herosection = () => {
    return (
        <React.Fragment>
            <div className="nk-content py-md-5 py-3 position-relative" style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR, height: '565px' }}>
                <Row className='justify-content-between pb-md-5 pb-3 mb-3'>
                    <Col md='7'>
                        <div className="container-fluid p-0 pt-md-3">
                            <div className='position-absolute icon_img3 d-none d-md-block opacity-50 z-3'>
                                <img src={bgimg1} alt="backimg" className='h-425px bgimg1' style={{ transform: 'rotate(147deg)' }} />
                            </div>
                            <div className="container ms-0 ms-md-3 ms-lg-4 p-md-3" >
                                <span className="ff" style={{ color: "#2B3E79" }}>
                                    SERVICES <Icon name={'chevron-right'} />
                                </span>
                            </div>
                            <div className='container ms-0 ms-md-3 ms-lg-4 py-md-4 py-2' >
                                <div>
                                    <h1 className='ff fw-bold' style={{ color: '#2B4584' }}>
                                        <span><img src={logo} alt="Axiom" className='img-fluid home_logo' /></span>xiom Web Control
                                    </h1>
                                </div>
                                <Col>
                                    <div>
                                        <h3 style={{ color: "#004CA1" }} className='ff text-capitalize'>Craft Your Digital Future. We Build It.</h3>
                                    </div>
                                </Col>
                                <div className="container py-2 px-0">
                                    <p className="text-black ff fs-5">Axiom Web Control Services - Your one-stop shop for all your web and mobile needs.</p>
                                </div>
                                <div className="py-3 d-flex justify-content-md-start justify-content-center " >
                                    <Link to={'/contact'}>
                                        <Button outline color="primary" className={'bg-white text-primary px-5 py-3 fw-bolder rounded-0 fs-5 ff'} style={{ zIndex: 999 }}>
                                            Contact Now
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md='5' className='align-self-center pb-md-0 pb-3'>
                        <img src={heroimg} alt="heroimg" className='img-fluid' />
                    </Col>
                </Row>
                <div className='position-absolute icon_img4 d-none d-md-block z-3'>
                    <img src={bgimg1} alt="backimg" className='h-325px bgimg1 opacity-25' style={{ transform: 'rotate(20deg)' }} />
                </div>
            </div>

        </React.Fragment >
    )
}

export default Herosection

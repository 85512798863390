import React from 'react'
import Head from '../../../../layout/head/Head'
import Content from '../../../../layout/content/Content'
import Herosection from './ServiceComponents/Herosection'
import Contact from '../../components/Contact'
import Testimonial from '../components/Testimonial'
import InfoCompo from './ServiceComponents/InfoCompo'
import OurServices from './ServiceComponents/OurServices'
import OurWork from './ServiceComponents/OurWork'
import AskedQue from './ServiceComponents/AskedQue'


const Service = () => {
    return (
        <React.Fragment>
            <Head title="Axiom" />
            <Content>
                <Herosection />
                <InfoCompo />
                <OurServices />
                <OurWork />
                <AskedQue />
                <Contact />
                <Testimonial />
            </Content>
        </React.Fragment>
    )
}

export default Service

import React from 'react';
import { Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { Icon } from '../../../components/Component';
import WhitelabeledButton from '../../axiomlayout/components/WhitelabeledButton';

const WelcomeModal = ({
    isOpen, onClose, title, subtitle, leftColumnTitle, leftColumnItems, rightColumnTitle, rightColumnItems, ctaButtonText, ctaButtonLink, footerTitle, phoneNumber, email
}) => {
    return (
        <Modal size="lg" isOpen={isOpen}>
            <button className="close" onClick={onClose}>
                <Icon name="cross" />
            </button>
            <ModalBody>
                <Card className='justify-content-center'>
                    <CardHeader className="px-0 bg-transparent text-center ff fs-5 fw-bold text-primary">
                        {title}
                        <p className="sub-text">{subtitle}</p>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <h6 className="ff">{leftColumnTitle}</h6>
                                <ul className="list">
                                    {leftColumnItems.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </Col>
                            <Col>
                                <h6 className="ff">{rightColumnTitle}</h6>
                                <ul className="list">
                                    {rightColumnItems.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <WhitelabeledButton
                    to={ctaButtonLink}
                    className="text-center"
                    buttonText={ctaButtonText}
                    buttonOutline
                    buttonColor="primary"
                />
            </ModalBody>
            <ModalFooter className="bg-light text-center justify-content-center">
                <h3 className="ff text-primary">{footerTitle}</h3>
                <div>
                    <span>
                        Call us at:
                        <a href={`tel:${phoneNumber}`}> {phoneNumber}</a>
                    </span>
                </div>
                <div>
                    <span>
                        Email us at:
                        <a href={`mailto:${email}`}> {email}</a>
                    </span>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default WelcomeModal;
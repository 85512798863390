import React from 'react'
import { Block } from '../../../../../components/Component'

const About = () => {
    return (
        <React.Fragment>
            <Block>
                <div className="container py-md-5 py-3">
                    <span className="ff fs-5 " style={{ color: "#2B3E79" }}>
                        Creative & Design Services
                    </span>
                </div>
                <div className="container py-4 ">
                    <div className='d-flex justify-content-center '>
                        <p className='ff fs-1 fw-bold text-primary text-center '>Elevate Your Idea of Game with {process.env.REACT_APP_PROJECT_NAME}
                        </p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-semibold '>At Axiom, we recognize that a game is an artistic tapestry woven from engaging mechanics, compelling narratives and immersive worlds. Our design philosophy transcends mere functionality, focusing on creating experiences that resonate with players on an emotional level.</p>
                    </div>
                    <div className='d-flex justify-content-center my-4 '>
                        <p className='ff fs-4 text-center fw-bolder text-primary'>Why Choose Axiom for Your Game Design Needs?</p>
                    </div>
                    <div className='d-flex flex-column  justify-content-center my-4 '>
                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '> Unparalleled Expertise:</span> Our team boasts a deep well of experience, having spearheaded the design of critically acclaimed titles. You can rely on our expertise to guide your project towards success.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '>  Flexibility and Scalability:</span> Our web control and game design services are built on a flexible and scalable platform, enabling projects of any size to evolve seamlessly. Whether starting with a small indie game or aiming for a large-scale MMORPG, Axiom's architecture supports growth and expansion without limitations.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '>  Comprehensive Design Solutions:</span> Axiom offers a full spectrum of design services, encompassing everything from initial concept ideation to meticulously crafted mechanics and immersive world-building.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '> Community and Support:</span> Axiom's active community and comprehensive resources empower users to navigate the game development process efficiently, offering support, advice and tools for effective learning and implementation.</p>

                        <p className='ff fs-6 text-start text-primary'><span className='fw-bold '> Seamless Integration:</span> Our established processes are designed to expedite development cycles and ensure your project stays on track, minimizing risk and maximizing results.</p>
                    </div>
                </div>
            </Block>
        </React.Fragment>

    )
}

export default About

import React from 'react'
import { Block } from '../../../../../components/Component'
import { Col } from 'reactstrap'
import SlideA from "../../../../../images/axiom_img/UI_UXPage-images/01.png";


const ChooseUs = () => {
    return (
        <React.Fragment>
            <Block>
                <div className='nk-content' style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                    <div className="container py-4 ">
                        <div className="container bg-transparent ">
                            <div className='d-flex justify-content-center mx-md-5'>
                                <p className='ff fs-1 fw-bolder text-primary text-center '>Why {process.env.REACT_APP_PROJECT_NAME} is Your Ideal Partner for UI/UX Design Services ? </p>
                            </div>
                        </div>

                        <div className=" row">
                            <Col md='7'>
                                <div className=''>
                                    <div className='d-flex flex-column justify-content-center my-4 '>
                                        <p className='ff fs-4 text-start text-primary '>In the ever-evolving digital landscape, user experience (UX) has become an undeniable differentiator, shaping brand perception and driving online success. At {process.env.REACT_APP_PROJECT_NAME}, we recognize this reality and deliver a UI/UX design service unlike any other.</p>

                                        <p className='ff fs-4 text-start text-primary '> Our approach goes beyond a data-driven foundation. We cultivate a collaborative environment throughout the design cycle, ensuring you receive a UI/UX experience that transcends aesthetics. It's meticulously crafted to meet the specific needs of your target audience, seamlessly integrating with your overarching marketing strategy.</p>

                                        <p className='ff fs-4 text-start text-primary '> Our team of award-winning UI/UX designers boasts extensive experience and industry knowledge. They consistently exceed industry benchmarks, crafting user journeys that not only captivate users but demonstrably drive engagement and measurable results. Partnering with {process.env.REACT_APP_PROJECT_NAME} signifies a commitment to your success.</p>

                                        <p className='ff fs-4 text-start text-primary '> We don't simply create a design; we continuously optimize it for maximum return on investment (ROI), ensuring your UI/UX experience continuously evolves alongside your business goals.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md='5' className='align-self-center'>
                                <img top src={SlideA} alt="UI/UXdesign" className='img-fluid ' />
                            </Col>
                        </div>
                    </div>
                </div>
            </Block>

        </React.Fragment>
    )
}

export default ChooseUs

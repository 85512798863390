import React from 'react'
import { Col, Row } from 'reactstrap';
import moblogo from '../../../images/axiom_img/moblogo.png';
import pclogo from '../../../images/axiom_img/pclogo.png';
import ServiceGallery from '../pages/components/ServiceGallery';
import Content from '../../../layout/content/Content';

const Service = () => {
    return (
        <React.Fragment>
            <Content>
                <div className={`block`} style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                    <div className="container" id="service">
                        <Row className='d-flex justify-content-between py-4'>
                            <Col md='12' className='p-0'>
                                <div className='text-md-start text-center py-md-4 py-2'>
                                    <p className="font-monospace fs-6" style={{ color: "#2B3E79", letterSpacing: "3.5px" }}>
                                        SERVICES
                                    </p>
                                </div>
                                <div className='text-md-start text-center pb-md-3 pb-2'>
                                    <p className="ff fs-2 fw-bold text-primary ">
                                        Crafting seamless product experiences
                                    </p>
                                </div>
                                <div>
                                    <p className="ff fs-4 fw-light text-primary px-md-0 px-3">
                                        Embark on your product development journey with a trusted partner by your side. Our forward-thinking approach and meticulously crafted roadmaps ensure efficient execution, sustainable growth and the ultimate success of your vision.
                                    </p>
                                </div>
                            </Col>
                            {/* <Col md='5' className='d-md-flex justify-content-end pt-5 d-none'>
                                <div className="col-3">
                                    <img src={moblogo} alt="mobileicon" className="img-fluid mw-100 mw-md-75 mw-lg-50 h-25" />
                                </div>
                                <div className="col-3">
                                    <img src={pclogo} alt="PCicon" className="img-fluid mw-100 mw-md-75 mw-lg-50 h-25" />
                                </div>
                            </Col> */}
                        </Row>
                    </div>
                    <div className='servicehead pb-4' >
                        <ServiceGallery />
                    </div>
                </div>
            </Content>
        </React.Fragment>
    )
}

export default Service

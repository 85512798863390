import React from 'react'
import Head from '../../../../layout/head/Head'
import Content from '../../../../layout/content/Content'
import AboutSection from './SocialMediaPageComponents/AboutSection'
import ServiceSection from './SocialMediaPageComponents/ServiceSection'
import Contact from '../../components/Contact'
import Testimonial from '../components/Testimonial'
import GenericHero from '../components/GenericHero'
import { Icon } from '../../../../components/Component'
import heroimg from '../../../../images/axiom_img/Social Media Creation.png';

const SocialMediaPage = () => {
    return (
        <React.Fragment>
            <Head title="Axiom | Social Media Creation"></Head>
            <Content>
                <GenericHero
                    breadcrumbs={
                        <>
                            SERVICES <Icon name={'chevron-right'} /> Digital Marketing & Management Services<Icon name={'chevron-right'} /> Social Media Management & Creation
                        </>
                    }
                    title="Elevate your social media presence with Axiom's expert Management & Creation services"
                    description="Empower your brand with tailored social media strategies that engage and captivate your audience"
                    buttonText="Contact Now"
                    buttonLink="/contact"
                    heroImage={heroimg} />
                <AboutSection />
                <ServiceSection />
                <Contact />
                <Testimonial />
            </Content>
        </React.Fragment>
    )
}

export default SocialMediaPage

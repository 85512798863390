import React from "react";
import { Outlet } from "react-router-dom";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppWrap from "./global/AppWrap";
import FooterNew from "../pages/axiomlayout/pages/components/FooterNew";

const Layout = ({ title, ...props }) => {
  
  return (
    <React.Fragment>
      <Head title={process.env.REACT_APP_HEAD_TITLE} />
      <AppRoot className={'overflow-x-hidden'}>
        <AppWrap className={'bg-white'}>
          <Header fixed />
          <Outlet />
          <FooterNew />
          <Footer />
        </AppWrap>
      </AppRoot>
    </React.Fragment>
  );
};
export default Layout;

import React from 'react';
import { Card, CardBody, CardHeader, CardText } from 'reactstrap';
import { Col, Row } from '../../../../../components/Component';
import Content from '../../../../../layout/content/Content';

const StaticVsDynamicSection = () => {
    const bgColor1 = '#D1D7FC';
    const bgColor2 = '#CEE8FF';
    return (
        <React.Fragment>
            <Content>
                <div className={`block pb-5`} style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
                    <p className="text-center mb-4 ff fs-1 fw-bold text-primary py-4 ">Static vs. Dynamic Websites</p>
                    <Row className={'mx-3 '}>
                        <Col md={6} className={'mb-2 mb-md-0'}>
                            <Card className="card-bordered h-100 ">
                                <CardHeader className='fs-3 fw-bold text-primary ff' style={{ backgroundColor: `${bgColor2}` }}>Static Websites</CardHeader>
                                <CardBody className="card-inner text-primary " style={{ backgroundColor: `${bgColor2}` }}>
                                    <CardText className='ff'>
                                        Static websites display the same content to every visitor and are built using HTML/CSS. Content is fixed and doesn't change unless the site itself is updated.
                                    </CardText>
                                    <div className='list'>
                                        <ul className='ff'>
                                            <li className='list-item'>Simple and quick to develop</li>
                                            <li className='list-item'>Fast loading times</li>
                                            <li className='list-item'>Cost-effective hosting</li>
                                        </ul>
                                    </div>
                                    <p className='ff'><strong className='fw-bold'>When to use:</strong> Ideal for small businesses, portfolios and landing pages that require minimal updates.</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className="card-bordered h-100 ">
                                <CardHeader className='fs-3 fw-bold text-primary ff' style={{ backgroundColor: `${bgColor1}` }}>Dynamic Websites</CardHeader>
                                <CardBody className="card-inner text-primary" style={{ backgroundColor: `${bgColor1}` }}>
                                    <CardText className='ff'>
                                        Dynamic websites use server-side scripting to generate and display content based on user interactions or database queries. Content can be personalized and updated frequently.
                                    </CardText>
                                    <div className='list'>
                                        <ul className='ff'>
                                            <li className='list-item'>Interactive features like user logins and forms</li>
                                            <li className='list-item'>Real-time content updates</li>
                                            <li className='list-item'>Scalability for complex functionalities</li>
                                        </ul>
                                    </div>
                                    <p className='ff'><strong className='fw-bold'>When to use:</strong> Suitable for e-commerce stores, social networks and websites requiring frequent content updates or user interactions.</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Content>

        </React.Fragment>
    );
};

export default StaticVsDynamicSection;

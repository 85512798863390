import React from 'react'
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Row } from 'reactstrap';
import slideA from '../../../../../images/axiom_img/UI_UXPage-images/09.png'
import slideB from '../../../../../images/axiom_img/UI_UXPage-images/13.png'
import slideC from '../../../../../images/axiom_img/UI_UXPage-images/12.png'
import slideD from '../../../../../images/axiom_img/UI_UXPage-images/10.png'
import slideE from '../../../../../images/axiom_img/UI_UXPage-images/14.png'
import slideF from '../../../../../images/axiom_img/UI_UXPage-images/08.png'

const ImpOfService = () => {
    const bgColor1 = '#D1D7FC';
    const bgColor2 = '#CEE8FF';
    return (
        <div className='container-fluid' style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
            <Row>
                <Col>
                    <p className="fw-bolder text-primary text-center ff my-5 fs-1 ">Why UI/UX Design Service Is Important For You ?</p>
                </Col>
            </Row>
            <Row>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100 position-relative" inverse style={{ backgroundColor: `${bgColor2}` }}>
                        <CardHeader className='text-primary ff fw-bold' style={{ backgroundColor: `${bgColor2}` }}>01</CardHeader>
                        <img src={slideA} alt="img" className='position-absolute icon_img6 h-25 '/>
                        <CardBody className="card-inner " style={{ backgroundColor: `${bgColor2}` }}>
                            <CardTitle tag="h5" className='text-primary ff fw-bold'>Elevated User Experience (UX)</CardTitle>
                            <CardText className='text-primary ff fw-bold'>
                                User-centered UI/UX design prioritizes intuitive interfaces and seamless user journeys, resulting in demonstrably higher user satisfaction, increased engagement and a demonstrably reduced learning curve for your platform.
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100" inverse color="primary">
                        <CardHeader className='text-primary ff fw-bold ' style={{ backgroundColor: `${bgColor1}` }}>02</CardHeader>
                        <img src={slideB} alt="img" className='position-absolute icon_img6 h-25 '/> 

                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                            <CardTitle tag="h5" className='text-primary ff fw-bold'>Strengthened Brand Perception</CardTitle>
                            <CardText className='text-primary ff fw-bold'>
                                A visually appealing and user-friendly interface fosters trust and loyalty with your audience, demonstrably strengthening your brand image.
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100" inverse color="primary">
                        <CardHeader className='text-primary ff fw-bold ' style={{ backgroundColor: `${bgColor2}` }}>03</CardHeader>
                        <img src={slideC} alt="img" className='position-absolute icon_img6 h-25 '/> 

                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                            <CardTitle tag="h5" className='text-primary ff fw-bold'>Optimized Conversion Rates</CardTitle>
                            <CardText className='text-primary ff fw-bold'>
                                By removing barriers to conversion, a well-designed UI/UX experience encourages users to take desired actions such as making purchases, signing up for services, or downloading content, leading to demonstrably boosted conversion rates.
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100" inverse color="primary">
                        <CardHeader className='text-primary ff fw-bold ' style={{ backgroundColor: `${bgColor1}` }}>04</CardHeader>
                        <img src={slideD} alt="img" className='position-absolute icon_img6 h-25 '/> 

                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                            <CardTitle tag="h5" className='text-primary ff fw-bold'>Measurable Return on Investment</CardTitle>
                            <CardText className='text-primary ff fw-bold'>
                                A well-crafted UI/UX design directly contributes to a higher return on investment for your digital presence by optimizing user experience and driving conversions. The impact can be measured through website analytics and conversion tracking.
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100" inverse color="primary">
                        <CardHeader className='text-primary ff fw-bold ' style={{ backgroundColor: `${bgColor2}` }}>05</CardHeader>
                        <img src={slideE} alt="img" className='position-absolute icon_img6 h-25 '/> 

                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor2}` }}>
                            <CardTitle tag="h5" className='text-primary ff fw-bold'>Reduced Customer Support Costs</CardTitle>
                            <CardText className='text-primary ff fw-bold'>
                                An intuitive interface reduces user confusion and the need for customer support inquiries, resulting in demonstrably lower support costs over time
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="card-bordered h-100" inverse color="primary">
                        <CardHeader className='text-primary ff fw-bold' style={{ backgroundColor: `${bgColor1}` }}>06</CardHeader>
                        <img src={slideF} alt="img" className='position-absolute icon_img6 h-25 '/> 

                        <CardBody className="card-inner" style={{ backgroundColor: `${bgColor1}` }}>
                            <CardTitle tag="h5" className='text-primary ff fw-bold'>Enhanced Competitive Advantage</CardTitle>
                            <CardText className='text-primary ff fw-bold'>
                                In today's digital landscape, a user-centric approach sets you apart from competitors. A well-designed UI/UX experience can give you a significant and demonstrable competitive edge.
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
        </div>
    )
}

export default ImpOfService
